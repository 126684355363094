import React from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const defaultPadding = 5;

export const ParagraphText = ({ text }) => {
    return (
        <Typography paragraph sx={{ lineHeight: 2.5 }}>
            {text}
        </Typography>
    );
};

export const Title = ({ title, author }) => {
    return (
        <Grid item mb={5} mt={5} md={8}>
            <Grid container justifyContent={'center'} textAlign={'center'} >
                <Grid item sm={12} md={12} mb={1} >
                    <Typography variant='subtitle2' sx={{ color: "grey" }}>August 30, 2024</Typography>
                </Grid>
                <Grid item sm={12} md={12} mb={1}>
                    <Typography variant='title' sx={{ color: "secondary.main", fontWeight: "25px" }}>{title}</Typography>
                </Grid>
                <Grid item sm={12} md={12} >
                    <Typography variant='subtitle1' sx={{ color: "primary" }}>{author}</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export const SubTitle = ({ text }) => {
    return (
        <Grid item md={12} mb={1}>
            <Grid container justifyContent={'center'} textAlign={'center'} >
                <Grid item md={8}>
                    <Typography variant='h3' sx={{ lineHeight: 1.5 }}>
                        {text}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export const Section = ({ title, content }) => {
    return (
        <Grid container md={12} justifyContent={'center'}>
            <Grid item md={10} mb={3}>
                <Typography variant="h1" sx={{ fontWeight: 700 }}>
                    {title}
                </Typography>
            </Grid>
            <Grid item md={10}>
                {content}
            </Grid>
        </Grid>
    );
};

export const AnnouncementCard: React.FC = ({ title, subtitle, date, body, chips, blogUrl}) => {
    let navigate = useNavigate();
    const handleClick = () => {
        console.log('Card clicked!');
        navigate(blogUrl);
    };

    return (
        <Card sx={{ width: 345, height: '450px' }} onClick={handleClick}>
            <Box sx={{ padding: '16px', backgroundColor: 'primary.main', color: 'white' }}>
                <Typography variant="overline" display="block" gutterBottom>
                    {/* NEW */}
                </Typography>
                <Typography variant="h6">
                    {title}
                </Typography>
            </Box>
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    {subtitle}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                    {date}
                </Typography>
                <Typography variant="body1" component="p">
                    {body}
                </Typography>
            </CardContent>
            <Box sx={{ padding: '16px' }}>
                {chips}
            </Box>
        </Card>
    );
};


