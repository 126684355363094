import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { handleDelete, handleToastNotification } from '../common/Utils.tsx';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchOrganizations, fetchUsagePlans, formatGetOrganizationData, formatOrganizationPatchRequestData, organizationPatchRequest } from './common.tsx';
import api from '../../services/ApiService.tsx';
import TableLayout from '../../layouts/TableLayout.tsx';
import { IconButton, Typography, Dialog, DialogContent, DialogTitle, Grid, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AuthService from '../../services/AuthService.js';

const url = 'organizations/';


const DeleteDialog = ({ showDialog, setShowDialog, handleDecline, handleAccept, focusRow }) => {
    if (!focusRow) {
        return null;
    }

    return (
        <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
            <DialogTitle>
                <Typography variant='h1' gutterBottom>Deleting Organization</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} mb={1} >
                        <Typography variant='h2' gutterBottom>{focusRow.name}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h3' gutterBottom>Important</Typography>
                        <Typography paragraph>By confirming the deletion of your organization, all associated resources, including your user account, will be permanently removed. This action is irreversible, so please proceed with caution.</Typography>
                    </Grid>
                    <Grid container item md={12} textAlign="center">
                        <Grid item xs={6}>
                            <Button
                                onClick={(handleDecline)}
                                color="primary"
                                sx={{ marginTop: '16px' }}
                            >
                                Decline
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                onClick={handleAccept}
                                color="primary"
                                sx={{ marginTop: '16px' }}
                            >
                                Accept
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};




export default function OrganizationsTable() {
    const [rows, setRows] = React.useState([]);
    const [availableUsagePlanOptions, setAvailableUsagePlanOptions] = React.useState([]);
    const [showDialog, setShowDialog] = React.useState(false);
    const [focusRow, setFocusRow] = React.useState();

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 70,
            editable: false,
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 170,
            editable: true,
        },
        {
            field: 'main_contact_email',
            headerName: 'Main Contact Email',
            width: 170,
            editable: true,
        },
        // {
        //     field: 'usage_rollover',
        //     headerName: 'Allow Usage Plan Rollover',
        //     width: 170,
        //     editable: true,
        //     type: 'singleSelect',
        //     valueOptions: [
        //         { "value": true, "label": 'Yes' },
        //         { "value": false, "label": 'No' }
        //     ],
        // },
        {
            field: 'usage_plan',
            headerName: 'Usage Plan',
            width: 170,
            editable: false,
            type: 'singleSelect',
            valueOptions: availableUsagePlanOptions,
        },
        {
            field: 'is_deleted',
            headerName: 'Deleted',
            width: 170,
            editable: false,
        },
        {
            field: 'updated',
            headerName: 'Updated',
            width: 170,
            editable: false,
        },
        {
            field: 'date_created',
            headerName: 'Date Created',
            width: 170,
            editable: false,
        },
        {
            field: 'delete',
            headerName: 'Delete',
            sortable: false,
            renderCell: (params) => (
                <IconButton onClick={() => showDeletionConfirmation(params.row)} aria-label="delete">
                    <DeleteIcon />
                </IconButton>
            ),
        },
    ];

    const showDeletionConfirmation = async (row) => {
        setFocusRow(row);
        setShowDialog(true);
    };

    const handleDialogAccept = async () => {
        if(focusRow){
            await handleDelete(focusRow.id, url, setRows);
            setFocusRow(undefined);
            setShowDialog(false);
            AuthService.logoutUser();
        } else {
            console.error("Unable to delete organization. Illegal state reached. Organization row should have been set before calling this function.")
        }   
    };
    
    const handleDialogDecline= async () => {
        setFocusRow(undefined);
        setShowDialog(false);
    };

    const handleProcessRowUpdate = async (updatedRow, originalRow) => {

        // Determine the changed values
        const changedValues = {};
        Object.keys(updatedRow).forEach((key) => {
            if (updatedRow[key] !== originalRow[key]) {
                changedValues[key] = updatedRow[key];
            }
        });

        // Return original row and prevent request being made when no changes were made.
        if (Object.keys(changedValues).length === 0) {
            return originalRow;
        }

        const body = formatOrganizationPatchRequestData(changedValues);

        // Update updatedRow with parsed inputs
        Object.keys(body).forEach((key) => {
            if (updatedRow[key] !== body[key]) {
                updatedRow[key] = body[key];
            }
        });

        try {
            const response = await api.patch(`${url}${updatedRow.id}/`, body);
            handleToastNotification(response);
            return updatedRow;
        } catch (error) {
            handleToastNotification(error.response);
            return originalRow;
        }

    };

    function onProcessRowUpdateError(error) {
        console.error('Error occurred while updating row:', error);
    }


    // Function to determine row class based on status code. Used to turn error message to a light red.
    const getRowClassName = (params) => {
        // Disabled for now
        if (params.row.status_code >= 400) {
            // return 'error-metric';
        }
        return '';
    };

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchOrganizations(setRows);
            } catch (error) {
                handleToastNotification(error.response);
            }

            try {
                await fetchUsagePlans(setAvailableUsagePlanOptions);
            } catch (error) {
                handleToastNotification(error.response);
            }
        };
        fetchData();
    }, []);

    return (
        <>
            <Typography variant="h2" gutterBottom>
                Organization
            </Typography>
            <DeleteDialog 
                showDialog={showDialog}
                setShowDialog={setShowDialog}
                handleAccept={handleDialogAccept}
                handleDecline={handleDialogDecline}
                focusRow={focusRow}
            />
            <TableLayout buttonPath='/organizations/create' buttonText='New Organization'>
                <Box sx={{ height: 400, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 5,
                                },
                            },
                        }}
                        pageSizeOptions={[5, 10, 25]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        getRowClassName={getRowClassName}
                        processRowUpdate={handleProcessRowUpdate}
                        onProcessRowUpdateError={onProcessRowUpdateError}
                    />
                    <ToastContainer />
                </Box>
            </TableLayout>
        </>
    );
}

