
import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';

const EmailOptOut = () => {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h3" component="h1" gutterBottom>
          Success!
        </Typography>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography paragraph>
            You have successfully opted out of emails notifications. If this was a mistake, you can update this setting after logging in. 
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

export default EmailOptOut;

