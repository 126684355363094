import React, { useEffect } from 'react';
import { Typography, Box, Grid, Card, CardHeader, CardContent, List, ListItem, ListItemIcon, ListItemText, Button, TextField, CircularProgress, Pagination, PaginationItem, InputAdornment, IconButton, MenuItem, DialogActions, Menu } from '@mui/material';
import { styles } from '../../style.tsx';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { useLocation } from 'react-router-dom';
import { Clear } from '@mui/icons-material';
import { DateTimePicker, PickersActionBarProps, usePickersTranslations } from '@mui/x-date-pickers';
import useId from '@mui/material/utils/useId';
import dayjs from 'dayjs';

type SectionProps = {
    title: string;
    content: string;
    headerStyle?: SxProps<Theme>;
};

export const Section: React.FC<SectionProps> = ({ title, content, headerStyle }) => {
    return (
        <React.Fragment >
            <Typography variant="h1" sx={{ ...headerStyle }}>
                {title}
            </Typography>
            {content}
        </React.Fragment >
    );
};



type BulletListItemProps = {
    strongText: string;
    text: string;
    strongTextStyles?: SxProps<Theme>;
};

export const BulletListItem: React.FC<BulletListItemProps> = ({ strongText, text, strongTextStyles }) => {
    return (
        <ListItem>
            <Typography>
                <Box component="strong" sx={{ ...styles.bulletListStrong, ...strongTextStyles }}>
                    {strongText}
                </Box>
                {` ${text}`}
            </Typography>
        </ListItem>
    );
};

type BoldProps = {
    text: string;
};
export const Bold: React.FC<BoldProps> = ({ text }) => {
    return (
        <Box component="strong" sx={{ typography: 'bold' }}>{text}</Box>
    );
}


// Pricing Components
export const pricingTiers = [
    {
        title: 'Free',
        price: '0',
        subtitle: 'Give it a try!',
        description: [
            '1 organization included',
            '5 users included',
            '1000 metrics / month',
            '25 notifications / month',
            '5 services',
            '5 monitors',
            'Email support'
        ]
    },
    {
        title: 'Pro',
        price: '20',
        subtitle: 'It grows with your needs!',
        description: [
            '1 organization included',
            '10 users included',
            '10,000 metrics / month',
            '500 notifications / month',
            '25 services',
            '50 monitors',
            'Priority email support'
        ]
    }
];



export const PricingCard = ({ tier, buttons }) => {
    return (

        <Grid item key={tier.title} xs={12} md={4} sx={{ textAlign: 'left' }}>
            <Card sx={{ minHeight: '580px',
                padding: 0.5,
                borderRadius: 2,
                boxShadow: 0,
                border: 2,
                borderColor: '#e5e7eb',
             }}>
                <CardHeader
                    title={tier.title}
                    subheader={tier.subtitle}
                    titleTypographyProps={{ align: 'left', typography: 'title2', fontWeight: 700 }}
                    subheaderTypographyProps={{ align: 'left', fontSize: '0.875rem', lineHeight: '1.25rem' }}
                />
                <CardContent>
                    <Grid item sx={{ ml: 2, mt: 5 }}>
                        <Typography color="primary" sx={{fontSize: '3.75rem', fontWeight: 800, lineHeight: 1}}>
                            ${tier.price}
                        </Typography>
                        <Typography variant="h6" color="text.secondary">
                            / month
                        </Typography>
                    </Grid>
                    <List>
                        {tier.description.map((line, index) => (
                            <ListItem key={index}>
                                <ListItemIcon sx={{ minWidth: 'auto', marginRight: 1 }}>
                                    <CheckOutlinedIcon color="secondary" />
                                </ListItemIcon>
                                <ListItemText>
                                    {line}
                                </ListItemText>
                            </ListItem>
                        ))}

                    </List>
                </CardContent>
                {buttons}
            </Card>
        </Grid>

    );
};

export const usePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
        if (typeof window.gtag === 'function') {
            window.gtag('config', 'AW-16685243424', {
                page_path: location.pathname,
            });
        }
    }, [location]);
};




interface FilterInputProps {
    label: string;
    filter: string;
    isLoading: boolean;
    onChange: (_event: React.ChangeEvent<{}>, value: string) => void;
}

export const FilterInput: React.FC<FilterInputProps> = ({ label, filter, isLoading, onChange }) => {
    return (
        <TextField
            label={label}
            variant="standard"
            value={filter}
            onChange={(event) => onChange(event, event.target.value)}
            fullWidth
            placeholder="Type to search..."
            InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      <IconButton
                        onClick={() => onChange(null, '')} // Clears input when clicked
                        edge="end"
                        aria-label="clear input"
                      >
                        <Clear />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
        />
    );
};

interface PaginationComponentProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (_event: React.ChangeEvent<unknown>, page: number) => void;
}

export const PaginationComponent: React.FC<PaginationComponentProps> = ({
    currentPage,
    totalPages,
    onPageChange,
}) => {
    return (
        <Pagination
            count={totalPages}
            page={currentPage}
            onChange={onPageChange}
            color="secondary"
            siblingCount={0}
            boundaryCount={0}
            showFirstButton={false}
            showLastButton={false}
            renderItem={(item) => (
                <PaginationItem
                    {...item}
                    sx={{
                        display: item.page === currentPage || item.type === 'previous' || item.type === 'next' ? 'block' : 'none',
                    }}
                />
            )}
        />
    );
};

interface SuggestionsPaginationProps<T> {
    suggestions: T[];
    handleSuggestionSelected: (_event: React.ChangeEvent<{}>, value: T | null) => void;
}

export const SuggestionsPagination = <T extends { id: string }>({ suggestions, handleSuggestionSelected }: SuggestionsPaginationProps<T>) => {
    return (
        <List>
            {suggestions.map((item) => (
                <ListItem key={item.id}>
                    <Button
                        onClick={() => handleSuggestionSelected(null, item)}
                        sx={{ textTransform: 'none', justifyContent: 'left'}}
                    >
                        {item.name}
                    </Button>
                </ListItem>
            ))}
        </List>
    );
};

export function CustomDatePickerActionBar(props: PickersActionBarProps) {
    const { actions, className, handleWindowPresetUsed } = props;
    const translations = usePickersTranslations();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = useId();

    if (actions == null || actions.length === 0) {
        return null;
    }

    const setDateTimeWindow = (amount: number, unit: string) => {
        const windowEnd = dayjs();
        const windowStart = windowEnd.subtract(amount, unit);
        handleWindowPresetUsed(windowStart, windowEnd)
        setAnchorEl(null);

    };

    const clear = () => {
        handleWindowPresetUsed(null, null)
    }

    const menuItems = actions?.map((actionType) => {
        switch (actionType) {
            case 'clear':
                return (
                    <MenuItem
                        onClick={() => { clear() }}
                        key={'clear'}
                    >
                        Clear
                    </MenuItem>
                );
            case '5m':
                return (
                    <MenuItem
                        onClick={() => setDateTimeWindow(5, 'minute')}
                        key="5m"
                    >
                        5 Minutes
                    </MenuItem>
                );
            case '15m':
                return (
                    <MenuItem
                        onClick={() => setDateTimeWindow(15, 'minute')}
                        key="15m"
                    >
                        15 Minutes
                    </MenuItem>
                );
            case '30m':
                return (
                    <MenuItem
                        onClick={() => setDateTimeWindow(30, 'minute')}
                        key="30m"
                    >
                        30 Minutes
                    </MenuItem>
                );
            case '1h':
                return (
                    <MenuItem
                        onClick={() => setDateTimeWindow(1, 'hour')}
                        key="1h"
                    >
                        1 Hour
                    </MenuItem>
                );
            case '4h':
                return (
                    <MenuItem
                        onClick={() => setDateTimeWindow(4, 'hour')}
                        key="4h"
                    >
                        4 Hours
                    </MenuItem>
                );
            case '1d':
                return (
                    <MenuItem
                        onClick={() => setDateTimeWindow(1, 'day')}
                        key="1d"
                    >
                        1 Day
                    </MenuItem>
                );
            case '2d':
                return (
                    <MenuItem
                        onClick={() => setDateTimeWindow(2, 'day')}
                        key="2d"
                    >
                        2 Days
                    </MenuItem>
                );
            case '1w':
                return (
                    <MenuItem
                        onClick={() => setDateTimeWindow(1, 'week')}
                        key="1w"
                    >
                        1 Week
                    </MenuItem>
                );
            case '1month':
                return (
                    <MenuItem
                        onClick={() => setDateTimeWindow(1, 'month')}
                        key="1month"
                    >
                        1 Month
                    </MenuItem>
                );
            default:
                return null;
        }
    });

    return (
        <DialogActions className={className}>
            <Button
                id={`picker-actions-${id}`}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(event) => setAnchorEl(event.currentTarget)}
                sx={{color: '#4ADE80'}}
            >
                Presets
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': `picker-actions-${id}`,
                }}
            >
                {menuItems}
            </Menu>
        </DialogActions>
    );
}

export const CustomDatePicker = ({
    label,
    value, // value should represent the current date value
    onChange, // callback for when the date changes
    handlePresetSelected // handle selecting presets like 5m, 15m, etc.
}) => {

    const presets = handlePresetSelected ? ['clear', '5m', '15m', '30m', '1h', '4h', '1d', '2d', '1w', '1month'] : [];
    return (
        <DateTimePicker
            label={label}
            value={value}
            onChange={onChange}
            slots={{
                actionBar: CustomDatePickerActionBar,
            }}
            slotProps={{
                actionBar: {
                    actions: presets,
                    handleWindowPresetUsed: handlePresetSelected,
                },
            }}
        />
    );
};

export const setSeoMetaTags = (title, description, keywords, ogUrl, ogImage ) => {
    // Set the document title
    document.title = title;

    // Define reusable variables
    const author = 'Devin Ogle';

    // Create meta description
    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = description;
    document.head.appendChild(metaDescription);

    // Create meta author
    const metaAuthor = document.createElement('meta');
    metaAuthor.name = 'author';
    metaAuthor.content = author;
    document.head.appendChild(metaAuthor);

    // Create meta keywords
    const metaKeywords = document.createElement('meta');
    metaKeywords.name = 'keywords';
    metaKeywords.content = keywords;
    document.head.appendChild(metaKeywords);

    // Open Graph Meta Tags
    const ogTitle = document.createElement('meta');
    ogTitle.setAttribute('property', 'og:title');
    ogTitle.content = title;
    document.head.appendChild(ogTitle);

    const ogDescription = document.createElement('meta');
    ogDescription.setAttribute('property', 'og:description');
    ogDescription.content = description;
    document.head.appendChild(ogDescription);

    const ogType = document.createElement('meta');
    ogType.setAttribute('property', 'og:type');
    ogType.content = 'article';
    document.head.appendChild(ogType);

    const ogUrlMeta = document.createElement('meta');
    ogUrlMeta.setAttribute('property', 'og:url');
    ogUrlMeta.content = ogUrl;
    document.head.appendChild(ogUrlMeta);

    const ogImageMeta = document.createElement('meta');
    ogImageMeta.setAttribute('property', 'og:image');
    ogImageMeta.content = ogImage;
    document.head.appendChild(ogImageMeta);

    // Twitter Meta Tags
    const twitterCard = document.createElement('meta');
    twitterCard.name = 'twitter:card';
    twitterCard.content = 'summary_large_image';
    document.head.appendChild(twitterCard);

    const twitterTitle = document.createElement('meta');
    twitterTitle.name = 'twitter:title';
    twitterTitle.content = title;
    document.head.appendChild(twitterTitle);

    const twitterDescription = document.createElement('meta');
    twitterDescription.name = 'twitter:description';
    twitterDescription.content = description;
    document.head.appendChild(twitterDescription);

    const twitterImage = document.createElement('meta');
    twitterImage.name = 'twitter:image';
    twitterImage.content = ogImage;
    document.head.appendChild(twitterImage);

    // Clean up function
    return () => {
        // Remove meta tags upon cleanup
        document.head.removeChild(metaDescription);
        document.head.removeChild(metaAuthor);
        document.head.removeChild(metaKeywords);
        document.head.removeChild(ogTitle);
        document.head.removeChild(ogDescription);
        document.head.removeChild(ogType);
        document.head.removeChild(ogUrlMeta);
        document.head.removeChild(ogImageMeta);
        document.head.removeChild(twitterCard);
        document.head.removeChild(twitterTitle);
        document.head.removeChild(twitterDescription);
        document.head.removeChild(twitterImage);
    };
};

export const QuoteBlock = ({ quote, source }) => {

    const sourceComponent = <>
        <Typography variant="body2" align="right" sx={{ mt: 2 }}>
            {source}
        </Typography>
    </>
    const renderedSourceComponent = source !== null ? sourceComponent : <></>

    return (
        <Box
            component="blockquote"
            sx={{
                borderLeft: '5px solid #ccc',
                backgroundColor: '#f9f9f9',
                fontStyle: 'italic',
                width: '100%',
                maxWidth: { xs: '250px', sm: '100%' },
                padding: 2,
                m: 1,
                overflow: 'auto',
                wordWrap: 'break-word',
            }}
        >
            <Typography variant="body1">
                {quote}
            </Typography>
            {renderedSourceComponent}
        </Box>
    );
};