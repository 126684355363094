

import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';

const About = () => {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h3" component="h1" gutterBottom>
          About Us
        </Typography>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            Welcome to Beholdr.io
          </Typography>
          <Typography paragraph>
            Your partner in proactive system health monitoring. In the fast-paced world of digital services, staying ahead of potential issues is not just necessary; it's crucial for maintaining trust and reliability. That's where we come in.
          </Typography>
          
          <Typography variant="h6">Our Mission</Typography>
          <Typography paragraph>
            Our mission at Beholdr.io is simple: to provide a powerful yet lightweight monitoring solution that can be seamlessly integrated into any system. We believe in empowering developers, businesses, and IT professionals with the tools they need to preemptively address issues before they impact users. By doing so, we're not just monitoring systems; we're safeguarding experiences.
          </Typography>

          <Typography variant="h6">How It Works</Typography>
          <Typography paragraph>
            Beholdr.io is designed with simplicity and efficiency at its core. Our service enables you to define custom thresholds, establish time windows, and receive timely notifications. This straightforward approach ensures that you're always in the know, without being overwhelmed by unnecessary complexity.
          </Typography>

          <Typography variant="h6">Built with Dedication</Typography>
          <Typography paragraph>
            Beholdr.io is the brainchild of a passionate developer who recognized the need for a monitoring solution that strikes the perfect balance between comprehensiveness and usability. As a small business, we understand the challenges that come with managing digital products and services. That's why we've poured countless hours into crafting a tool that is not only effective but also accessible to teams of all sizes.
          </Typography>

          <Typography variant="h6">Why Choose Beholdr.io?</Typography>
          <Typography paragraph>
            Choose Beholdr.io for its lightweight efficiency, customization flexibility, proactive issue handling, developer-friendly design, and supportive small business ethos. Experience the peace of mind that comes with proactive monitoring. Try Beholdr.io today and take the first step towards a more reliable digital future.
          </Typography>

          <Typography paragraph>
            We're not just monitoring your systems; we're empowering your success. Welcome to Beholdr.io.
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

export default About;

