import React, { useState } from 'react';
import { handleToastNotification } from '../common/Utils.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchServices } from '../services/common.tsx';
import { toast } from 'react-toastify';

import api from '../../services/ApiService.tsx';
import { fetchMonitor, formatMonitorPatchRequestData, MonitorForm, url, validateEmails, validateTeamsInput } from './common.tsx';



const EditMonitorForm: React.FC = () => {
    const { monitorId } = useParams<{ monitorId: string }>();
    const [formData, setFormData] = useState({
        name: '',
        subscribers: '',
        window: '',
        threshold: '',
        notification_content: '',
        metric_name: '',
        monitor_type: '',
        service: ''
    });

    const [services, setServices] = useState([]);

    const navigate = useNavigate();

    const handleClassifierChange = (value: string) => {
        setFormData({ ...formData, metric_name: value });
    };

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Commented out until other notification avenues are supported.
        // if (!validJson(formData.subscribers)) {
        //     toast.error("JSON is not in the proper format.")
        //     return;
        // }
        if (!validateEmails(formData.subscribers)) {
            toast.error("Emails are not formatted correctly.")
            return;
        }
        if (!validateTeamsInput(formData.subscribers)) {
            toast.error("Teams webhooks are not formatted correctly.")
            return;
        }

        const body = formatMonitorPatchRequestData(formData);
        try {
            const response = await api.patch(`${url}${monitorId}/`, body);
            handleToastNotification(response);
            navigate("/monitors")
        } catch (error) {
            handleToastNotification(error.response);
        }
        console.log(formData);
    };

    React.useEffect(() => {
        fetchServices(setServices);
        fetchMonitor(setFormData, monitorId)
    }, [monitorId]);


    return (
        <MonitorForm
            formData={formData}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleClassifierChange={handleClassifierChange}
            services={services}
            formTitle="Edit Monitor"
        />
    );
}

export default EditMonitorForm;
