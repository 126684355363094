import { Container, Grid, Typography } from '@mui/material';
import React from 'react';
import Link from '@mui/material/Link';
import "../../App.css";

export const Footer = (props) => {
    return (
        <React.Fragment >

            <Grid container justifyContent="center" alignItems="center" spacing={1} textAlign="center" sx={{mb: 4}}>
                <Grid item xs={12} md={12}>
                    {/* TODO: Finish Copyright then uncomment. */}
                    <Typography className='Copyright' variant="body2" color="text.secondary" align="center" {...props}>
                        {'Copyright © '}
                        {new Date().getFullYear()}
                        {' Ogle Software LLC. All rights reserved.'}
                    </Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                    <Typography variant="body2" color="text.secondary" {...props}>
                        <Link color="inherit" href="/privacy-policy">
                            Privacy Policy
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                    <Typography variant="body2" color="text.secondary" {...props}>
                        <Link color="inherit" href="/contact-us">
                            Contact Us
                        </Link>
                    </Typography>
                </Grid>
            </Grid>

        </React.Fragment>
    );
}
