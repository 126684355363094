import React from "react";

import {Grid} from "@mui/material";
import { styles } from "../../style.tsx";
import {MetricsTable} from "./MetricsTable.tsx";
import { ToastContainer } from "react-toastify";
import { MetricSearch } from "./MetricSearch.tsx";
import { FiltersProp } from "./common.tsx";

export default function MetricView() {
    const [filters, setFilters] = React.useState<FiltersProp>({
        startDate: null,
        endDate: null,
        monitorName: '',
        metricName: '',
    });

    const [filterSubmittedEvent, setFilterSubmittedEvent] = React.useState<Number>(0); // This is used to signal that a change has occurred in the filters
    
    const signalFilterSubmittedEvent = () => {
        const current_timestamp = new Date();
        setFilterSubmittedEvent(current_timestamp.getTime());
    };

    return (
        <React.Fragment>
            <Grid container spacing={1} sx={styles.contentContainer}>
                <Grid item xs={12} md={12}>
                    <MetricSearch filters={filters} setFilters={setFilters} signalFilterSubmittedEvent={signalFilterSubmittedEvent}/>
                </Grid>
                <Grid item xs={12} md={12}>
                    <MetricsTable filters={filters} filterSubmittedEvent={filterSubmittedEvent}/>
                </Grid>
            </Grid>
            <ToastContainer />
        </React.Fragment>
    );
}