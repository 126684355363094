import React, { useState } from 'react';
import { Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { fetchOrganizations } from '../organizations/common.tsx';
import { handleToastNotification } from '../common/Utils.tsx';
import { ToastContainer } from 'react-toastify';
import api from '../../services/ApiService.tsx';
import { useNavigate } from 'react-router-dom';


const user_url = 'users/';

interface UserCreateData {
    first_name: String;
    last_name: String;
    username: String;
    email: String;
    password: String;
    user_organizations: [];
    account_type: String;
}

function formatUserCreateRequestData(
    data: {}
): UserCreateData {

    const userCreateData: UserCreateData = {
        first_name: '',
        last_name: '',
        username: '',
        email: '',
        password: '',
        user_organizations: [],
        account_type: '',
    }

    const { first_name, last_name, username, email, password, user_organizations, account_type } = data;

    userCreateData.first_name = first_name;
    userCreateData.last_name = last_name;
    userCreateData.username = username;
    userCreateData.email = email;
    userCreateData.password = password;
    userCreateData.user_organizations.push(user_organizations);
    userCreateData.account_type = account_type;

    return userCreateData;
}

export default function UserForm() {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        username: '',
        email: '',
        password: '',
        user_organizations: '',
        account_type: '',
    });
    const [organizations, setOrganizations] = useState([]);

    const navigate = useNavigate();

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const body = formatUserCreateRequestData(formData);
        try {
            const response = await api.post(`${user_url}`, body);
            handleToastNotification(response);
            navigate("/users")
        } catch (error) {
            handleToastNotification(error.response);
        }
        console.log(formData);
    };


    React.useEffect(() => {
        fetchOrganizations(setOrganizations);
    }, []);

    return (
        <React.Fragment>
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <Typography variant="h6" gutterBottom>
                        Create User
                    </Typography>
                    <ToastContainer />
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={6} >
                                <TextField
                                    required
                                    id="first_name"
                                    name="first_name"
                                    label="First Name"
                                    fullWidth
                                    autoComplete="given-name"
                                    variant="standard"
                                    value={formData.first_name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <TextField
                                    required
                                    id="last_name"
                                    name="last_name"
                                    label="Last Name"
                                    fullWidth
                                    autoComplete="family-name"
                                    variant="standard"
                                    value={formData.last_name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="username"
                                    name="username"
                                    label="Username"
                                    fullWidth
                                    autoComplete="username"
                                    variant="standard"
                                    value={formData.username}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="email"
                                    name="email"
                                    label="Email"
                                    fullWidth
                                    autoComplete="email"
                                    variant="standard"
                                    value={formData.email}
                                    onChange={handleChange}
                                    type="email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="password"
                                    name="password"
                                    label="Password"
                                    fullWidth
                                    autoComplete="new-password"
                                    variant="standard"
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="select-field-label">Select Organization</InputLabel>
                                    <Select
                                        labelId="user_organizations"
                                        value={formData.user_organizations}
                                        label="User Organizations"
                                        name="user_organizations"
                                        onChange={handleChange}
                                    >
                                        {organizations.map((organization) => {
                                            return (
                                                <MenuItem value={organization.id}>{organization.name}</MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="select-field-label">Select Account Type</InputLabel>
                                    <Select
                                        labelId="account_type"
                                        value={formData.account_type}
                                        label="Account Type"
                                        name="account_type"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={1}>User</MenuItem>
                                        <MenuItem value={2}>Admin</MenuItem>
                                        <MenuItem value={3}>Organization Admin</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" color="primary">
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
        </React.Fragment >


    );
}
