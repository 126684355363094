import axios from 'axios';
import Cookies from 'js-cookie';
import api from './ApiService.tsx';

const AuthService = {

    loginUser: async function (email, password) {
        try {
            const res = await api.post('login/', { email, password });
            const accessToken = res.data.access;
            this.setAuthToken(accessToken);
        } catch (error) {
            console.error("Login failed:", error);
            this.setAuthToken();
            throw error;
        }
    },

    isLoggedIn: function () {
        const token = Cookies.get(process.env.REACT_APP_TOKEN_NAME);
        if (token) {
            return true;
        }
        return false;
    },

    logoutUser: function () {
        console.log("Logging Out.");  // For debugging only
        this.setAuthToken();
        window.location.href = '/login';
        return true;
    },

    setAuthToken: function (token) {
        if (token) {
            // TODO: Set Secure to True when HTTPS certs have been setup.
            Cookies.set(process.env.REACT_APP_TOKEN_NAME, token, { expires: 1, secure: false }); // Expires in 1 day, secure: true ensures it's sent over HTTPS
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } else {
            try {
                Cookies.remove(process.env.REACT_APP_TOKEN_NAME);
                delete axios.defaults.headers.common['Authorization'];
            } catch (error) {}
            
        }
    },

    checkForAuthError: function (error) {
        if (error.response && error.response.status === 401) {
            this.setAuthToken();
        }
    }
    
    
};

export default AuthService;
