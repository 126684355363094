import React, { useState, useEffect, useCallback } from 'react';
import { Box } from '@mui/material';
import debounce from 'lodash.debounce';
import api from '../../services/ApiService.tsx';
import { FilterInput, PaginationComponent, SuggestionsPagination } from '../common/CommonComponents.tsx';

const url = 'metric-classifiers/';

interface Classifier {
    id: string;
    name: string;
    organization: string;
}

interface MetricClassifierInputProps {
    handleFilterChange: (name: string, value: string) => void; // Callback for selected classifier
    initialInput?: string; // New prop for initial input

}

const MetricClassifierInput: React.FC<MetricClassifierInputProps> = ({ handleFilterChange, initialInput = '' }) => {
    const [filter, setFilter] = useState(initialInput);
    const [suggestions, setSuggestions] = useState<Classifier[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const pageSize = 5; // Number of items per page
    const [page, setPage] = useState(1); // Current page state
    const [totalPages, setTotalPages] = useState(1); // Total number of pages

    const filterName = "metricName";

    const fetchSuggestions = useCallback(debounce(async (filter: string, page: number) => {
        setIsLoading(true);
        try {
            const params = { metric_name: filter, page, page_size: pageSize };
            const response = await api.get(url, { params });
            setSuggestions(response.data.data);
            setTotalPages(Math.ceil(response.data.count / pageSize)); // Set the total number of pages
        } catch (error) {
            console.error('Error fetching suggestions:', error);
            setSuggestions([]);
        } finally {
            setIsLoading(false);
        }
    }, 300), []);

    useEffect(() => {
        fetchSuggestions(initialInput, 1);
        setFilter(initialInput);
    }, [initialInput]);

    useEffect(() => {
        fetchSuggestions(filter, 1);
    }, [filter]);

    const handleQueryChange = (_event: React.ChangeEvent<{}>, value: string) => {
        handleFilterChange(filterName, value ? value : '');
        setFilter(value);
        setPage(1); // Reset to the first page when the filter changes
    };

    const handleSuggestionSelected = (_event: React.ChangeEvent<{}>, value: Classifier | null) => {
        handleFilterChange(filterName, value ? value.name : '');
        setFilter(value ? value.name : '');
    };

    const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value); // Update the current page
        fetchSuggestions(filter, value); // Fetch suggestions for the selected page
    };

    return (
        <Box>
            <FilterInput
                label="Metric Name"
                filter={filter}
                isLoading={isLoading}
                onChange={handleQueryChange}
            />

            <SuggestionsPagination
                suggestions={suggestions}
                handleSuggestionSelected={handleSuggestionSelected}
            />

            <PaginationComponent
                currentPage={page}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
        </Box>
    );
};

export default MetricClassifierInput;