
import React from 'react';
import { Container, Typography, Box, Paper, Link } from '@mui/material';

const PaymentSuccess = () => {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h1" gutterBottom>
            Congratulations on Your Successful Subscription to Beholdr.io!
        </Typography>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography paragraph>
                Thank you for choosing Beholdr.io as your monitoring solution. Your subscription is now active, giving you access to advanced monitoring capabilities, real-time metrics, and priority support. We're excited to support your journey towards seamless service management and exceptional performance monitoring. <br/><br/>Welcome aboard!
          </Typography>
          <Typography paragraph>
                If you are new to Beholdr.io, please follow the <Link  href="/onboarding">Onboarding Guide</Link>
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

export default PaymentSuccess;

