import React from 'react';
import { Container, Typography, Box, Paper, Grid, Button, styled, SvgIcon, Card, CardHeader, CardContent, LinearProgress, List, ListItem, ListItemIcon, ListItemText, IconButton, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import DataThresholdingOutlinedIcon from '@mui/icons-material/DataThresholdingOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';

import CodeBlockDisplay from '../common/CodeBlockDisplay.tsx';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { styles } from '../../style.tsx';

import { LineChart } from '@mui/x-charts';
import MonitorMetricChart from '../metrics/MonitorMetricChart.tsx';
import MonitorHeartOutlined from '@mui/icons-material/MonitorHeartOutlined';
import { PricingCard, pricingTiers } from '../common/CommonComponents.tsx';


const curlCodeExample = `
curl -X POST "https://beholdr.io/api/metrics/create/" 
     -H "X-Api-Key: xxxxx.xxxxxxxxxxxxxxxxxx" 
     -H "Accept: application/json" 
     -H "Content-Type: application/json" 
     -d '{"message": "Service process completed successfully.", "name" : "ExampleServiceApi.process_success"}'

`;
const pythonCodeExample = `
import requests
url = "https://beholdr.io/api/metrics/create/"
    
# Set the Api Key for your Service
headers = {
    "X-Api-Key": "xxxxx.xxxxxxxxxxxxxxxxxx",
    "Accept": "application/json",
}
# Define your service's metric
metric = {
    "message": "Service process completed successfully.",
    "name" : "ExampleServiceApi.process_success"
}
# Send your metric to Beholdr.io to ingest
response = requests.post(url, headers=headers, data=metric)
`;

const sdkExample = `
# Use our python SDK for a pre-made metric request handler
https://github.com/devin-beholdr-io/beholdr_io_sdk
`;


const codeExamples = [
    { label: 'Curl', language: 'bash', content: curlCodeExample },
    { label: 'Python', language: 'python', content: pythonCodeExample },
    { label: 'SDK', language: 'python', content: sdkExample },
];

const pricingQuestions = [
    {
        'question': 'Can I cancel my subscription at any time?',
        'answer': 'Yes, you can cancel your subscription at any time. Your level of service access will remain until the end of the current billing period.'
    },
    {
        'question': 'What if I need more usage than what is provided in the "Pro" plan?',
        'answer': 'You can enable an option in your organization\'s settings to extend your usage beyond the limits of the "Pro" plan. For every additional $20 spent each month, your plan’s limits will increase incrementally. This additional expenditure allows for a proportional increase in resources, aligning with your organization\'s expansion needs. For example, if you opt to spend an extra $40 each month on top of the base "Pro" plan, your plan will upgrade to "Pro_x3". This upgraded plan includes increased limits such as 30,000 metrics and 1,500 notifications, offering a scalable solution as your requirements grow.'
    },
    {
        'question': 'What notifications does Beholdr.io support?',
        'answer': 'Currently, Beholdr.io supports notifications via email. We are actively working to expand our capabilities to include integration with the most commonly used business messaging systems, such as Microsoft Teams, Slack, and others.'
    },
    {
        'question': 'Who can be added as service subscribers?',
        'answer': 'Only active users within your organization who are registered with Beholdr.io can be added as subscribers to your service. Additionally, these users must have verified their email addresses to ensure they can receive notifications. This requirement is designed to maintain the integrity and reputation of Beholdr.io\'s email system, guaranteeing the secure and responsible use of our notification services.'
    },
];


const featureCallouts = [
    {
        "icon": <QueryStatsOutlinedIcon sx={{ fontSize: '2rem' }} />,
        "header": "Stream Your Metrics",
        "description": "Effortlessly integrate Beholdr.io into your service to seamlessly emit metrics for continuous monitoring and management. Name and classify your metrics for enhanced monitoring and precise specificity.",
    },
    {
        "icon": <DataThresholdingOutlinedIcon sx={{ fontSize: '2rem' }} />,
        "header": "Define Thresholds",
        "description": "Define service-specific monitors with metric thresholds and window sizes in Beholdr.io to customize when you receive alerts, ensuring you stay informed on the metrics that matter most to your operations.",
    },
    {
        "icon": <EmailOutlinedIcon sx={{ fontSize: '2rem' }} />,
        "header": "Stay Informed",
        "description": "Beholdr.io will promptly notify the subscribers of your monitors as soon as your metric thresholds are breached, ensuring timely and effective communication. Beholdr.io will soon support SMS and Slack notifications.",
    },
    {
        "icon": <MonitorHeartOutlined sx={{ fontSize: '2rem' }} />,
        "header": "Service Heartbeats",
        "description": "Ensure your services or processes are running smoothly by defining expected time intervals for metrics emission. Receive instant notifications if your services stop unexpectedly.",
    },
]

const beholdrInformationSection = [
    {
        "icon": null,
        "header": "Our Mission",
        "description": "At Beholdr.io, we aim to provide a powerful yet lightweight monitoring solution that integrates seamlessly into any system, empowering developers, businesses, and IT professionals to preemptively address issues before they impact users.",
    },
    {
        "icon": null,
        "header": "How It Works",
        "description": "No need to learn new tools or migrate your logs. Beholdr works with your current setup, focusing solely on what matters: alerting you when things go wrong. Emit metrics from your services and let Beholdr.io monitor them for you.",
    },
    {
        "icon": null,
        "header": "Built with Dedication",
        "description": "Developed by a passionate team, Beholdr.io balances comprehensiveness and usability, crafting a tool that supports small to large teams efficiently.",
    },
    {
        "icon": null,
        "header": "Why Choose Beholdr.io?",
        "description": "Designed for simplicity, Beholdr saves you time and money by avoiding the complexity and costs of full observability suites like Datadog. Get notified when you want to be, and use your existing tools to explore and debug.",
    },

]

const dataSeries = [
    {
        name: "Metrics",
        type: 'area',
        data: [1, 2, 6, 6, 6, 10, 10, 10, 10]
    },
    {
        name: "Threshold",
        type: 'line',
        data: [8, 8, 8, 8, 8, 8, 8, 8, 8],
    }
];

const catagories = ['10:01', '10:02', '10:03', '10:04', '10:05', '10:06', '10:07', '10:08', '10:09'];

const SignupButton = () => {
    let navigate = useNavigate();
    return (
        <Grid p={1} item justifyContent="center" alignItems="center" textAlign={'center'}>
            <Button variant='contained' color="primary" onClick={() => navigate('/login')} sx={{ backgroundColor: 'secondary.main', width: '200px' }}>
                Sign Up
            </Button>
        </Grid>

    );
};

const BladeComponent = ({ leftColumn, rightColumn, boxSx }) => {
    return (
        <Box padding={4} sx={{ overflowX: 'hidden', minHeight: '450px', ...boxSx }}>
            <Grid container spacing={4} sx={{ minHeight: '450px' }}>
                <Grid container item spacing={4} justifyContent="center" alignItems="center" md={6} >
                    {leftColumn}
                </Grid>
                <Grid container item spacing={4} justifyContent="center" alignItems="center" md={6} >
                    {rightColumn}
                </Grid>
            </Grid>
        </Box>
    );
};

const ContentCard = ({ icon, header, description }) => {
    return (
        <Card
            sx={{
                borderRadius: 2,
                boxShadow: 0,
                border: 2,
                borderColor: '#e5e7eb',
                padding: 0.5,
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                height: "100%",
            }}
        >

            <CardContent>
                <Grid item>
                    {/* Title & Image */}
                    <Grid container alignItems="center" mb={5}>
                        {icon && (
                            <Grid item>
                                <Box component="span" sx={{ color: 'secondary.main', display: 'flex', alignItems: 'center', mr: 1 }} >
                                    {icon}
                                </Box>
                            </Grid>
                        )}
                        <Grid item>
                            <Typography sx={{ typography: 'cardTitle', fontWeight: 600 }}>
                                {header}
                            </Typography>
                        </Grid>
                    </Grid>
                    {/* Text */}
                    <Grid container>
                        <Grid item>
                            <Typography paragraph sx={{ color: '#4b5563' }}>
                                {description}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

    );
}

export default function Home() {

    return (
        <React.Fragment>

            {/* Title & Image */}
            <Box sx={{
                minHeight: '70vh',
                background: 'linear-gradient(to bottom, #2a3439 40%, white)',
                color: '#fff',
                backgroundSize: 'cover',
                backgroundPosition: 'center',

            }}>
                <Grid container>
                    {/* Title */}
                    <Grid item mt={2} justifyContent="left" md={12} padding={4} textAlign={"center"}>
                        <Typography sx={{ typography: "title", fontWeight: '700', mb: 2 }}>
                            Monitor Your Service With <Box component="span" sx={{ typography: "callout", color: 'secondary.main' }}>Beholdr.io</Box>
                        </Typography>
                        <Typography gutterBottom sx={{ typography: 'subtitle' }}>
                            Keep it simple with Beholdr.io monitoring, get notified, and resolve issues without the hassle of a full observability platform.
                        </Typography>
                    </Grid>
                    {/* Image */}
                    <Grid container justifyContent={"center"}>
                        <Grid item>
                            <Box
                                component="img"
                                src={`/assets/images/Monitor-Table-Mock-Animation.webp`}
                                alt="Beholdr Monitor Mock Video"
                                sx={{
                                    width: '100%',
                                    height: 'auto',
                                    maxHeight: '600px',
                                    objectFit: 'contain',
                                }}
                            ></Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            {/* Monitoring Features */}
            <Grid container padding={4} spacing={5} justifyContent="center" alignItems="stretch" sx={{ mb: 10 }}>
                <Grid item textAlign={'center'} md={12}>
                    <Typography mb={3} sx={{ typography: 'title', fontWeight: '700' }}>
                        Monitoring Features
                    </Typography>
                </Grid>
                {featureCallouts.map((callout, index) => (
                    <Grid item key={index} md={5} xs={12}>
                        <ContentCard
                            icon={callout.icon}
                            header={callout.header}
                            description={callout.description}
                        />
                    </Grid>

                ))}
            </Grid>


            {/* Four Squares */}
            <Box padding={4} sx={{ bgcolor: 'primary.main', width: 'fit-content', margin: 'auto', mb: 10 }}>
                <Grid container p={2} spacing={5} justifyContent={"center"}>
                    {beholdrInformationSection.map((callout, index) => (
                        <Grid item key={index} md={5} xs={12}>
                            <ContentCard
                                icon={callout.icon}
                                header={callout.header}
                                description={callout.description}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {/* <BladeComponent
                leftColumn={
                    <Grid item>
                        <Typography>Image</Typography>
                    </Grid>
                }
                rightColumn={
                    <Grid item>
                        <Typography sx={{ typography: "h2", color: "white" }} gutterBottom>Staying Informed Without the Complexity</Typography>
                        <Typography paragraph sx={{ color: "white" }}>
                            Beholdr.io is your solution for straightforward, reliable service monitoring. We fill the gap when you need to know exactly when things go wrong, without the hassle of complex integrations or lengthy setups. Add monitoring to your services effortlessly and receive instant alerts the moment issues arise. Beholdr.io keeps you informed precisely when you need to be, ensuring smooth operations without unnecessary complexity.
                        </Typography>
                    </Grid>
                }
                boxSx={{ bgcolor: 'primary.main' }}
            />

            <BladeComponent
                leftColumn={
                    <Grid item>
                        <Typography sx={{ typography: "h2" }} gutterBottom>Define Error Tolerances and Stay Ahead </Typography>
                        <Typography paragraph>
                            Keep your services running smoothly with Beholdr.io's Monitors. Define intelligent rules to watch over your service's metrics and get instant alerts when thresholds are crossed. Customize your monitor's name, set notification channels, and define the time window and error limits. Stay ahead with targeted, actionable notifications that help you address issues before they impact your operations. Beholdr.io Monitors—your proactive alert system for seamless service performance.
                        </Typography>
                    </Grid>
                }
                rightColumn={

                    <Box width="100%" minHeight="350px">
                        <MonitorMetricChart series={dataSeries} categories={catagories} height={400} />
                    </Box>
                }
                boxSx={undefined}
            />
            <BladeComponent
                leftColumn={
                    <>
                        <Grid item>
                            <Box
                                component="img"
                                src={"/assets/icons/Slack_icon_2019.svg.png"}
                                sx={{
                                    width: "100px",
                                    height: "auto"
                                }}
                            ></Box>
                        </Grid>
                        <Grid item>
                            <Box
                                component="img"
                                src={TeamIcon}
                                sx={{
                                    width: "150px",
                                    height: "auto",
                                    m: '0px',
                                    p: '0px'
                                }}
                            ></Box>
                        </Grid>
                        <Grid item>
                            <Box
                                component="img"
                                src={"SMSIcon"}
                                sx={{
                                    width: "100px",
                                    height: "auto"
                                }}
                            ></Box>
                        </Grid>
                        <Grid item>
                            <Box
                                component="img"
                                src={GmailIcon}
                                sx={{
                                    width: "100px",
                                    height: "auto"
                                }}
                            ></Box>
                        </Grid>
                    </>
                }
                rightColumn={

                    <Grid item sx={{ color: 'white' }}>
                        <Typography sx={{ typography: "h2" }} gutterBottom>Get Notified Through Your Preferred Channels</Typography>
                        <Typography paragraph>
                            At Beholdr.io, we ensure you stay informed without the noise. When a monitor threshold is breached, notifications are sent through your chosen channels, including popular services like email and Slack. To prevent alert fatigue, we implement a 3-minute cooldown period, ensuring timely updates without overwhelming you with excessive notifications. With Beholdr.io, you receive critical information precisely when you need it, via the channels you prefer.
                        </Typography>
                    </Grid>
                }
                boxSx={{ bgcolor: 'primary.main' }}
            /> */}

            {/* Notification Channels Callout */}
            <Grid container mb={10} sx={styles.contentContainer}>
                <Grid container item textAlign="left" justifyContent="center" alignItems="center" md={6} sx={{ height: 'fit-content' }}>
                    <Grid item mt={4} pr={1} md={12}>
                        <Typography sx={{ typography: 'title', fontWeight: '700' }} gutterBottom>
                            Stay Notified Through Your Preferred Channels
                        </Typography>
                        <Typography paragraph>
                            Choose your preferred notification channels when setting up your monitors to ensure you receive alerts exactly the way you want. Currently, only email and teams notifications are supported, however, most common notification channels will be supported eventually.
                        </Typography>
                    </Grid>
                    <Grid container item spacing={3} pr={1} md={12} justifyContent="center" >
                        {/* <Grid item md={3}>
                            <Box
                                component="img"
                                src={"/assets/icons/Slack_icon_2019.svg.png"}
                                sx={{
                                    width: "85px",
                                    height: "auto"
                                }}
                            ></Box>
                        </Grid> */}
                        <Grid item md={3}>
                            <Box
                                component="img"
                                alt="Microsoft Teams Icon"
                                src={"/assets/icons/Microsoft-Teams-Logo.webp"}
                                sx={{
                                    width: "85px",
                                    height: "auto",
                                    m: '0px',
                                    p: '0px'
                                }}
                            ></Box>
                        </Grid>
                        {/* <Grid item md={3}>
                            <TextsmsOutlinedIcon sx={{ fontSize: "85px", color: "primary.main" }}></TextsmsOutlinedIcon>
                        </Grid> */}
                        <Grid item md={3}>
                            <Box
                                component="img"
                                alt="Email Icon"
                                src={"/assets/icons/Gmail_icon.webp"}
                                sx={{
                                    width: "85px",
                                    height: "auto"
                                }}
                            ></Box>
                        </Grid>

                    </Grid>
                    {/* Code Examples */}
                    <Grid item xs={12} mt={4} md={12} sx={{ mb: 5 }}>
                        <Typography sx={{ typography: "title2", fontWeight: '600' }}>
                            Sending Your Metrics to Beholdr.io
                        </Typography>
                        <Typography paragraph>
                            Once you have configured your resources in Beholdr.io and generated your service's API key, you can quickly enable Beholdr.io to monitor your service on your behalf.
                        </Typography>
                        <CodeBlockDisplay codeBlocks={codeExamples} />
                    </Grid>
                </Grid>
                <Grid container item justifyContent="center" md={6} >
                    <Box
                        component="img"
                        alt="Email Icon"
                        src={'/assets/images/GmailNotificationMockup.webp'}
                        sx={{
                            width: {
                                xs: '350px',
                                sm: 'auto',
                                md: 'auto',
                            },
                            height: "auto"
                        }}
                    ></Box>
                </Grid>
            </Grid>

            {/* Pricing */}
            <Grid container mb={10} spacing={2} justifyContent="center" alignItems="center" sx={styles.contentContainer}>
                <Grid container mb={5} >
                    <Grid item xs={12} md={12} textAlign="center">
                        <Typography gutterBottom sx={{ typography: 'title', fontWeight: 700 }}>
                            Pricing
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} textAlign="center">
                        <Typography gutterBottom sx={{ typography: 'subtitle' }}>
                            Choose the plan that's right for you
                        </Typography>
                    </Grid>
                </Grid>

                {
                    pricingTiers.map((tier) => (
                        <PricingCard tier={tier} buttons={<SignupButton />} />
                    ))
                }
            </Grid>

            {/* Questions */}
            <Grid container mb={10} spacing={1} p={1} justifyContent="center" alignItems="center" sx={{ mt: 5 }}>
                <Grid item xs={12} md={12} textAlign="center">
                    <Typography variant="h1" gutterBottom>
                        Q&A
                    </Typography>
                </Grid>
                {pricingQuestions.map((item, index) => (
                    <Grid container item justifyContent="center" alignItems="center">
                        <Grid item md={12}>
                            <Accordion key={index}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography sx={{ typography: "h2" }}>
                                        {item.question}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography paragraph>
                                        {item.answer}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                ))}
            </Grid>

        </React.Fragment >
    );
};

