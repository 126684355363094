import React, { useState } from 'react';
import { Tabs, Tab, Box, Grid } from '@mui/material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// You can choose any style as per your preference
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Widgets } from '@mui/icons-material';

interface CodeBlock {
  label: string;
  content: string;
  language: string;
}

interface CodeTabsProps {
  codeBlocks: CodeBlock[];
}

function TabPanel(props: { children?: React.ReactNode, value: number, index: number }) {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}      
    >
      {value === index && (
        <Box sx={{ 
            p: 3,
            overflowY: 'auto',
        }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const CodeBlockDisplay: React.FC<CodeTabsProps> = ({ codeBlocks }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid item sx={{maxWidth: {xs: '300px', sm: '450px',  md: '100%'} }}>
      <Tabs value={value} onChange={handleChange} aria-label="" sx={{width: '100%'}}>
        {codeBlocks.map((block, index) => (
          <Tab key={index} label={block.label} id={`tab-${index}`} aria-controls={`tabpanel-${index}`} />
        ))}
      </Tabs>
      {codeBlocks.map((block, index) => (
        <TabPanel key={index} value={value} index={index} >
          <SyntaxHighlighter language={block.language} style={atomDark}>
            {block.content}
          </SyntaxHighlighter>
        </TabPanel>
      ))}
    </Grid>
  );
};

export default CodeBlockDisplay;
