import axios from 'axios';
import Cookies from 'js-cookie';


const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL

});

api.interceptors.request.use(
    config => {
        const token = Cookies.get(process.env.REACT_APP_TOKEN_NAME);
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
);

api.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        // Check for 401 Unauthorized response
        if (error.response && error.response.status === 401) {
            Cookies.remove(process.env.REACT_APP_TOKEN_NAME);
            window.location.href = '/login';
        }
        // Return any error which is not due to unauthorized access
        return Promise.reject(error);
    }
);


export default api;