import React from 'react';
import { Container, Typography, Grid, ThemeProvider } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import CodeBlockDisplay from '../common/CodeBlockDisplay.tsx';
import { mainTheme } from '../../theme.tsx';
import { Section } from '../common/CommonComponents.tsx';
import { styles } from '../../style.tsx';

const monitor_subscriber_json_text = `

This field specifies the notification channels activated when your monitors are triggered. The input should be a string of email addresses delimited by commas.

\`\`\`json
your.email@example.com, your.email@example.com
\`\`\`

`;

const monitor_types_text = `
*Threshold*

This monitor type will notify the specified channels only when the number of errors exceed the threshold in the specified window of seconds.
`;

const service_text = `
This entity represents the service within your organization that you want Beholdr.io to monitor. It should use Beholdr.io's *Service API Keys* for authentication and submitting data. Your service must send requests to Beholdr.io's REST API endpoint.
`;

const user_text = `
This is the users underneath your organization that have varying degrees of access to your resources

#### Organization Admin
Empowered with the highest level of access, Organization Administrators have the authority to modify and create all resources within the organization. They play a pivotal role in shaping the organization's structure and capabilities.

#### Admin
Administrators have the privilege to modify certain resources within the organization. Their access is comprehensive but tailored, allowing them to manage day-to-day operations effectively while ensuring security protocols are upheld.
#### User
Users possess read-only access to the organization's resources. This role is designed for individuals who require information and insights from organizational resources without the need for modification privileges.

`;

const service_api_keys_text = `
This key is essential for authenticating your service's requests to Beholdr.io's \`POST https://beholdr.io/metrics/create/\` endpoint. To incorporate the key into your requests, simply add the secret key given to you upon the creation of a new secret key. It should be included as a header in your request, using X-Api-Key as the key name.

\`\`\`json
POST https://beholdr.io/metrics/create/
Headers:
{ X-Api-Key: YOUR_SECRET_KEY_HERE }
\`\`\`
`;


const curlCodeExample = `
curl -X POST "https://beholdr.io/api/metrics/create/" 
     -H "X-Api-Key: xxxxx.xxxxxxxxxxxxxxxxxx" 
     -H "Accept: application/json" 
     -H "Content-Type: application/json" 
     -d '{"status_code": 200, "message": "Service process completed successfully."}'

`;
const pythonCodeExample = `
import requests
url = "https://beholdr.io/api/metrics/create/"
    
# Set the Api Key for your Service
headers = {
    "X-Api-Key": "xxxxx.xxxxxxxxxxxxxxxxxx",
    "Accept": "application/json",
}
# Define your service's metric
metric = {
    "status_code": 200,
    "message": "Service process completed successfully."
}
# Send your metric to Beholdr.io to ingest
response = requests.post(url, headers=headers, data=metric)
`;


const codeExamples = [
    { label: 'Curl', language: 'bash', content: curlCodeExample },
    { label: 'Python', language: 'python', content: pythonCodeExample },
];


const content = [
    {
        'header': 'Organization',
        'content': (
            <>
                <Typography paragraph>
                    An organization serves as the central hub that encompasses all of your resources, such as users, services, and more. By registering and establishing an organization, you unlock the capability to add services and users within this unified entity. Consequently, all of your resources will be seamlessly integrated and associated with this singular organization, streamlining management and access across your platform.
                </Typography>
                <Typography sx={{ typography: 'h3' }}>
                    Attributes
                </Typography>
                <Typography sx={{ mt: 2, typography: 'bold' }} gutterBottom>
                    Name
                </Typography>
                <Typography paragraph gutterBottom>
                    This will be the name of your organization, and will need to be unique.
                </Typography>
                <Typography sx={{ mt: 2, typography: 'bold' }} gutterBottom>
                    Main Email Contact
                </Typography>
                <Typography paragraph gutterBottom>
                    This email address will serve as the primary channel for all communications related to your organization's engagement with Beholdr.io, including billing, security, updates, and other pertinent information. Please ensure regular monitoring of this email to stay informed about matters concerning your organization.
                </Typography>
                <Typography sx={{ mt: 2, typography: 'bold' }} gutterBottom>
                    Allow Usage Plan Rollover
                </Typography>
                <Typography paragraph gutterBottom>
                    This setting enables automatic scaling of your organization's usage plan in Beholdr.io, ensuring that your services continue without interruption. If you opt not to allow automatic scaling, Beholdr.io will cap your usage to the limits of your current plan. Should you reach these limits, your organization will receive a 426 error, indicating that you have reached your plan's capacity for metrics and/or notifications. Further submissions will be restricted until the start of the next month or until you upgrade your usage plan.
                </Typography>
                <Typography sx={{ mt: 2, typography: 'bold' }} gutterBottom>
                    Usage Plan
                </Typography>
                <Typography paragraph gutterBottom>
                    This is the current usage plan for your organization, which sets the limits for your Beholdr.io usage. Please note that you cannot select a plan with lower limits than your current usage directly. If you wish to reduce your usage below the current levels, you will need to wait until the first of the next month to downgrade your subscription. Alternatively, you can contact devin@beholdr.io at any time for manual assistance with downgrading your plan.
                </Typography>
            </>
        )
    },
    {
        'header': 'Monitor',
        'content': (
            <>
                <Typography paragraph gutterBottom>
                    Monitors are defined rules that Beholdr.io applies when analyzing your service's metrics. They dictate the conditions under which Beholdr.io will alert you, ensuring you're promptly notified whenever your specified monitor criteria are met.                </Typography>
                <Typography sx={{ typography: 'h3' }}>
                    Attributes
                </Typography>
                <Typography sx={{ mt: 2, typography: 'bold' }} gutterBottom>
                    Name
                </Typography>
                <Typography paragraph gutterBottom>
                    This will be the name of your monitor. The name does not need to be unique, but it should have a meaningful name.
                </Typography>
                <Typography sx={{ mt: 2, typography: 'bold' }} gutterBottom>
                    Metric Name
                </Typography>
                <Typography paragraph gutterBottom>
                    This field defines the specific metrics that this Monitor will track and assess. These metric names are drawn from the metrics your organization has provided to Beholdr.io.
                </Typography>
                <Typography sx={{ mt: 2, typography: 'bold' }} gutterBottom>
                    Subscribers
                </Typography>
                <Typography variant="body1" gutterBottom component="div">
                    <ReactMarkdown>{monitor_subscriber_json_text}</ReactMarkdown>
                </Typography>
                <Typography sx={{ mt: 2, typography: 'bold' }} gutterBottom>
                    Window
                </Typography>
                <Typography paragraph gutterBottom>
                    This field specifies the time frame, in seconds, within which to evaluate if your service has surpassed its predefined threshold.
                </Typography>
                <Typography sx={{ mt: 2, typography: 'bold' }} gutterBottom>
                    Threshold
                </Typography>
                <Typography paragraph gutterBottom>
                    This field determines the maximum allowable number of error metrics within the specified window before triggering a notification to the designated channels.
                </Typography>
                <Typography sx={{ mt: 2, typography: 'bold' }} gutterBottom>
                    Notification Content
                </Typography>
                <Typography paragraph gutterBottom>
                    This will be the message included in the notification sent to the designated channels.
                </Typography>
                <Typography sx={{ mt: 2, typography: 'bold' }} gutterBottom>
                    Monitor Type
                </Typography>
                <Typography paragraph gutterBottom>
                    <ReactMarkdown>{monitor_types_text}</ReactMarkdown>
                </Typography>
                <Typography sx={{ mt: 2, typography: 'bold' }} gutterBottom>
                    Service
                </Typography>
                <Typography paragraph gutterBottom>
                    This will be the specific service within your organization that this monitor will be tracking metrics for.
                </Typography>
            </>
        )
    },
    {
        'header': 'Notifications',
        'content': (
            <>
                <Typography paragraph gutterBottom>
                    When Beholdr.io detects that a monitor threshold has been breached, you will be notified through your specified communication channels. To avoid overwhelming you with alerts, notifications are managed with a 3-minute cooldown period. This ensures that you are informed promptly without receiving excessive notifications when issues arise.
                </Typography>
            </>
        )
    },
    {
        'header': 'Service',
        'content': (
            <>
                <Typography paragraph gutterBottom>
                    <ReactMarkdown>{service_text}</ReactMarkdown>
                    <CodeBlockDisplay codeBlocks={codeExamples} />
                </Typography>
            </>
        )
    },
    {
        'header': 'User',
        'content': (
            <>
                <Typography paragraph gutterBottom>
                    <ReactMarkdown>{user_text}</ReactMarkdown>
                </Typography>
            </>
        )
    },
    {
        'header': 'Service API Key',
        'content': (
            <>
                <Typography paragraph gutterBottom>
                    <ReactMarkdown>{service_api_keys_text}</ReactMarkdown>
                </Typography>
            </>
        )
    },
]

function InformationPage() {
    return (
        <ThemeProvider theme={mainTheme}>
            <Container disableGutters maxWidth={false} sx={styles.contentContainer}>
                <Typography variant="h1" gutterBottom>
                    Information Page
                </Typography>
                <Grid container direction="column" spacing={2}>
                    {content.map((item, index) => (
                        <Grid item key={index} sx={{ mb: 3 }}>
                            <Section title={item.header} content={item.content} headerStyle={{ mb: 2, typography: 'h2', color: 'primary.main' }} />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </ThemeProvider>
    );
}

export default InformationPage;
