import React from 'react';
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import DnsIcon from '@mui/icons-material/Dns';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import PeopleIcon from '@mui/icons-material/People';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import KeyIcon from '@mui/icons-material/Key';
import LogoutIcon from '@mui/icons-material/Logout';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import NotesIcon from '@mui/icons-material/Notes';

interface AppDrawerProps {
    open: boolean;
    onClose: () => void;
    isAuthenticated: boolean;
    onLogout: () => void;

}

interface NavItem {
    text: string;
    icon: React.ReactElement;
    path: string;
}

const authNavItems: NavItem[] = [
    { text: 'Home', icon: <HomeIcon />, path: '/' },
    { text: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard' },
    { text: 'Organizations', icon: <CorporateFareIcon />, path: '/organizations' },
    { text: 'Billing', icon: <CreditCardIcon />, path: '/billing' },
    { text: 'Services', icon: <DnsIcon />, path: '/services' },
    { text: 'Monitors', icon: <TroubleshootIcon />, path: '/monitors' },
    { text: 'Users', icon: <PeopleIcon />, path: '/users' },
    { text: 'Metrics', icon: <AnalyticsIcon />, path: '/metrics' },
    { text: 'Service Api Keys', icon: <KeyIcon />, path: '/service-api-keys' },
    { text: 'About', icon: <InfoIcon />, path: '/about' },
    { text: 'Info', icon: <HelpCenterIcon />, path: '/info' },
    { text: 'Onboarding', icon: <FactCheckOutlinedIcon />, path: '/onboarding' },
    { text: 'Blogs', icon: <NotesIcon/>, path: '/blogs' },
];

const unauthNavItems: NavItem[] = [
    { text: 'Home', icon: <HomeIcon />, path: '/' },
    { text: 'Login', icon: <LogoutIcon />, path: '/login' },
    { text: 'About', icon: <InfoIcon />, path: '/about' },
    { text: 'Info', icon: <HelpCenterIcon />, path: '/info' },
    { text: 'Onboarding', icon: <FactCheckOutlinedIcon />, path: '/onboarding' },
    { text: 'Blogs', icon: <NotesIcon/>, path: '/blogs' },
];


const AppDrawer: React.FC<AppDrawerProps> = ({ open, onClose, isAuthenticated, onLogout }) => {
    
    const itemsToShow = isAuthenticated ? authNavItems : unauthNavItems;

    return (
        <Drawer anchor="left" open={open} onClose={onClose}>
            <List>
                {itemsToShow.map((item) => (
                    <ListItemButton key={item.text} component={Link} to={item.path} onClick={onClose}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                    </ListItemButton>
                ))}
                {/* Conditionally rendering Logout based on authentication */}
                {isAuthenticated && (
                    <ListItemButton component={Link} to="/login" onClick={onLogout}>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItemButton>
                )}
            </List>
        </Drawer>
    );
}

export default AppDrawer;
