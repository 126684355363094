import api from '../../services/ApiService.tsx';
import { handleToastNotification } from '../common/Utils.tsx'


export const url = 'organizations/';

export interface OrganizationPatchData {
    name?: string;
    main_contact_email?: string;
    usage_rollover?: boolean;
    usage_plan?: string;
}


export const formatOrganizationCreateRequestData = (data: {}): OrganizationPatchData => {

    const organizationPatchData = {}
    const { name, main_contact_email } = data;

    if (main_contact_email !== undefined) {
        organizationPatchData.main_contact_email = main_contact_email;
    }

    if (name !== undefined) {
        organizationPatchData.name = name;
    }

    return organizationPatchData;
}

export const formatOrganizationPatchRequestData = (data: {}): OrganizationPatchData => {

    const organizationPatchData = {}
    const { name, main_contact_email, usage_rollover, usage_plan } = data;

    if (main_contact_email !== undefined) {
        organizationPatchData.main_contact_email = main_contact_email;
    }

    if (name !== undefined) {
        organizationPatchData.name = name;
    }


    if (usage_rollover !== undefined) {
        organizationPatchData.usage_rollover = usage_rollover;
    }

    if (usage_plan !== undefined) {
        organizationPatchData.usage_plan = usage_plan;
    }

    return organizationPatchData;
}


export const organizationPatchRequest = async (id: number, updatedData: OrganizationPatchData): Promise<Response> => {

    try {
        const response = await api.patch(`${url}${id}/`, updatedData);
        handleToastNotification(response);
        return response;
    } catch (error) {
        handleToastNotification(error.response);
        return error;
    }
}


export interface GetOrganizationData {
    id: number;
    name: string;
    main_contact_email: string;
    usage_rollover: boolean;
    usage_plan: string;
    is_deleted: string;
    updated: string;
    date_created: string;
}


export const formatGetOrganizationData = (
    id: number,
    name: string,
    main_contact_email: string,
    usage_rollover: boolean,
    usage_plan: string,
    is_deleted: string,
    updated: string,
    date_created: string,
): GetOrganizationData => {
    return { id, name, main_contact_email, usage_rollover, usage_plan, is_deleted, updated, date_created };
}


/**
 * Fetches organizations and sets the state.
 * @param {Function} setOrganizations The state setter function for organizations.
 */
export const fetchOrganizations = async (setOrganizations) => {
    try {
        const response = await api.get(url);
        const formattedRows: [] = response.data.data
            .map(
                response => formatGetOrganizationData(
                    response.id,
                    response.name,
                    response.main_contact_email,
                    response.usage_rollover,
                    response.usage_plan,
                    response.is_deleted,
                    response.updated,
                    response.date_created,
                )
            )
        setOrganizations(formattedRows);
    } catch (error) {
        console.error('Error fetching organizations', error);
    }
};


/**
 * TODO: Update eventually to support getting more than one organizational usage.
 * Fetches organization usage and sets the state.
 * @param {Function} setOrganizationUsage The state setter function for Organization Usage.
 */
export const fetchOrganizationUsage = async (setOrganizationUsage) => {
    try {
        const response = await api.post(`${url}usage/`);
        const organizationData: [] = response.data.data[0]
        setOrganizationUsage(organizationData);
    } catch (error) {
        console.error('Error fetching organizations', error);
    }
};

/**
 * Fetches available organization usage plans and sets the state.
 * @param {Function} setAvailableUsagePlanOptions The state setter function for Organization Usage Plans.
 */
export const fetchUsagePlans = async (setAvailableUsagePlanOptions) => {
    try {
        const response = await api.get(`/usage-plans/`);
        let availableUsagePlans: [] = response.data.data
            .map((plan, index) => {
                return { "label": plan.name, "value": index };
            });
        setAvailableUsagePlanOptions(availableUsagePlans);
    } catch (error) {
        console.error('Error fetching usage plans.', error);
    }
};


