export const  styles = {
    fullPageContainer: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    header: {
      padding: '16px',
      backgroundColor: '#f5f5f5',
      borderBottom: '1px solid #ddd',
    },
    contentContainer: {
      flex: 1,
      paddingTop: '16px',
      paddingBottom: '16px',
      paddingLeft: '32px', 
      paddingRight: '32px', 
    },
    footer: {
      padding: '16px',
      backgroundColor: '#f5f5f5',
      borderTop: '1px solid #ddd',
    },
    bulletedList: {
        '& .MuiListItem-root::before': {
          content: '"•"',
          color: 'black',
          fontWeight: 'bold',
          display: 'inline-block',
          width: '1em',
          marginLeft: '-1em',
        },
    },
    bulletListStrong: {
        '&::before': {
            content: '"•"',
            color: 'inherit', // Matches the text color
            marginRight: '4px', 
        }
    },
    description: {
        whiteSpace: 'normal', // Allow the description to wrap normally within the container bounds
        overflow: 'hidden', // Hide any overflow text if necessary
        textOverflow: 'ellipsis', // Show ellipsis if text overflows
        flex: 1, // Let the description take up the remaining space
      },
      flexContainer: {
        display: 'flex',
        alignItems: 'baseline',
        flexWrap: 'wrap', // Allows the content to wrap within the bounds if needed
      },
  };