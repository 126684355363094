import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Container, Box, Button, Grid } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AppDrawer from '../components/common/AppDrawer.tsx'; // Ensure this path is correct
import AuthService from '../services/AuthService.js';
import { Footer } from '../components/common/Footer.tsx';
import { useNavigate } from 'react-router-dom';

interface MainLayoutProps {
    children?: React.ReactNode;
}

const renderLoginLogoutButton = (navigate, isLoggedIn) => {
    const loginButton = <Button color="inherit" onClick={() => navigate('/login')}>Login</Button>;
    const logoutButton = <Button color="inherit" onClick={() => AuthService.logoutUser()}>Logout</Button>;
    const itemsToShow = isLoggedIn ? logoutButton : loginButton;
    return itemsToShow;
};

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
    const navigate = useNavigate();

    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <React.Fragment>
            <AppBar position="static">
                <Toolbar >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box
                        component="img"
                        alt="Beholdr White Logo"
                        src={`/assets/images/Beholdr_white_logo.webp`}
                        sx={{
                            width: "100px",
                            height: "auto",
                        }}
                    ></Box>
                    <Grid container justifyContent="flex-end" spacing={1} >
                        <Grid item>
                            {renderLoginLogoutButton(navigate, AuthService.isLoggedIn())}
                        </Grid>
                        <Grid item>
                            <Button color="inherit" onClick={() => navigate('/blogs/UsingBeholdrIoToMonitorYourService')}>Integrate</Button>
                        </Grid>
                        <Grid item>
                            <Button color="inherit" onClick={() => navigate('/onboarding')}>Onboard</Button>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

            <AppDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} isAuthenticated={AuthService.isLoggedIn()} onLogout={() => AuthService.logoutUser()} />
            <Container disableGutters maxWidth={false} sx={{ maxWidth: '2040px' }}>
                {children}
            </Container>
            <Footer></Footer>
        </React.Fragment>
    );
}

export default MainLayout;
