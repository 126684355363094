import React, { useState, useEffect, useCallback } from 'react';
import { TextField, List, ListItem, ListItemText, CircularProgress, Pagination, Box, Autocomplete } from '@mui/material';
import axios from 'axios';
import debounce from 'lodash.debounce';
import api from '../../services/ApiService.tsx';

const url = 'metric-classifiers/';

interface Classifier {
    id: string;
    name: string;
    organization: string;
}

interface MetricClassifierInputProps {
    onClassifierSelect: (value: string) => void; // Callback for selected classifier
    initialInput?: string; // New prop for initial input

}

const TempComponent: React.FC<MetricClassifierInputProps> = ({onClassifierSelect, initialInput='' }) => {
    const [query, setQuery] = useState(initialInput);
    const [suggestions, setSuggestions] = useState<Classifier[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const pageSize = 50; // Number of items per page
    const [selectedValue, setSelectedValue] = useState<Classifier | null>(null);

    const fetchSuggestions = useCallback(debounce(async (query: string, page: number) => {
        setIsLoading(true);
        try {
            let params = { metric_name: query, page: page, page_size: pageSize }; 
            const response = await api.get(url, {params});
            setSuggestions(response.data.data); // Assuming the API returns a paginated response with 'results'
        } catch (error) {
            console.error('Error fetching suggestions:', error);
            setSuggestions([]);
        } finally {
            setIsLoading(false);
        }
    }, 300), []);  // 300ms delay for debouncing

    useEffect(() => {

        fetchSuggestions(initialInput, 1);
        setQuery(initialInput)
    }, [initialInput, fetchSuggestions, query]);

    const handleQueryChange = (_event: React.ChangeEvent<{}>, value: string) => {
        onClassifierSelect(value ? value : '');
        setQuery(value);
    };

    const handleSuggestionSelected = (_event: React.ChangeEvent<{}>, value: Classifier | null) => {
        onClassifierSelect(value ? value.name : '');
        setSelectedValue(value);
        setQuery(value ? value.name : '');
    };

    return (
        <Box>
            <Autocomplete
                freeSolo
                options={suggestions}
                getOptionLabel={(option) => option.name}
                inputValue={query}
                onInputChange={handleQueryChange}
                onChange={handleSuggestionSelected}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        id="metric_name"
                        label="Metric Name"
                        variant="standard"
                        fullWidth
                        placeholder="Type to search..."
                        title="This field defines the specific metric that the Monitor will track to detect any threshold breaches."
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
                renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                        {option.name}
                    </li>
                )}
                fullWidth
            />
        </Box>
    );
};


  
export default TempComponent;

