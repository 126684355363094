import React, { useState } from 'react';
import { Button, } from '@mui/material';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { handleToastNotification } from '../common/Utils.tsx';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import api from '../../services/ApiService.tsx';


const url = "reset/";

interface PasswordResetRequestPostData {
    email: string;
}

function formatPasswordResetRequestPostRequestData(
    changedFields: {}
): PasswordResetRequestPostData {
    const passwordResetRequestPostData = {}
    const { email } = changedFields;
    passwordResetRequestPostData.email = email;
    return passwordResetRequestPostData;
}


export default function PasswordResetRequest() {
    const [formData, setFormData] = useState({
        email: '',
    });
    const navigate = useNavigate();

    const handleChange = (event) => {
        const { name, type, checked, value } = event.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const body = formatPasswordResetRequestPostRequestData(formData);
        try {
            const response = await api.post(`${url}`, body);
            navigate("/dashboard");
        } catch (error) {
            handleToastNotification(error.response);
        }
    };

    return (
        <React.Fragment>
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <Typography variant="h6" gutterBottom>
                        Password Reset
                    </Typography>
                    <ToastContainer />
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="email"
                                    name="email"
                                    label="Email"
                                    fullWidth
                                    autoComplete="given-name"
                                    variant="standard"
                                    value={formData.email}
                                    onChange={handleChange}
                                    type="email"
                                />
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid item xs={6}>
                                    <Button type="submit" variant="contained" color="primary">
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
        </React.Fragment >
    );
}
