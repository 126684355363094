import React, { useState } from 'react';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { ToastContainer } from 'react-toastify';
import api from '../../services/ApiService.tsx';
import { useNavigate } from 'react-router-dom';
import { handleToastNotification } from '../common/Utils.tsx';
import { EulaModal } from './Eula.tsx';
import { toast } from 'react-toastify';


const url = 'register/';

interface RegisterData {
    first_name: String;
    last_name: String;
    username: String;
    email: String;
    password: String;
}

function formatRegisterRequestData(
    data: {}
): RegisterData {

    const userCreateData: RegisterData = {
        first_name: '',
        last_name: '',
        username: '',
        email: '',
        password: '',
    }

    const { first_name, last_name, username, email, password } = data;

    userCreateData.first_name = first_name;
    userCreateData.last_name = last_name;
    userCreateData.username = username;
    userCreateData.email = email;
    userCreateData.password = password;

    return userCreateData;
}

export default function Registration() {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        username: '',
        email: '',
        password: '',
    });

    const [eulaAccepted, setEulaAccepted] = useState(false);
    const [showEula, setShowEula] = useState(false);


    const navigate = useNavigate();

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const body = formatRegisterRequestData(formData);
        if (!eulaAccepted) {
            toast.error("You must accept the EULA to sign up.");
            return;
          }

        try {
            const response = await api.post(`${url}`, body);
            handleToastNotification(response);
            navigate("/login")
        } catch (error) {
            handleToastNotification(error.response);
        }
        console.log(formData);
    };

    const handleAcceptEula = () => {
        setEulaAccepted(true);
        setShowEula(false);
    };

    const handleUnacceptEula = () => {
        setEulaAccepted(false);
        setShowEula(false);
    };


    React.useEffect(() => { }, []);

    return (
        <React.Fragment>
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <Typography variant="h6" gutterBottom>
                        Registration
                    </Typography>
                    <ToastContainer />
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={6} >
                                <TextField
                                    required
                                    id="first_name"
                                    name="first_name"
                                    label="First Name"
                                    fullWidth
                                    autoComplete="given-name"
                                    variant="standard"
                                    value={formData.first_name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={6} >
                                <TextField
                                    required
                                    id="last_name"
                                    name="last_name"
                                    label="Last Name"
                                    fullWidth
                                    autoComplete="family-name"
                                    variant="standard"
                                    value={formData.last_name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="username"
                                    name="username"
                                    label="Username"
                                    fullWidth
                                    autoComplete="username"
                                    variant="standard"
                                    value={formData.username}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="email"
                                    name="email"
                                    label="Email"
                                    fullWidth
                                    autoComplete="email"
                                    variant="standard"
                                    value={formData.email}
                                    onChange={handleChange}
                                    type="email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="password"
                                    name="password"
                                    label="Password"
                                    fullWidth
                                    autoComplete="current-password"
                                    variant="standard"
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={eulaAccepted}
                                            onChange={() => handleUnacceptEula()}
                                            required
                                        />
                                    }
                                    label={
                                        <>
                                            I have read and agree to the
                                            <Button
                                                variant="text"
                                                color="secondary"
                                                onClick={() => setShowEula(true)}
                                                sx={{ textDecoration: 'underline',  }}
                                            >
                                                EULA
                                            </Button>
                                        </>
                                    }
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" color="primary">
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>

                <Dialog open={showEula} onClose={() => setShowEula(false)}>
                    <DialogTitle>End User License Agreement (EULA)</DialogTitle>
                    <DialogContent>
                        <EulaModal onClose={() => setShowEula(false)} onAccept={handleAcceptEula} />
                    </DialogContent>
                </Dialog>
            </Container>
        </React.Fragment >


    );
}
