import * as React from 'react';

import { useNavigate } from 'react-router-dom';

import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import { fetchServices, formatServicePatchRequestData, url } from './common.tsx';
import { Box, IconButton, Button, Grid, Typography } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import { handleDelete, handleToastNotification } from '../common/Utils.tsx';
import DeleteIcon from '@mui/icons-material/Delete';
import api from '../../services/ApiService.tsx';
import TableLayout from '../../layouts/TableLayout.tsx';
import { styles } from '../../style.tsx';


export default function ServicesTable() {
    const [rows, setRows] = React.useState([]);

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 70,
            editable: false,
        },
        {

            field: 'name',
            headerName: 'Name',
            width: 170,
            editable: true,

        },
        {
            field: 'description',
            headerName: 'Description',
            width: 250,
            editable: true,
        },
        {
            field: 'org_account',
            headerName: 'Organization ID',
            width: 100,
            align: 'right'
        },
        {
            field: 'updated',
            headerName: 'Updated',
            width: 170,
            align: 'right',
        },
        {
            field: 'date_created',
            headerName: 'Date Created',
            width: 170,
            align: 'right'
        },
        {
            field: 'is_deleted',
            headerName: 'Deleted',
            width: 70,
            align: 'right'
        },
        {
            field: 'delete',
            headerName: 'Delete',
            sortable: false,
            renderCell: (params) => (
                <IconButton onClick={() => handleDelete(params.id, url, setRows)} aria-label="delete">
                    <DeleteIcon />
                </IconButton>
            ),
        },
    ];

    const handleProcessRowUpdate = async (updatedRow, originalRow) => {

        // Determine the changed values
        const changedValues = {};
        Object.keys(updatedRow).forEach((key) => {
            if (updatedRow[key] !== originalRow[key]) {
                changedValues[key] = updatedRow[key];
            }
        });

        // Return original row and prevent request being made when no changes were made.
        if (Object.keys(changedValues).length === 0) {
            return originalRow;
        }


        // Format editied fields for patch request. Notify user if json field are not parseable.
        let body = formatServicePatchRequestData(changedValues);


        // Update updatedRow with parsed inputs
        Object.keys(body).forEach((key) => {
            if (updatedRow[key] !== body[key]) {
                updatedRow[key] = body[key];
            }
        });

        try {
            const response = await api.patch(`${url}${updatedRow.id}/`, body);
            handleToastNotification(response);
            return updatedRow;
        } catch (error) {
            handleToastNotification(error.response);
            return originalRow;
        }

    };


    const navigate = useNavigate();

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchServices(setRows);
            } catch (error) {
                handleToastNotification(error.response);
            }
        };
        fetchData();
    }, []);

    return (
        <Grid container spacing={5} sx={styles.contentContainer}>
            <Grid item xs={12} md={12}>
                <Typography variant="h2" gutterBottom>
                    Services
                </Typography>
                <TableLayout buttonPath='/services/create' buttonText='New Service'>
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            pageSizeOptions={[5, 10, 25]}
                            checkboxSelection
                            disableRowSelectionOnClick
                            processRowUpdate={handleProcessRowUpdate}
                        />
                        <ToastContainer />
                    </Box>
                </TableLayout>
            </Grid>
        </Grid>

    );
}