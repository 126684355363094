import api from "../../services/ApiService.tsx";
import React from 'react';
import { Container, Paper, Typography, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import MetricClassifierInput from "../metrics/MetricClassifierInput.tsx";


export const url = 'monitors/';


export function validateEmails(input: string): boolean {
    // Regular expression to validate email
    const emailPattern: RegExp = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    // Split the input string into emails and remove whitespace
    const emails: string[] = input
        .split(',')
        .map(item => item.trim())
        .filter(item => item.includes('email:'))
        .map(item => item.replace('email:', '').trim());
    


    let result = true;

    // Check each email
    emails.forEach(email => {
        if (!emailPattern.test(email)) {
            result = false;
        }
    });

    return result;
}

export function validateTeamsInput(input: string): boolean {
    
    const urlPattern: RegExp = /^(https?|ftp):\/\/(?:www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+(:\d+)?(\/[^\s]*)?$/;

    const teams: string[] = input
        .split(',')
        .map(item => item.trim())
        .filter(item => item.includes('teams:'))
        .map(item => item.replace('teams:', '').trim());
    
    let result = true;

    // Check each email
    teams.forEach(email => {
        if (!urlPattern.test(email)) {
            result = false;
        }
    });

    return result;

}

export interface GetMonitorData {
    id: number;
    name: string;
    metric_name: string;
    type: string;
    service: string;
    window: string;
    threshold: string;
    subscribers: string;
    notification_content: string;
    is_deleted: string;
    date_created: string;
}


export const formatGetMonitorData = (
    id: number,
    name: string,
    metric_name: string,
    type: string,
    service: string,
    window: string,
    threshold: string,
    subscribers: string,
    notification_content: string,
    is_deleted: string,
    date_created: string,
): GetMonitorData => {

    return { id, name, metric_name, type, service, window, threshold, subscribers, notification_content, is_deleted, date_created };
}

export interface MonitorPatchData {
    type?: string;
    name?: string;
    window?: string;
    metric_name?: string;
    threshold?: string;
    subscribers?: JSON;
    notification_content?: string;
    service?: string;
}

export function formatMonitorPatchRequestData(
    changedFields: {}
): MonitorPatchData {

    const monitorPatchData = {}
    const { monitor_type, name, window, metric_name, threshold, subscribers, notification_content, service } = changedFields;

    if (monitor_type !== undefined) {
        monitorPatchData.type = monitor_type;
    }

    if (name !== undefined) {
        monitorPatchData.name = name;
    }

    if (window !== undefined) {
        monitorPatchData.window = window;
    }

    if (metric_name !== undefined) {
        monitorPatchData.metric_name = metric_name;
    }

    if (threshold !== undefined) {
        monitorPatchData.threshold = threshold;
    }

    if (subscribers !== undefined) {
        monitorPatchData.subscribers = subscribers;
    }

    if (notification_content !== undefined) {
        monitorPatchData.notification_content = notification_content;
    }

    if (service !== undefined) {
        monitorPatchData.service = service;
    }

    if (name !== undefined) {
        monitorPatchData.name = name;
    }

    return monitorPatchData;
}


export const fetchMonitors = async (setRows) => {
    try {
        const response = await api.get(url);
        const formattedRows: [] = response.data.data
            .filter(row => row.is_deleted != true)
            .map(
                row => formatGetMonitorData(
                    row.id,
                    row.name,
                    row.metric_name,
                    row.type,
                    row.service,
                    row.window,
                    row.threshold,
                    row.subscribers,
                    row.notification_content,
                    row.is_deleted,
                    row.date_created,
                )
            )
        setRows(formattedRows);
    } catch (error) {
        console.error('Error fetching organizations', error);
    }
};

export const fetchMonitor = async (setFormData, monitor_id) => {
    try {
        const response = await api.get(`${url}${monitor_id}/`);
        const data = response.data.data;
        const formattedData = formatGetMonitorData(
            data.id,
            data.name,
            data.metric_name,
            data.type,
            data.service,
            data.window,
            data.threshold,
            data.subscribers,
            data.notification_content,
            data.is_deleted,
            data.date_created,
        )

        setFormData(
            {
                name: formattedData.name,
                subscribers: formattedData.subscribers,
                window: formattedData.window,
                threshold: formattedData.threshold,
                notification_content: formattedData.notification_content,
                metric_name: formattedData.metric_name,
                monitor_type: formattedData.type,
                service: formattedData.service,
            }
        );
    } catch (error) {
        console.error('Error fetching monitor', error);
    }
};


export const MonitorForm = ({ formData, handleChange, handleSubmit, handleClassifierChange, services, formTitle }) => {
    const renderThresholdField = (formData) => {
        if (formData.monitor_type === 2) {
            return (
                <Grid item xs={12}>
                    <TextField
                        required
                        id="threshold"
                        name="threshold"
                        label="Threshold"
                        fullWidth
                        autoComplete="given-name"
                        variant="standard"
                        type="number"
                        title="This is the number of error messages that are allowed within the window before a notification is sent."
                        value={formData.threshold}
                        onChange={handleChange}
                    />
                </Grid>
            );
        }
        formData.threshold = null;
        return null;
    }

    return (
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                <Typography variant="h6" gutterBottom>
                    {formTitle}
                </Typography>
                <ToastContainer />
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="name"
                                name="name"
                                label="Name"
                                fullWidth
                                autoComplete="given-name"
                                variant="standard"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="subscribers"
                                name="subscribers"
                                label="Subscribers Email List"
                                fullWidth
                                multiline
                                rows={4}
                                autoComplete="given-name"
                                variant="standard"
                                value={formData.subscribers}
                                onChange={handleChange}
                                placeholder="email:example@example.com, teams:https://teams_channel_webhook"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MetricClassifierInput
                                onClassifierSelect={handleClassifierChange}
                                initialInput={formData.metric_name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="window"
                                name="window"
                                label="Window"
                                rows={2}
                                type="number"
                                fullWidth
                                autoComplete="given-name"
                                variant="standard"
                                value={formData.window}
                                title="This is the range of seconds to consider when searching for error messages exceeding the threshold."
                                onChange={handleChange}
                            />
                        </Grid>
                        {renderThresholdField(formData)}
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="notification_content"
                                name="notification_content"
                                label="Notification Content"
                                fullWidth
                                autoComplete="given-name"
                                variant="standard"
                                title="The plain text content to include in the notification message."
                                value={formData.notification_content}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="monitor_type_label">Select Monitor Type</InputLabel>
                                <Select
                                    labelId="monitor_type_label"
                                    value={formData.monitor_type}
                                    label="Monitor Type"
                                    name="monitor_type"
                                    onChange={handleChange}
                                >
                                    <MenuItem
                                        value={2}
                                        title="If error messages exceed the threshold in the window of time, generate notification."
                                    >
                                        Threshold
                                    </MenuItem>
                                    <MenuItem
                                        value={3}
                                        title="If no metrics are received within the window provided, you will be notified."
                                    >
                                        Heartbeat
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="service_label">Select Service</InputLabel>
                                <Select
                                    labelId="service_label"
                                    value={formData.service}
                                    label="Monitor Services"
                                    name="service"
                                    onChange={handleChange}
                                >
                                    {services.map((service) => (
                                        <MenuItem key={service.id} value={service.id}>
                                            {service.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary">
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container>
    );
};