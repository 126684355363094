
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { mainTheme } from '../../theme.tsx';



interface CustomizedMonitorMetricChartProps {
    series: ApexAxisChartSeries; // Data series
    categories: string[]; // Categories for the x-axis
    height: string | number; // Chart height
    width?: string | number; // Chart height

}

const MonitorMetricChart: React.FC<CustomizedMonitorMetricChartProps> = ({ series, categories, height, width }) => {
    const chartOptions: ApexCharts.ApexOptions = {
        chart: {
            type: 'line',
            stacked: false,
            fontFamily: mainTheme.typography.fontFamily,
            
            
        },
        colors: ['#255aee', '#ff0000'], // Metric Line Color, Threshold Color
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [0, 2],
            curve: 'smooth'
        },
        title: {
            text: '',
            align: 'left'
        },
        xaxis: {
            title: {
                text: 'Time'
            },
            categories: categories
        },
        yaxis: {
            title: {
                text: 'Count'
            },
        },
        tooltip: {
            shared: true,
            intersect: false,
        },
        legend: {
            labels: {
                useSeriesColors: true
            },
        },
        fill: {
            type: ['solid'],
            opacity: [0.5, 1] // Reduced opacity for area to ensure line remains visible

        },
    };

    return (
        <ReactApexChart
            options={chartOptions}
            series={series}
            type="line" // This should match the primary type specified in options
            height={height}
            width={width}
        />
    );
};
export default MonitorMetricChart;
