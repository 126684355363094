import React from 'react';
import { Box, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { mainTheme } from '../theme.tsx';

interface TableLayoutProps {
    children: React.ReactNode;
    buttonPath: string;
    buttonText: string;
}

const TableLayout: React.FC<TableLayoutProps> = ({ children, buttonPath, buttonText }) => {
    const navigate = useNavigate();

    return (
        <>
            <ThemeProvider theme={mainTheme}>
                <Grid container p={1}>
                    {children}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate(buttonPath)}
                        sx={{ marginBottom: 2, mt: 2 }}
                    >
                        {buttonText}
                    </Button>
                </Grid>
                {/* <Box sx={{ height: 400, width: '100%', mb: 2 }}> */}
                {/* </Box> */}

            </ThemeProvider>

        </>
    );
};

export default TableLayout;
