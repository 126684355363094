import React from 'react';
import { Route, Routes } from 'react-router-dom';
// import Login from './components/Login'; // Adjust the path as necessary for your project structure
import ProtectedRoute from "./components/ProtectedRoute";
import "./App.css";

import Dashboard from './components/general/Dashboard.tsx';
import ServicesTable from './components/services/ServicesTable.tsx';
import UsersTable from './components/users/UsersTable.tsx';
import 'react-toastify/dist/ReactToastify.css';
import OrganizationForm from './components/organizations/OrganizationForm.tsx';
import UserForm from './components/users/UserForm.tsx';
import MonitorTable from './components/monitor/MonitorTable.tsx';
import NewMonitorForm from './components/monitor/NewMonitorForm.tsx';
import ServicesForm from './components/services/ServicesForm.tsx';
import MainLayout from './layouts/MainLayout.tsx';
import ServiceApiKeysTable from './components/service_api_keys/ServiceApiKeysTable.tsx';
import ServiceApiKeysForm from './components/service_api_keys/ServiceApiKeysForm.tsx';
import Login from './components/general/Login.tsx';
import About from './components/general/About.tsx';
import Registration from './components/general/Registration.tsx';
import InformationPage from './components/general/Info.tsx';
import RegistrationSuccess from './components/general/RegistrationSuccess.tsx';
import EmailOptOut from './components/general/EmailOptOut.tsx';
import PasswordReset from './components/password/PasswordReset.tsx';
import PasswordResetSuccess from './components/password/PasswordResetSuccess.tsx';
import PasswordResetRequest from './components/password/PasswordResetRequest.tsx';
import Home from './components/general/Home.tsx';
import OrganizationView from './components/organizations/OrganizationView.tsx';
import PrivacyPolicy from './components/general/PrivacyPolicy.tsx';
import EditMonitorForm from './components/monitor/EditMonitorForm.tsx';
import ContactUs from './components/general/ContactUs.tsx';
import MetricView from './components/metrics/MetricView.tsx';
import TempComponent from './components/common/Temp.tsx';
import PaymentSuccess from './components/general/PaymentSuccess.tsx';
import Subscription from './components/billing/Subscription.tsx';
import Onboarding from './components/common/Onboarding.tsx';
import { usePageTracking } from './components/common/CommonComponents.tsx';

// Blogs
import Blogs from './components/blog/blogs.tsx'
import BestPracticesForMonitoringYourApplication from './components/blog/content/BestPracticesForMonitoringYourApplication.tsx'
import UsingBeholdrIoToMonitorYourService from './components/blog/content/UsingBeholdrIoToMonitorYourService.tsx'
import FixingGmailAuthenticationIssues from './components/blog/content/FixingGmailAuthenticationIssues.tsx'

function App() {
    usePageTracking();

    return (
        <Routes>
            <Route path="/*" element={<RoutesWithAppBar />} />
        </Routes>
    );
}

function RoutesWithAppBar() {
    return (
        <MainLayout>
            <Routes>
                
                {/* <Route path="/temp" element={<TempComponent />} /> */}

                <Route path="/" element={<Home />} />
                <Route path="/info" element={<InformationPage />} />
                <Route path="/register" element={<Registration />} />
                <Route path="/login" element={<Login />} />
                <Route path="/about" element={<About />} />
                <Route path="/registration/success" element={<RegistrationSuccess />} />
                <Route path="/opt-out/success" element={<EmailOptOut />} />
                <Route path="/reset" element={<PasswordResetRequest />} />
                <Route path="/reset/:uuid/:token" element={<PasswordReset />} />
                <Route path="/reset/success" element={<PasswordResetSuccess />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/payment/success" element={<PaymentSuccess />} />
                <Route path="/contact-us" element={<ContactUs/>} />
                <Route path="/onboarding" element={<Onboarding/>} />
                
                {/* Blogs */}
                <Route path="/blogs" element={<Blogs/>} />
                <Route path="/blogs/BestPracticesForMonitoringYourApplication" element={<BestPracticesForMonitoringYourApplication/>} />
                <Route path="/blogs/UsingBeholdrIoToMonitorYourService" element={<UsingBeholdrIoToMonitorYourService/>} />
                <Route path="/blogs/FixingGmailAuthenticationIssues" element={<FixingGmailAuthenticationIssues/>} />

                <Route
                    path="/metrics"
                    element={
                        <ProtectedRoute>
                            <MetricView />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/organizations"
                    element={
                        <ProtectedRoute>
                            <OrganizationView />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/organizations/create"
                    element={
                        <ProtectedRoute>
                            <OrganizationForm />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/monitors"
                    element={
                        <ProtectedRoute>
                            <MonitorTable />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/monitors/create"
                    element={
                        <ProtectedRoute>
                            <NewMonitorForm />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/monitor/edit/:monitorId"
                    element={
                        <ProtectedRoute>
                            <EditMonitorForm />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/services"
                    element={
                        <ProtectedRoute>
                            <ServicesTable />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/services/create"
                    element={
                        <ProtectedRoute>
                            <ServicesForm />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/users"
                    element={
                        <ProtectedRoute>
                            <UsersTable />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/users/create"
                    element={
                        <ProtectedRoute>
                            <UserForm />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/service-api-keys"
                    element={
                        <ProtectedRoute>
                            <ServiceApiKeysTable />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/service-api-keys/create"
                    element={
                        <ProtectedRoute>
                            <ServiceApiKeysForm />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/billing"
                    element={
                        <ProtectedRoute>
                            <Subscription/>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/dashboard"
                    element={
                        <ProtectedRoute>
                            <Dashboard />
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </MainLayout>
    );
}
export default App;
