import api from '../../services/ApiService.tsx';


export const url = 'service-api-keys/';


export interface ServiceApiKeyCreateData {
    name: String;
    service: String;
}

export function formatServiceApiKeyCreateRequestData(
    data: {}
): ServiceApiKeyCreateData {

    const serviceApiKeyCreateData: ServiceApiKeyCreateData = {
        name: '',
        service: '',
    }

    const { name, service } = data;

    serviceApiKeyCreateData.name = name;
    serviceApiKeyCreateData.service = service;

    return serviceApiKeyCreateData;
}


export const parseServiceApiKeyCreateResponse = async (response, setValue) => {
    const key = response.data.data['key']
    setValue(key);
};

export interface GetServiceApiKeyData {
    id: string;
    name: string;
    service: string;
    created: string;
    is_deleted: string;
}


export function formatGetServiceApiKeyData(
    id: string,
    name: string,
    service: string,
    created: string,
    is_deleted: string,
): GetServiceApiKeyData {
    return { id, name, service, created, is_deleted };
}

/**
 * Fetches Service Api Keys and sets the state.
 * @param {Function} setRows The state setter function for services.
 */
export const fetchServiceApiKeys = async (setRows) => {
    const response = await api.get(url);
    const formattedServiceApiKeyRows: [] = response.data.data
        .filter(serviceApiKeyJson => serviceApiKeyJson.is_deleted != true)
        .map(
            serviceApiKeyJson => formatGetServiceApiKeyData(
                serviceApiKeyJson.prefix,
                serviceApiKeyJson.name,
                serviceApiKeyJson.service,
                serviceApiKeyJson.created,
                serviceApiKeyJson.is_deleted
            )
        )
    setRows(formattedServiceApiKeyRows);
};

