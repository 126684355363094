import moment from "moment";
import api from "../../services/ApiService.tsx";

export interface FiltersProp {
    startDate: Date | null;
    endDate: Date | null;
    monitorName: string;
    metricName: string;
}

export interface MetricSearchProps {
    filters: FiltersProp;
    setFilters: React.Dispatch<React.SetStateAction<FiltersProp>>;
    signalFilterSubmittedEvent: () => void;
}

export interface MetricsTableProps {
    filters: FiltersProp;
    filterSubmittedEvent: Number;
};


export function convertToTimezoneAwareString(input) {
    const timezone_aware_date = new Date(input);
    const date = moment(timezone_aware_date);
    const formattedDateTime = date.format('YYYY-MM-DDTHH:mm:ssZ');
    return formattedDateTime;
}

export interface MetricRequestFilters {
    start_datetime?: string; // UTC
    end_datetime?: string; // UTC
    current_datetime?: string; // Timezone aware
    monitor_name?: string;
    metric_name?: string;
}

export function formatMetricRequestFilters(
    filters: {}
): MetricRequestFilters {


    const metricRequestFilters = {}
    const { startDate, endDate, monitorName, metricName } = filters;

    if (monitorName !== undefined) {
        metricRequestFilters.monitor_name = monitorName;
    }

    if (metricName !== undefined) {
        metricRequestFilters.metric_name = metricName;
    }

    if (startDate !== null) {
        metricRequestFilters.start_datetime = startDate.toISOString();
    }
    if (endDate !== null) {
        metricRequestFilters.end_datetime = endDate.toISOString();
    }
    metricRequestFilters.current_datetime = convertToTimezoneAwareString(new Date());

    return metricRequestFilters;
}


interface GetMetricsData {
    id: number;
    name: string;
    message: string;
    service: string;
    date_created: string;
}

function formatGetMetricsData(
    id: number,
    name: string,
    message: string,
    service: string,
    date_created: string,
): GetMetricsData {
    return { id, name, message, service, date_created };
}

export const getMetrics = async (setMetrics, setRowCount, params) => {
    try {
        const response = await api.get('metrics/', {params: params});
        setRowCount(response.data.count);
        const formattedMetricsRows: [] = response.data.data
            .map(
                metricJson => formatGetMetricsData(
                    metricJson.id,
                    metricJson.name,
                    metricJson.message,
                    metricJson.service,
                    metricJson.date_created,
                )
            )
            setMetrics(formattedMetricsRows);
    } catch (error) {
        console.error('Error fetching metrics', error);
    }
};
