import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { Grid, Typography } from '@mui/material';
import { styles } from '../../style.tsx';
import { handleToastNotification } from '../common/Utils.tsx';
import { formatMetricRequestFilters, getMetrics, MetricsTableProps } from './common.tsx';
import { Pages } from '@mui/icons-material';

const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: 'ID',
        width: 170,
        editable: false,
    },
    {
        field: 'name',
        headerName: 'Name',
        width: 170,
        editable: false,
    },
    {
        field: 'service',
        headerName: 'Service',
        width: 170,
        editable: false,
    },
    {
        field: 'message',
        headerName: 'Message',
        minWidth: 200,
        editable: false,
    },
    {
        field: 'date_created',
        headerName: 'Created',
        minWidth: 200,
        editable: false,
    },

];

export const MetricsTable: React.FC<MetricsTableProps> = ({filters, filterSubmittedEvent}) => {
    const [rows, setRows] = React.useState([]);
    const [page, SetPage] = React.useState(0);
    const [pageSize, SetPageSize] = React.useState(5);
    // const [paginationModel, setPaginationModel] = React.useState<GridPaginationModel>({ page: 0, pageSize: 5 });
    const [rowCount, setRowCount] = React.useState(0);

    // Function to determine row class based on status code. Used to turn error message to a light red.
    const getRowClassName = (params) => {
        // Disabled for now
        if (params.row.status_code >= 400) {
            // return 'error-metric';
        }
        return '';
    };

    const determinePageParams = () => {
        return {
            page: page + 1,
            page_size: pageSize,
        };
    }

    const fetchMetrics = async () => {
        try {
            let params = determinePageParams();
            const body = formatMetricRequestFilters(filters);
            getMetrics(setRows, setRowCount, {...params, ...body});
            } catch (error) {
            handleToastNotification(error.response);
            console.error('Error fetching metrics', error);
        }
    };

    React.useEffect(() => {
        SetPage(0);
        fetchMetrics();
    }, [filterSubmittedEvent]);

    React.useEffect(() => {
        SetPage(0);
    }, [pageSize]);

    React.useEffect(() => {
        fetchMetrics();
    }, [page]);
    

    React.useEffect(() => {
        fetchMetrics();
    }, []);

    const handlePaginationModelChange = (model: GridPaginationModel) => {
        if(model.page !== page){
            SetPage(model.page);
        } else if (model.pageSize !== pageSize){
            SetPageSize(model.pageSize);
        }
    }

    return (
        <Grid container spacing={5} sx={styles.contentContainer}>
            <Grid item xs={12} md={12}>
                <Typography variant="h2" gutterBottom>
                    Metrics
                </Typography>
                <Box sx={{ height: 400, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSizeOptions={[5, 10, 25]}
                        rowCount={rowCount}
                        paginationModel={{ page: page, pageSize: pageSize }}
                        paginationMode="server"
                        disableRowSelectionOnClick
                        onPaginationModelChange={handlePaginationModelChange}
                        getRowClassName={getRowClassName}
                    />
                </Box>
            </Grid>
        </Grid>
    );
}
