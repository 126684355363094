import React, { useState } from 'react';
import { Button, } from '@mui/material';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { handleToastNotification } from '../common/Utils.tsx';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../services/AuthService.js';


export default function Login() {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });

    const navigate = useNavigate();

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const body = (formData);
        try {
            await AuthService.loginUser(formData.email, formData.password);
            navigate("/dashboard")
        } catch (error) {
            handleToastNotification(error.response);
        }
    };

    return (
        <React.Fragment>
            <Container component="main" sx={{ mb: 4 }}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <Typography variant="h6" gutterBottom>
                        Login
                    </Typography>
                    <ToastContainer />
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} >
                                <TextField
                                    required
                                    id="email"
                                    name="email"
                                    label="email"
                                    fullWidth
                                    autoComplete="email"
                                    variant="standard"
                                    value={formData.email}
                                    onChange={handleChange}
                                    type="email"
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    required
                                    id="password"
                                    name="password"
                                    label="password"
                                    fullWidth
                                    autoComplete="current-password"
                                    variant="standard"
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button type="submit" variant="text" color="primary">
                                        Submit
                                    </Button>
                                </Grid>
                                <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        onClick={() => navigate('/reset')}
                                    >
                                        Reset Password
                                    </Button>
                                </Grid>
                                <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        onClick={() => navigate('/register')}
                                    >
                                        Sign Up
                                    </Button>
                                </Grid>


                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
        </React.Fragment >
    );
}
