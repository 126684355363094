import React, { useState } from 'react';
import { Button, } from '@mui/material';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { handleToastNotification } from '../common/Utils.tsx';
import { ToastContainer } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import AuthService from '../../services/AuthService.js';
import api from '../../services/ApiService.tsx';


const url = "reset/";

interface PasswordResetPostData {
    password: string;
}

function formatPasswordResetPostRequestData(
    changedFields: {}
): PasswordResetPostData {
    const passwordResetPostData = {}
    const { password } = changedFields;
    passwordResetPostData.password = password;
    return passwordResetPostData;
}


export default function PasswordReset() {
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: ''
    });
    const [error, setError] = useState('');
    const [confirmError, setConfirmError] = useState('');
    const { uuid, token } = useParams();

    const navigate = useNavigate();

    
    const validatePassword = (value) => {
        const lengthCheck = /^.{6,}$/;
        const letterCheck = /[A-Za-z]/;
        const digitCheck = /\d/;
        
        if (!lengthCheck.test(value)) {
            setError("Password must be at least 6 characters long.");
            return;
        }
        if (!letterCheck.test(value)) {
            setError("Password must contain at least one letter.");
            return;
        }
        if (!digitCheck.test(value)) {
            setError("Password must contain at least one digit.");
            return;
        }
        if (!digitCheck.test(value)) {
            setError("Password must contain at least one digit.");
            return;
        }
        setError('');
    };

    const handlePasswordChange = (event) => {
        const { value } = event.target;
        handleChange(event);
        validatePassword(value);

         // Check if the confirmPassword field matches the new password
         if (formData.confirmPassword && value !== formData.confirmPassword) {
            setConfirmError("Passwords do not match.");
        } else {
            setConfirmError('');
        }
    };

    const handleConfirmPasswordChange = (event) => {
        const { value } = event.target;
        handleChange(event);
        if (formData.password !== value) {
            setConfirmError("Passwords do not match.");
        } else {
            setConfirmError('');
        }
    };

    const handleChange = (event) => {
        const { name, type, checked, value } = event.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const body = formatPasswordResetPostRequestData(formData);
        
        if(error || confirmError) {
            return;
        }

        try {
            const response = await api.post(`${url}${uuid}/${token}/`, body);
            navigate("/reset/success");
        } catch (error) {
            handleToastNotification(error.response);
        }
    };

    return (
        <React.Fragment>
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <Typography variant="h6" gutterBottom>
                        Password Reset
                    </Typography>
                    <ToastContainer />
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} >
                                <TextField
                                    required
                                    id="password"
                                    name="password"
                                    label="Password"
                                    fullWidth
                                    autoComplete="new-password"
                                    variant="standard"
                                    error={!!error}
                                    helperText={error}
                                    value={formData.password}
                                    onChange={handlePasswordChange}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    required
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    fullWidth
                                    autoComplete="new-password"
                                    variant="standard"
                                    error={!!confirmError}
                                    helperText={confirmError}
                                    value={formData.confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                />
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid item xs={6}>
                                    <Button type="submit" variant="contained" color="primary">
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
        </React.Fragment >
    );
}
