import api from '../../services/ApiService.tsx';


export const url = 'services/';


export interface ServiceCreateData {
    name: String;
    description: String;
    org_account: String;
}

export function formatServiceCreateRequestData(
    data: {}
): ServiceCreateData {

    const monitorCreateData: ServiceCreateData = {
        name: '',
        description: '',
        org_account: '',
    }

    const { name, description, organization} = data;

    monitorCreateData.name = name;
    monitorCreateData.description = description;
    monitorCreateData.org_account = organization;

    return monitorCreateData;
}

export interface ServicePatchData {
    name?: string;
    description?: string;
}

export function formatServicePatchRequestData(
    changedFields: {}
): ServicePatchData {

    const ServicePatchData = {}
    const { name, description } = changedFields;

    if (name !== undefined) {
        ServicePatchData.name = name;
    }

    if (description !== undefined) {
        ServicePatchData.description = description;
    }

    return ServicePatchData;
}



export interface GetServiceData {
    id: number;
    name: string;
    description: string;
    org_account: string;
    updated: string;
    date_created: string;
    is_deleted: string;
}


export function formatGetServiceData(
    id: number,
    name: string,
    description: string,
    org_account: string,
    updated: string,
    date_created: string,
    is_deleted: string,
): GetServiceData {
    return { id, name, description, org_account, updated, date_created, is_deleted };
}

/**
 * Fetches services and sets the state.
 * @param {Function} setRows The state setter function for services.
 */
export const fetchServices = async (setRows) => {
    const response = await api.get(url);
    const formattedServiceRows: [] = response.data.data
    .filter(serviceJson => serviceJson.is_deleted != true )    
    .map(
            serviceJson => formatGetServiceData(
                serviceJson.id,
                serviceJson.name,
                serviceJson.description,
                serviceJson.org_account,
                serviceJson.updated,
                serviceJson.date_created,
                serviceJson.is_deleted,
            )
        )
    setRows(formattedServiceRows);
};

