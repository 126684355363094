import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import ServicesTable from '../services/ServicesTable.tsx';
import UsersTable from '../users/UsersTable.tsx';
import OrganizationsTable from '../organizations/OrganizationsTable.tsx';
import MonitorsTable from '../monitor/MonitorTable.tsx';
import { styles } from '../../style.tsx';
import OrganizationUsage from '../organizations/OrganizationUsageTable.tsx';
import { mainTheme } from '../../theme.tsx';

export default function Dashboard() {
    return (
        <ThemeProvider theme={mainTheme}>
            <Grid container spacing={3} sx={styles.contentContainer}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="h1" gutterBottom>
                            Usage
                        </Typography>
                        <OrganizationUsage />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <OrganizationsTable />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <ServicesTable />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <UsersTable />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <MonitorsTable />
                    </Paper>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
