import React, { useState } from 'react';
import { Button, FormControlLabel, Checkbox, RadioGroup, Radio, FormControl, InputLabel, Select, MenuItem, Slider } from '@mui/material';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { handleToastNotification } from '../common/Utils.tsx';
import { ToastContainer } from 'react-toastify';
import { formatServiceApiKeyCreateRequestData, parseServiceApiKeyCreateResponse, url } from './common.tsx';

import api from '../../services/ApiService.tsx';
import { fetchServices } from '../services/common.tsx';
import InfoCard from '../common/InfoCard.tsx';


export default function ServiceApiKeysForm() {
    const [formData, setFormData] = useState({
        name: '',
        service: ''
    });

    const [services, setServices] = useState([]);

    const [secretKey, setSecretKey] = useState('');


    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const body = formatServiceApiKeyCreateRequestData(formData);
        try {
            const response = await api.post(`${url}`, body);
            handleToastNotification(response);
            parseServiceApiKeyCreateResponse(response, setSecretKey);
        } catch (error) {
            handleToastNotification(error.response);
        }
        console.log(formData);
    };

    React.useEffect(() => {
        fetchServices(setServices);
    }, []);



    return (
        <React.Fragment>
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <Typography variant="h6" gutterBottom>
                        Create Service Api Key
                    </Typography>
                    <ToastContainer />
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} >
                                <TextField
                                    required
                                    id="name"
                                    name="name"
                                    label="Name"
                                    fullWidth
                                    variant="standard"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="select-field-label">Select Service</InputLabel>
                                    <Select
                                        required
                                        labelId="service"
                                        value={formData.service}
                                        label="Service"
                                        name="service"
                                        onChange={handleChange}
                                    >
                                        {services.map((services) => {
                                            return (
                                                <MenuItem value={services.id}>{services.name}</MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" color="primary">
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>

                </Paper>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <Typography variant="h6" gutterBottom>
                        Secret Api Access Key
                    </Typography>
                    <InfoCard
                        title="Important Information"
                        description="Use this secret key to authenticate your service's requests when submitting metrics to Beholdr. It is displayed only once for security reasons, so please ensure it's stored safely. Include it as a header in your requests, using 'X-Api-Key' as the key. This step is crucial for secure and successful communication with Beholdr."
                    />
                    <Grid container spacing={3}>
                        <Grid item xs={12} >
                            <Typography variant="body1" gutterBottom>
                                {secretKey}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </React.Fragment >
    );
}
