import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import api from '../../services/ApiService.tsx';
import { ToastContainer } from 'react-toastify';
import { handleDelete, handleToastNotification } from '../common/Utils.tsx';
import { styles } from '../../style.tsx';
import { Box, Button, Grid, Typography } from '@mui/material';
import { PricingCard, pricingTiers } from '../common/CommonComponents.tsx';
import TableLayout from '../../layouts/TableLayout.tsx';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

const stripePromise = loadStripe(
    process.env.REACT_APP_NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY
);

const SubscribeButton: React.FC = ({buttonName, reload, refresh}) => {
    const handleClick = async (event: React.MouseEvent) => {
        let stripe;
        let session;
        let response;
        try{ 
            stripe = await stripePromise;
            response =  await api.post('stripe/subscribe/');
            session = await response.data.data;
        } catch (error) {
            handleToastNotification(error.response);
            return;
        }
        
        if(session) {
            // Redirect to Checkout
            const result = await stripe?.redirectToCheckout({
                sessionId: session.id,
            });
        } else {
            handleToastNotification(response);
            if(reload === true){ refresh();}
        }
      
    };

    return (
        <>
            <Button fullWidth variant='contained' onClick={handleClick} sx={{ color: 'secondary.main', fontSize: 20 }}>
                {buttonName}
            </Button>
        </>
    );
};

const UnsubscribeButton: React.FC = ({refresh}) => {
    const handleClick = async (event: React.MouseEvent) => {
        
        try{ 
            const response =  await api.post('stripe/unsubscribe/');
            handleToastNotification(response);
            refresh();
        } catch (error) {
            handleToastNotification(error.response);
            return;
        }
        
    };

    return (
        <>
            <Button fullWidth variant='contained' onClick={handleClick} sx={{ color: 'secondary.main', fontSize: 20 }}>
                Unsubscribe
            </Button>
        </>
    );
};

const renderUsagePlanActions  = ({subscriptionInfo, pricingTiers, refresh}) => {
    let buttons;
    // The Organization has an active subscription
    if(subscriptionInfo?.active === true && subscriptionInfo?.renew === true ) {
        buttons = (
            <UnsubscribeButton refresh={refresh}/>
        );
    // The Organization has an active subscription but has turned off renewals
    } else if (subscriptionInfo?.active === true && subscriptionInfo?.renew === false ) {
        buttons = (
            <SubscribeButton buttonName={"Enable Renewal"} reload={true} refresh={refresh}/>
        );
    // The Organization does not have an active subscription.
    } else if (subscriptionInfo?.active === false && subscriptionInfo?.renew === false ) {
        buttons = (
            <SubscribeButton buttonName={"Subscribe"}/>
        );
    // Default, show both buttons if logic above breaks.
    } else {
        buttons = (
            <>
                <SubscribeButton buttonName={"Subscribe"}/>
                <UnsubscribeButton refresh={refresh}/>
            </>
        );
    }
    const freeTier = pricingTiers.filter(tier => tier.title === 'Free')[0];
    const proTier = pricingTiers.filter(tier => tier.title === 'Pro')[0];
    return (
        <>
            <PricingCard tier={freeTier} buttons={<></>} />
            <PricingCard tier={proTier} buttons={buttons}/>
        </>
        
    )
};

interface GetSubscriptionInfoData {
    id: String;
    active: String;
    renew: String;
    period_start: String;
    period_end: String;
    date_created: String;
};

const formatSubscriptionInfoData = (
    id: String,
    active: String,
    renew: String,
    period_start: String,
    period_end: String,
    date_created: String,
): GetSubscriptionInfoData => {
    if(id === null){ return null;}
    return {id, active, renew, period_start, period_end, date_created};
}

const Subscription: React.FC = () => {
    const [rows, setRows] = React.useState([]);
    const [subscriptionInfo, setSubscriptionInfo] = React.useState({});
    const [rerender, setRerender] = React.useState(0);

    const columns: GridColDef[] = [
        {
            field: 'active',
            headerName: 'Active',
            width: 70,
            editable: false,
        },
        {
            field: 'renew',
            headerName: 'Renew',
            width: 70,
            editable: false,
        },
        {
            field: 'period_start',
            headerName: 'Current Period Start',
            width: 200,
            editable: false,
        },
        {
            field: 'period_end',
            headerName: 'Current Period End',
            width: 200,
            editable: false,
        },
        {
            field: 'date_created',
            headerName: 'Date Created',
            width: 200,
            editable: false,
        },
        {
            field: 'id',
            headerName: 'ID',
            width: 70,
            editable: false,
        }
    ];

    const refresh = () => {
        setRerender(rerender + 1);
    };

    const fetchData = async () => {
        try {
            const response = await api.get(`stripe/info/`);
            const data = response.data.data;
            setSubscriptionInfo(data.subscription);
            const row = formatSubscriptionInfoData(
                data.subscription.customer,
                data.subscription.active,
                data.subscription.renew,
                data.subscription.period_start,
                data.subscription.period_end,
                data.subscription.date_created,
            );
            if(row !== null){
                setRows([row]);
            }

        } catch (error) {
            console.error('Error fetching organizations', error);
        }
    };

    React.useEffect(() => {
        fetchData();
    }, []);
    
    React.useEffect(() => {
        fetchData();
    }, [rerender]);

   


    return (
        <Grid container spacing={1} justifyContent="center" alignItems="center" sx={styles.contentContainer}>
            <ToastContainer/>
            <Grid container item textAlign="left" md={12}>
                <Typography variant='h1'>Subscription</Typography>
            </Grid>
            <Box sx={{ height: 400, width: '100%', mt: 1, mb: 5 }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                    checkboxSelection
                    disableRowSelectionOnClick
                />
            </Box>
            <Grid container item spacing={5} md={12} mb={5} justifyContent="center" alignItems="center">
                {renderUsagePlanActions({ subscriptionInfo, pricingTiers, refresh })}
            </Grid>
        </Grid>
    );
}

export default Subscription;

