import React, { useState } from 'react';
import { Button} from '@mui/material';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { handleToastNotification } from '../common/Utils.tsx';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import api from '../../services/ApiService.tsx';


const url = 'organizations/';


interface OrganizationCreateData {
    name: string;
    main_contact_email: string;
}

function formatOrganizationCreateRequestData(
    data: {}
): OrganizationCreateData {

    const organizationCreateData: OrganizationCreateData = {
        name: '',
        main_contact_email: ''
    };

    const { name, main_contact_email } = data
    organizationCreateData.main_contact_email = main_contact_email;
    organizationCreateData.name = name;
    

    return organizationCreateData;
}

export default function OrganizationForm() {
    const [formData, setFormData] = useState({
        name: '',
        main_contact_email: '',
    });

    const navigate = useNavigate();

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const body = formatOrganizationCreateRequestData(formData);

        try {
            const response = await api.post(`${url}`, body);
            handleToastNotification(response);
            navigate("/organizations")
        } catch (error) {
            handleToastNotification(error.response);
        }
        console.log(formData);
    };

    return (
        <React.Fragment>
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <Typography variant="h6" gutterBottom>
                        Create Organization
                    </Typography>
                    <ToastContainer />
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} >
                                <TextField
                                    required
                                    id="name"
                                    name="name"
                                    label="Name"
                                    fullWidth
                                    variant="standard"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="mainContactEmail"
                                    name="main_contact_email"
                                    label="Main Contact Email"
                                    fullWidth
                                    autoComplete="email"
                                    variant="standard"
                                    value={formData.main_contact_email}
                                    title="This will be the email we reach out to regarding your organization's use of Beholdr."
                                    onChange={handleChange}
                                    type="email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" color="primary">
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
        </React.Fragment >


    );
}
