import React, { useState, useEffect, useCallback } from 'react';
import { Box } from '@mui/material';
import debounce from 'lodash.debounce';
import api from '../../services/ApiService.tsx';
import { FilterInput, PaginationComponent, SuggestionsPagination } from '../common/CommonComponents.tsx';
import { url } from '../monitor/common.tsx';


interface Monitor {
    id: string;
    uuid: string;
    type: number;
    name: string;
    metric_name: string;
    window: number;
    threshold: number;
    subscribers: string;
    notification_content: string;
    date_created: string;
    is_deleted: boolean;
    created_by: number;
    service: number;
}

interface MonitorSearchInputProps {
    handleFilterChange: (name: string, value: string) => void;
    initialInput?: string;

}

const MonitorSearchInput: React.FC<MonitorSearchInputProps> = ({ handleFilterChange, initialInput = '' }) => {
    const [filter, setFilter] = useState(initialInput);
    const [suggestions, setSuggestions] = useState<Monitor[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const pageSize = 10;
    const [page, setPage] = useState(1); // Current page state
    const [totalPages, setTotalPages] = useState(1);

    const filterName = 'monitorName';

    const fetchSuggestions = useCallback(debounce(async (filter: string, page: number) => {
        setIsLoading(true);
        try {
            const params = { monitor_name: filter, page, page_size: pageSize };
            const response = await api.get(url, { params });
            
            setSuggestions(response.data.data);
            setTotalPages(Math.ceil(response.data.count / pageSize)); // Set the total number of pages
        } catch (error) {
            console.error('Error fetching suggestions:', error);
            setSuggestions([]);
        } finally {
            setIsLoading(false);
        }
    }, 300), []);

    useEffect(() => {
        fetchSuggestions(initialInput, 1);
        setFilter(initialInput);
    }, [initialInput]);

    useEffect(() => {
        fetchSuggestions(filter, 1);
    }, [filter]);

    const handleQueryChange = (_event: React.ChangeEvent<{}>, value: string) => {
        handleFilterChange(filterName, value ? value : '');
        setFilter(value);
        setPage(1); // Reset to the first page when the filter changes
    };

    const handleSuggestionSelected = (_event: React.ChangeEvent<{}>, value: Monitor | null) => {
        handleFilterChange(filterName, value ? value.name : '');
        setFilter(value ? value.name : '');
    };

    const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        fetchSuggestions(filter, value);
    };

    return (
        <Box>
            <FilterInput
                label="Monitors"
                filter={filter}
                isLoading={isLoading}
                onChange={handleQueryChange}
            />

            <SuggestionsPagination
                suggestions={suggestions}
                handleSuggestionSelected={handleSuggestionSelected}
            />

            <PaginationComponent
                currentPage={page}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
        </Box>
    );
};

export default MonitorSearchInput;