
import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';

const PasswordResetSuccess = () => {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h3" component="h1" gutterBottom>
          Password Reset
        </Typography>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography paragraph>
            Your password was reset successfully.
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

export default PasswordResetSuccess;

