import React from "react";
import OrganizationUsage from "./OrganizationUsageTable.tsx";
import OrganizationsTable from "./OrganizationsTable.tsx";
import { Container, Grid } from "@mui/material";
import { fetchOrganizations, fetchOrganizationUsage, fetchUsagePlans } from "./common.tsx";
import { handleToastNotification } from "../common/Utils.tsx";
import { styles } from "../../style.tsx";


export default function OrganizationView() {
    return (

        <React.Fragment>
                <Grid container spacing={5} sx={styles.contentContainer}>
                    <Grid item xs={12} md={12}>
                        <OrganizationsTable/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <OrganizationUsage/>
                    </Grid>
                </Grid>
        </React.Fragment>
    );
}