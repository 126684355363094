import React, { useState } from 'react';

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Box, CssBaseline, List, ListItem, ThemeProvider } from '@mui/material';
import { mainTheme } from '../../theme.tsx';
import { styles } from '../../style.tsx';
import { BulletListItem, Section } from '../common/CommonComponents.tsx';


const definitionsListContent = [
    {
        'strongText': 'Account:',
        'text': 'A unique account created for You to access our Service or parts of our Service.',
    },
    {
        'strongText': 'Application:',
        'text': 'Beholdr.io, the software program provided by the Company.',
    },
    {
        'strongText': 'Company:',
        'text': 'Refers to Beholdr.io (also referred to as "the Company", "We", "Us", or "Our" in this Agreement).',
    },
    {
        'strongText': 'Device:',
        'text': 'Any device that can access the Service, such as a computer, cellphone, or digital tablet.',
    },
    {
        'strongText': 'Personal Data:',
        'text': 'Information that relates to an identified or identifiable individual.',
    },
    {
        'strongText': 'Service:',
        'text': 'Refers to the Application, Beholdr.io.',
    },
    {
        'strongText': 'Service Provider:',
        'text': 'Any natural or legal person who processes data on behalf of the Company, including third-party companies or individuals hired to facilitate or provide the Service, perform services related to the Service, or assist in analyzing the use of the Service.',
    },
    {
        'strongText': 'Usage Data:',
        'text': 'Data collected automatically, either generated by the use of the Service or from the Service infrastructure itself.',
    },
    {
        'strongText': 'You:',
        'text': 'The individual accessing or using the Service, or the company or other legal entity on behalf of which such individual is accessing or using the Service.',
    },
];


const privacyListContent1 = [
    {
        'strongText': 'To provide and maintain our Service,',
        'text': 'including monitoring its usage.',
    },
    {
        'strongText': 'To manage Your Account:',
        'text': 'Handling Your registration as a user of the Service. The Personal Data You provide allows You to access various functionalities available to registered users.',
    },
    {
        'strongText': 'For the performance of a contract:',
        'text': 'Developing, complying with, and executing the purchase contract for products, items, or services You have purchased, or any other contract with Us through the Service.',
    },
    {
        'strongText': 'To contact You:',
        'text': 'Communicating via email, telephone calls, SMS, or other electronic communications, such as push notifications from a mobile application, regarding updates or information about the functionalities, products, or contracted services, including necessary or reasonable security updates.',
    },
    {
        'strongText': 'To provide You with news,',
        'text': 'special offers, and general information about other goods, services, and events similar to those you have already purchased or inquired about, unless You opt out of receiving such information.',
    },
    {
        'strongText': 'To manage Your requests:',
        'text': 'Responding to and handling Your requests to Us.',
    },
    {
        'strongText': 'For business transfers:',
        'text': 'Evaluating or conducting a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, where Personal Data held by Us about our Service users is among the assets transferred.',
    },
    {
        'strongText': 'For other purposes:',
        'text': 'Using Your information for data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns, and evaluating and improving our Service, products, services, marketing, and Your experience.',
    },
];


const privacyListContent2 = [
    {
        'strongText': 'With Service Providers:',
        'text': 'Sharing Your personal information with Service Providers to monitor, analyze, and improve the use of our Service, or to contact You.',
    },
    {
        'strongText': 'For business transfers:',
        'text': 'Sharing or transferring Your personal information during negotiations or in connection with any merger, sale of Company assets, financing, or acquisition of all or part of Our business by another company.',
    },
    {
        'strongText': 'With Your consent:',
        'text': 'Disclosing Your personal information for any other purpose with Your explicit consent.',
    },
];


const content = [
    {
        'header': 'Privacy Policy',
        'content': (
            <>
                <Typography paragraph>Last updated: June 6, 2024</Typography>
                <Typography paragraph>This Privacy Policy outlines Our policies and procedures regarding the collection, use, and disclosure of Your information when You use our Service. It also explains Your privacy rights and how the law protects You. We use Your Personal Data to provide and enhance the Service. By using the Service, You consent to the collection and use of information as described in this Privacy Policy.</Typography>
            </>
            
        )
    },
    {
        'header': 'Interpretation',
        'content': <Typography paragraph>  Words with initial capital letters have specific meanings defined in this Privacy Policy. These definitions apply regardless of whether the terms appear in singular or plural forms.</Typography>
    },
    {
        'header': 'Definitions',
        'content': (
            <>
                <Typography>For the purposes of this Privacy Policy:</Typography>
                <List>
                    {definitionsListContent.map((item, index) => (
                        <BulletListItem strongText={item.strongText} text={item.text} />
                    ))}
                </List>
            </>
        )

    },
    {
        'header': 'Collecting and Using Your Personal Data',
        'content': (
            <>
                <Typography variant='h2' sx={{ mb: 1 }}>Types of Data Collected</Typography>
                <Typography variant='h3'>Personal Data</Typography>
                <Typography>When using Our Service, We may ask You to provide certain personally identifiable information. This information can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</Typography>
                <List sx={styles.bulletedList}>
                    <ListItem>Email address</ListItem>
                    <ListItem>First name and last name</ListItem>
                    <ListItem>Usage data</ListItem>
                </List>

            </>
        )
    },
    {
        'header': 'Usage Data',
        'content': <Typography paragraph>
            Usage Data is collected automatically when You use the Service.<br /><br />
            This data may include information such as Your Device's IP address, browser type and version, the pages You visit on our Service, the time and date of Your visit, the duration of Your visit, unique device identifiers, and other diagnostic data.<br /><br />
            When accessing the Service through a mobile device, We may automatically collect information such as the type of mobile device, its unique ID, the IP address, operating system, the type of mobile Internet browser, unique device identifiers, and other diagnostic data.<br /><br />
            Additionally, We may gather information that Your browser sends whenever You visit our Service or access it through a mobile device.
        </Typography>
    },
    {
        'header': 'Use of Your Personal Data',
        'content': (
            <>
                <Typography paragraph>The Company may use Personal Data for the following purposes:</Typography>
                <List>
                    {privacyListContent1.map((item, index) => (
                        <BulletListItem strongText={item.strongText} text={item.text} />
                    ))}
                </List>
                <Typography paragraph>We may share Your personal information in the following situations:</Typography>
                <List>
                    {privacyListContent2.map((item, index) => (
                        <BulletListItem strongText={item.strongText} text={item.text} />
                    ))}
                </List>
            </>
        )
    },
    {
        'header': 'Retention of Your Personal Data',
        'content': (
            <>
                <Typography paragraph>The Company will retain Your Personal Data only for as long as necessary to fulfill the purposes outlined in this Privacy Policy. We retain and use Your Personal Data as required to comply with legal obligations, resolve disputes, and enforce our agreements and policies.</Typography>
                <br />
                <Typography paragraph>Usage Data is retained for shorter periods unless it is used to enhance the security or improve the functionality of Our Service, or we are legally required to retain it for longer periods.</Typography>
            </>
        )
    },
    {
        'header': 'Transfer of Your Personal Data',
        'content': (
            <>
                <Typography paragraph>Your information, including Personal Data, may be processed at the Company's operating offices and other locations where involved parties operate. This means Your data could be transferred to — and stored on — computers outside Your state, province, country, or governmental jurisdiction where data protection laws may differ from those in Your jurisdiction.</Typography>
                <Typography paragraph>By consenting to this Privacy Policy and submitting Your information, You agree to this transfer.</Typography>
                <Typography paragraph>The Company will take all reasonable steps to ensure that Your data is handled securely and in accordance with this Privacy Policy. Transfers of Your Personal Data to organizations or countries will only occur if there are adequate data protection measures in place.</Typography>
            </>
        )
    },
    {
        'header': 'Delete Your Personal Data',
        'content': (
            <>
                <Typography variant='h2'>Business Transactions</Typography>
                <Typography paragraph>
                    In the event the Company is involved in a merger, acquisition, or asset sale, Your Personal Data may be transferred. We will notify You before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
                </Typography>
                <Typography variant='h2'>Law Enforcement</Typography>
                <Typography paragraph>
                    The Company may be required to disclose Your Personal Data if mandated by law or in response to valid requests by public authorities, such as a court or government agency.
                </Typography>
                <Typography variant='h2'>Other Legal Requirements</Typography>
                <Typography paragraph>
                    The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
                </Typography>
                <List sx={styles.bulletedList}>
                    <ListItem>Comply with a legal obligation</ListItem>
                    <ListItem>Protect and defend the Company's rights or property</ListItem>
                    <ListItem>Prevent or investigate possible wrongdoing in connection with the Service</ListItem>
                    <ListItem>Protect the personal safety of Users of the Service or the public</ListItem>
                    <ListItem>Protect against legal liability</ListItem>
                </List>
            </>
        )
    },
    {
        'header': 'Security of Your Personal Data',
        'content': (
            <>
                <Typography paragraph>  We prioritize the security of Your Personal Data. However, please be aware that no method of transmission over the Internet or electronic storage is completely secure. While We implement commercially acceptable measures to protect Your Personal Data, We cannot guarantee its absolute security.</Typography>
            </>
        )
    },
    {
        'header': 'Children\'s Privacy',
        'content': (
            <>
                <Typography paragraph>
                    Our Service is not intended for use by anyone under the age of 13. We do not knowingly collect Personal Data from children under 13. If You are a parent or guardian and believe Your child has provided Us with Personal Data, please contact Us. If We discover that We have collected Personal Data from children under 13 without parental consent, We will take steps to delete such information from Our servers.
                </Typography>
                <Typography paragraph>
                    If We need to rely on consent as a legal basis for processing Your information and Your country requires parental consent, We may require Your parent's consent before We collect and use that information.
                </Typography>
            </>
        )
    },
    {
        'header': 'Changes to this Privacy Policy',
        'content': (
            <>
                <Typography paragraph>
                    We may update Our Privacy Policy periodically. We will notify You of any changes by posting the updated Privacy Policy on this page.
                </Typography>
                <Typography paragraph>
                    Additionally, We will inform You via email and/or a prominent notice on Our Service before the changes take effect. The "Last updated" date at the top of this Privacy Policy will be revised accordingly.
                </Typography>
                <Typography paragraph>
                    We encourage You to review this Privacy Policy regularly to stay informed about any changes. Updates to this Privacy Policy are effective when they are posted on this page.
                </Typography>
            </>
        )
    },
    {
        'header': 'Contact Us',
        'content': (
            <>
                <Typography paragraph>If you have any questions about this Privacy Policy, You can contact us:</Typography>
                <List sx={styles.bulletedList}>
                    <ListItem>By email: devin@beholdr.io</ListItem>
                </List>
            </>
        )
    },

]

export default function PrivacyPolicy() {

    return (
        <ThemeProvider theme={mainTheme}>
            <Container disableGutters maxWidth={false} sx={styles.contentContainer}>
                {content.map((item, index) => (
                    <Grid item key={index} sx={{ mb: 3 }}>
                        <Section title={item.header} content={item.content} headerStyle={{mb: 2, typography: 'bold'}}/>
                    </Grid>
                ))}
                <Grid container direction="column" spacing={2}>

                </Grid>
            </Container>
        </ThemeProvider>
    );
}

