
import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';

const RegistrationSuccess = () => {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h3" component="h1" gutterBottom>
          Welcome!
        </Typography>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography paragraph>
            Your account was succesfully activated. Please, navigate to the login page to access Beholdr.
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

export default RegistrationSuccess;

