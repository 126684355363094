import React, { useEffect } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { ParagraphText, Section, SubTitle, Title, defaultPadding } from '../template.tsx';
import { QuoteBlock, setSeoMetaTags } from '../../common/CommonComponents.tsx';





const content = [
    {
        'header': 'Suddenly Your Emails Stop Sending',
        'content': (
            <>
                <ParagraphText
                    text={
                        "I've been using Google to manage my domain and email services for the past year without any issues. However, Google recently transitioned their domain hosting to Squarespace, which now handles some DNS-related configurations. Since this migration, I began receiving 'Message Blocked' notifications when attempting to send emails, accompanied by the following error message:"
                    }
                />
                <QuoteBlock
                    quote={
                        "550 x.x.x Your email has been blocked because the sender is unauthenticated. Gmail requires all senders to authenticate with either SPF or DKIM. Authentication results: DKIM = did not pass SPF [domain] with ip: [x.x.x.x] = did not pass For instructions on setting up authentication, go to https://support.google.com/mail/answer/81126#authentication xxxxx - gsmtp"
                    }
                    source={null}
                />
                <ParagraphText
                    text={
                        "While Google's linked resources provide an explanation of the issue, they don't offer a straightforward, step-by-step guide on what you need to do to resolve it and get your email functioning again."
                    }
                />
            </>
        )
    },
    {
        'header': 'What Is Actually Happening?',
        'content': (
            <>
                <Grid container>
                    <Grid item md={12}>
                        <ParagraphText
                            text={
                                "You are receiving these messages because your domain does not have a properly configured DKIM key to authenticate your emails. In my case, after Google's migration to Squarespace, the DKIM setup was incorrect, causing my email authentication to fail. The solution, in simple terms, is to add the Google-provided DKIM key to your current DNS provider."
                            }
                        />
                        <ParagraphText
                            text={
                                "What is DKIM? DKIM (DomainKeys Identified Mail) is an email authentication method that allows the receiving server to verify that an email was sent from an authorized domain. It does this by adding a digital signature to your emails, which helps protect against spoofing and improves email deliverability."
                            }
                        />
                    </Grid>
                </Grid>

            </>
        )
    },
    {
        'header': 'Resolving The Issue',
        'content': (
            <>
                <Grid container>
                    <Grid item md={12}>
                        <ParagraphText
                            text={
                                "As we discussed earlier, this issue is caused by a misconfiguration between your DKIM key and your DNS provider settings. Simply put, you need to retrieve your DKIM key from your domain provider—in this case, Google—and add it to your DNS provider’s records. Below is an example of a DKIM key:"
                            }
                        />
                        <QuoteBlock
                            quote={
                                <>
                                    <Typography>{"TXT Record Name: google._domainkey"}</Typography>
                                    <br></br>
                                    <Typography>{"TXT Record Value:"}</Typography>
                                    <Typography>{"v=DKIM1; k=rsa; p=xxxxxxxxxxxxxxx..."}</Typography>
                                </>
                            }
                            source={null}
                        />
                        <ParagraphText
                            text={
                                "If you're using Google to manage your domain, you can find this information in the Google Admin Console. To access it, navigate through the following menu path: Apps > Google Workspace > Gmail."
                            }
                        />
                        <ParagraphText
                            text={
                                "In this menu, you can choose the option \"I'd rather configure the domain myself.\" to display your current DKIM key. If the key is too long for your DNS provider, you have the option to generate a new DKIM key and select the 1024-bit option. This DKIM key can now be added to your DNS provider of choice."
                            }
                        />
                    </Grid>
                </Grid>
            </>
        )
    },
    {
        'header': 'Walkthrough For Route53 DNS',
        'content': (
            <>
                <Grid container>
                    <Grid item md={12}>
                        <ParagraphText
                            text={
                                "Now that you have your DKIM key, you can navigate to your domain's Hosted Zone in Route53 in AWS. Here you will click on the \"Create record\" button. Now, you will fill in your key information, the following sceenshot will show an example of the values you should input:"
                            }
                        />
                        <Box
                            component="img"
                            src={`/assets/images/blogs/FixingGmailAuthenticationIssues/Route53-DKIM-Example.webp`}
                            alt="Route 53 Screenshot"
                            sx={{
                                width: '100%',
                                height: 'auto',
                                maxHeight: '600px',
                                objectFit: 'contain',
                            }}
                        ></Box>
                        <ParagraphText
                            text={
                                "At this point, your DKIM authentication issues should be resolved. Keep in mind that DNS changes can take up to 72 hours to propagate, though they typically take just a few hours. To verify that Google is now properly authenticating your emails, return to the same location where you obtained your DKIM key. In this window, you should see \"Status: Authenticating email with DKIM.\" indicating that Google has validated your DKIM key in your DNS provider. After waiting for the changes to take effect, send a test email to confirm that the authentication is working correctly."
                            }
                        />
                    </Grid>
                </Grid>
            </>
        )
    },
    {
        'header': 'How Beholdr.io Can Help',
        'content': (
            <>
                <Grid container>
                    {/* Intro */}
                    <Grid item md={12}>
                        <ParagraphText
                            text={
                                "It may have taken you hours or even days to realize that your emails weren't being sent, or worse, you might have discovered it just as you needed to respond to an important email. Outages like this can negatively impact your customers and hurt your bottom line. Beholdr.io can seamlessly integrate with your services to track error metrics in real time. In this case, if you had a service responsible for sending emails (such as user sign-ups), it was likely logging silent errors due to the DKIM issue. With Beholdr.io, you could have monitored these errors and received immediate notifications via your preferred channels as soon as your user service began encountering issues."
                            }
                        />
                    </Grid>

                    <Box
                        component="img"
                        src={`/assets/images/Monitor-Table-Mock-Animation.webp`}
                        alt="Beholdr Monitor Mock Video"
                        sx={{
                            width: '100%',
                            height: 'auto',
                            maxHeight: '600px',
                            objectFit: 'contain',
                        }}
                    ></Box>
                </Grid>
            </>
        )
    },
];

const FixingGmailAuthenticationIssues: React.FC = () => {

    useEffect(() => {
        const title = "Beholdr.io Blog - How to Resolve 'Message Blocked' Errors for Google Domains: A Step-by-Step Guide";
        const description = "Learn how to fix 'Message Blocked' errors on Google-managed domains by properly configuring your DKIM key. Follow this step-by-step guide to resolve email authentication issues and ensure your emails are delivered.";
        const keywords = 'Google domain DKIM setup, DKIM authentication issues, Fix message blocked error, Email blocked Google domain, DNS provider DKIM configuration, Google email authentication error, Resolve DKIM setup issues, Prevent email errors with DKIM, Improve email deliverability Google, Message blocked by Gmail fix, Troubleshoot email authentication errors';
        const ogUrl = 'https://beholdr.io/blogs/FixingGmailAuthenticationIssues';
        const ogImage = 'https://beholdr.io/assets/images/blogs/FixingGmailAuthenticationIssues/Gmail-Blocked-Email.png';

        const cleanup = setSeoMetaTags(title, description, keywords, ogUrl, ogImage);

        return cleanup;
        
    }, []);

    return (
        <Grid container p={defaultPadding} pt={5} justifyContent={'center'}>

            <Title title={"Resolving 'Message Blocked' Errors for Google-Managed Domains"} author={"Devin Ogle"}></Title>
            <SubTitle
                text={
                    "This guide provides a quick walkthrough on how to fix your email authentication issues, ensuring your emails are no longer blocked."
                }>
            </SubTitle>
            <Box
                component="img"
                src={`/assets/images/blogs/FixingGmailAuthenticationIssues/Gmail-Blocked-Email.png`}
                alt="Beholdr Monitor Mock Video"
                sx={{
                    width: '100%',
                    maxWidth: '800px',
                    height: 'auto',
                    objectFit: 'contain',
                    mb: 5,
                    p: 4,                    
                }}
            ></Box>

            <Grid container justifyContent={'center'}>
                {content.map((item, index) => (
                    <Grid item key={index} sx={{ mb: 5 }}>
                        <Section title={item.header} content={item.content} />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}

export default FixingGmailAuthenticationIssues;

