import React, { useState } from 'react';
import { handleToastNotification } from '../common/Utils.tsx';
import { useNavigate } from 'react-router-dom';
import { fetchServices } from '../services/common.tsx';
import { toast } from 'react-toastify';

import api from '../../services/ApiService.tsx';
import { validateEmails, MonitorForm } from './common.tsx';

const url = 'monitors/';



interface MonitorCreateData {
    name: String;
    subscribers: String;
    metric_name: String;
    window?: String;
    threshold?: String;
    notification_content: String;
    type: String;
    service: String;
}

function formatMonitorCreateRequestData(
    data: {}
): MonitorCreateData {

    const monitorCreateData: MonitorCreateData = {
        name: '',
        subscribers: '',
        metric_name: '',
        window: '',
        threshold: '',
        notification_content: '',
        type: '',
        service: '',
    }

    const { name, metric_name, subscribers, window, threshold, notification_content, monitor_type, service } = data;

    if (window !== undefined) {
        monitorCreateData.window = window;
    }
    if (threshold !== undefined) {
        monitorCreateData.threshold = threshold;
    }
    
    monitorCreateData.metric_name = metric_name;
    monitorCreateData.name = name;
    monitorCreateData.subscribers = subscribers;
    monitorCreateData.notification_content = notification_content;
    monitorCreateData.type = monitor_type;
    monitorCreateData.service = service;

    return monitorCreateData;
}


// {"email" : "test@test.com"}

export default function NewMonitorForm() {
    const [formData, setFormData] = useState({
        name: '',
        subscribers: '',
        window: null,
        threshold: null,
        notification_content: '',
        metric_name: '',
        monitor_type: '',
        service: ''
    });
    const [services, setServices] = useState([]);
    
    const navigate = useNavigate();

    const handleClassifierChange = (value: string) => {
        setFormData({ ...formData, metric_name: value });
    };

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Commented out until other notification avenues are supported.
        // if (!validJson(formData.subscribers)) {
        //     toast.error("JSON is not in the proper format.")
        //     return;
        // }
        if (!validateEmails(formData.subscribers)) {
            toast.error("Emails are not formatted correctly.")
            return;
        }
        const body = formatMonitorCreateRequestData(formData);
        try {
            const response = await api.post(`${url}`, body);
            handleToastNotification(response);
            navigate("/monitors")
        } catch (error) {
            handleToastNotification(error.response);
        }
        console.log(formData);
    };

    React.useEffect(() => {
        fetchServices(setServices);
    }, []);


    return (
        <MonitorForm
            formData={formData}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleClassifierChange={handleClassifierChange}
            services={services}
            formTitle="New Monitor"
        />
    );
}
