import React, { useEffect } from 'react';

import { Box, Grid } from '@mui/material';

import MonitoringIcon from '../../../static/images/undraw_read_notes_pa7e.png';
import UserApiMonitor from '../../../static/images/UserApiMonitor.png';
import DataIngestionMonitor from '../../../static/images/DataIngestionMonitor.png';

import { ParagraphText, Section, SubTitle, Title, defaultPadding } from '../template.tsx';
import CodeBlockDisplay from '../../common/CodeBlockDisplay.tsx';

const apiCodeExample = `
from beholdr_io_metric_client.client import BeholdrClient

# Create an instance of the BeholdrClient object with your service's API Key from Beholdr.io
beholdr_client = BeholdrClient(
    service_api_key='xxxxxxxxxxx'
)
# Emit a metric from your service to Beholdr.io. 
beholdr_client.emit_metric(
    name="MyService.do_something_success",
    message="An important message about doing something."
)
`;

const userApiCodeExample = `
from flask import Flask, request, jsonify
from beholdr_io_metric_client.client import BeholdrClient

beholdr_client = BeholdrClient(service_api_key='xxxxxxxxxxx')

@app.route('/signup', methods=['POST'])
def signup():
    data = request.json
    username = data.get('username')
    email = data.get('email')

    # Simulate user signup logic
    if username and email:
        # Assuming user creation is successful
        beholdr_client.emit_metric(
            name="user_api.user_signup_successful",
            message=f"User {username} signed up successfully"
        )
        return jsonify({"message": "Signup successful"}), 201
    else:
        beholdr_client.emit_metric(
            name="user_api.user_signup_failure",
            message="Signup failed due to missing data"
        )
        return jsonify({"error": "Signup failed"}), 400
`;

const dataIngestionCodeExample = `
import requests
from beholdr_io_metric_client.client import BeholdrClient

beholdr_client = BeholdrClient(service_api_key='xxxxxxxxxxx')

def ingest_data(data_source_url):
    try:
        # Attempt to fetch data from the source
        response = requests.get(data_source_url)
        response.raise_for_status()  # Raise an error for bad status codes
        
        # Process the data (placeholder for actual processing logic)
        data = response.json()
        # Process data...

        # Emit success metric
        beholdr_client.emit_metric("DataIngestion.Success", "Data ingestion succeeded")
        return data

    except requests.exceptions.RequestException as e:
        # Handle HTTP errors or connection issues
        beholdr_client.emit_metric("DataIngestion.request_error", f"Data ingestion failed: {str(e)}")
        return None

    except ValueError as e:
        # Handle JSON decoding errors or other processing issues
        beholdr_client.emit_metric("DataIngestion.value_error", f"Data ingestion failed: Invalid data format - {str(e)}")
        return None
`;

const codeExamples = [
    {
        label: 'Python',
        language: 'python',
        content: apiCodeExample
    },

];

const content = [
    {
        'header': 'Introduction to Integrating With Beholdr.io',
        'content': (
            <>
                <ParagraphText
                    text={
                        "This article will guide you through integrating your services with Beholdr.io's monitoring and alerting solution. Whether you've completed the onboarding process or are simply curious about how Beholdr.io can enhance your service management, this guide will provide valuable insights to help you make the most of the platform."
                    }
                />
            </>
        )
    },
    {
        'header': 'Why Is Service Monitoring Important?',
        'content': (
            <>
                <Grid container>
                    <Grid item md={12}>
                        <ParagraphText
                            text={
                                "Service monitoring is crucial for maintaining the health and performance of your infrastructure. It allows you to detect issues before they impact users, ensuring that your services remain reliable and available. By continuously tracking system metrics and receiving alerts when anomalies occur, you can proactively address potential problems, minimizing downtime and improving overall service quality. Effective monitoring also provides valuable insights into performance trends, helping you make informed decisions to optimize your services over time."
                            }
                        />
                    </Grid>
                </Grid>

            </>
        )
    },
    {
        'header': 'Using The SDK and Beholdr.io API Keys',
        'content': (
            <>
                <Grid container>
                    <Grid item md={12}>
                        <ParagraphText
                            text={
                                "Beholdr.io provides a user-friendly Python SDK designed to simplify the integration of API keys and the creation of metrics. This makes it easy for developers to quickly set up monitoring for their services, allowing them to focus on building and maintaining reliable systems without the hassle of complex configurations. The SDK streamlines the process, enabling efficient use of Beholdr.io's monitoring and alerting capabilities."
                            }
                        />
                    </Grid>
                    <Grid container md={12} spacing={2} >
                        <Grid item md={7} xs={12} sx={{ width: '100%' }}>
                            <CodeBlockDisplay codeBlocks={[
                                {
                                    label: 'Python',
                                    language: 'python',
                                    content: apiCodeExample
                                },

                            ]} />
                        </Grid>
                        <Grid item md={5} xs={12}>
                            <ParagraphText
                                text={
                                    "Here's an example of how to instantiate a Beholdr.io Client object and use it to send metrics to Beholdr.io. After sending, you should see each unique metric name appear on the Beholdr.io metric dashboard within a few minutes. This allows you to quickly verify that your metrics are being tracked and displayed as expected, ensuring that your service monitoring is up and running effectively."
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    },
    {
        'header': 'Use Case: Alerting For User Sign-Up',
        'content': (
            <>
                <Grid container>
                    {/* Intro */}
                    <Grid item md={12}>
                        <ParagraphText
                            text={"Beholdr.io enables you to track and set up notifications for any workflow or action within your system. For example, you can configure Beholdr.io to notify you via email or MS Teams whenever a new user signs up for your service. This immediate notification allows you to promptly engage with new users and initiate onboarding procedures, ensuring a smooth and responsive user experience."}
                        />
                    </Grid>
                    {/* Code Example */}
                    <Grid container spacing={2} mb={3}>
                        <Grid item md={7} xs={12} sx={{ width: '100%' }}>
                            <CodeBlockDisplay codeBlocks={[
                                {
                                    label: 'Python',
                                    language: 'python',
                                    content: userApiCodeExample
                                },

                            ]} />
                        </Grid>
                        <Grid item md={5} xs={12}>
                            <ParagraphText
                                text={
                                    "In this Flask User API example, Beholdr.io is utilized to generate success and failure metrics based on the outcome of the `/signup` request. When a user signs up successfully, a success metric is emitted; if the signup fails, a failure metric is generated. These metrics are then tracked on Beholdr.io's metric dashboard, allowing you to monitor the performance and health of your user signup process in real-time. The image below shows an example Monitor that will notify you "
                                }
                            />
                        </Grid>
                    </Grid>
                    {/* Example Monitor */}
                    <Grid container spacing={2} >
                        <Grid item md={5} xs={12}>
                            <ParagraphText
                                text={
                                    "Now that you're emitting metrics from your service to Beholdr.io, you can set up a monitor to notify you immediately each time the `/signup` endpoint executes successfully, or when errors occur. The image below illustrates an example Monitor that sends notifications to `myEmail@email.com` whenever a `user_api.user_signup_successful` metric is generated by your User API Service, keeping you informed in real-time about your service's performance and any issues that arise"
                                }
                            />
                        </Grid>
                        <Grid item md={7} xs={12}>
                            <Grid container justifyContent={'center'}>
                                <Box
                                    component="img"
                                    alt="User Api Monitor Example"
                                    src={UserApiMonitor}
                                    sx={{
                                        width: '100%',
                                        maxWidth: '500px',
                                        height: 'auto',
                                    }}
                                ></Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    },
    {
        'header': 'Use Case: Error Alerting With A Tolerance',
        'content': (
            <>
                <Grid container>
                    {/* Intro */}
                    <Grid item md={12}>
                        <ParagraphText
                            text={
                                "Beholdr.io allows you to track and set tolerances for service errors, enabling you to receive notifications only when necessary. By defining these tolerances, you can avoid being overwhelmed by alerts for every minor error. This is particularly useful for workflows like data ingestion, where errors may occur frequently. Instead of being notified for each error, you can configure monitors to alert you only when errors exceed a specific threshold, ensuring you focus on issues that truly require attention."
                            }
                        />
                    </Grid>
                    {/* Code Example */}
                    <Grid container spacing={2} mb={3}>
                        <Grid item md={7} xs={12} sx={{ width: '100%' }}>
                            <CodeBlockDisplay codeBlocks={[
                                {
                                    label: 'Python',
                                    language: 'python',
                                    content: dataIngestionCodeExample
                                },

                            ]} />
                        </Grid>
                        <Grid item md={5} xs={12}>
                            <ParagraphText
                                text={
                                    "In this example, you can use different metric names to track specific errors in your service's operation. Given that some failures are expected, you can avoid being notified for each individual error. Instead, by leveraging Beholdr.io's monitoring capabilities, you can set up alerts to notify you only when the number of errors exceeds a defined threshold. This approach ensures that you're only alerted when the error rate deviates from the expected norm, indicating a potential issue that requires attention."
                                }
                            />
                        </Grid>
                    </Grid>
                    {/* Example Monitor */}
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={5}>
                            <ParagraphText
                                text={
                                    "This monitor is configured to track the specific `DataIngestion.value_error` metric and notify us if more than 15 errors occur within a 6-minute window. When this threshold is breached, a notification will be sent to `MyEmail@email.com`, containing the message set in the `Notification Content` field. This setup ensures that you are only alerted when the error rate exceeds the expected norm, helping you stay informed of potential issues without being overwhelmed by frequent alerts."
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Grid container justifyContent={'center'}>
                                <Box
                                    component="img"
                                    alt="Data Ingestion Monitor Example"
                                    src={DataIngestionMonitor}
                                    sx={{
                                        width: '100%',
                                        maxWidth: '500px',
                                        height: 'auto',
                                    }}
                                ></Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    },
    {
        'header': 'Flexibility in Monitoring and Alerting with Beholdr.io',
        'content': (
            <>
                <Grid container>
                    <Grid item md={12}>
                        <ParagraphText
                            text={
                                "Beholdr.io offers a lightweight and flexible solution that keeps you informed about your services' behavior and workflows on your terms. You can configure notifications to alert you about specific events, an excess of errors, or any other activity you want to monitor. This allows you to maintain control over the flow of information, ensuring you're notified only when it matters most, and helping you manage your services efficiently without unnecessary interruptions."
                            }
                        />
                    </Grid>
                </Grid>

            </>
        )
    },
];

const UsingBeholdrIoToMonitorYourService: React.FC = () => {

    useEffect(() => {
        // Set the document title
        document.title = "Beholdr.io Blog - Monitoring & Alerting Insights";

        // Meta description
        const metaDescription = document.createElement('meta');
        metaDescription.name = 'description';
        metaDescription.content = 'Guides, announcements, and articles about Beholdr.io, monitoring, and alerting.';
        document.head.appendChild(metaDescription);

        // Meta keywords
        const metaKeywords = document.createElement('meta');
        metaKeywords.name = 'keywords';
        metaKeywords.content = 'Beholdr.io, monitoring, alerting, DevOps, service management';
        document.head.appendChild(metaKeywords);

        return () => {
            // Clean up the tags if necessary
            document.head.removeChild(metaDescription);
            document.head.removeChild(metaKeywords);
        };
    }, []);

    return (
        <Grid container p={defaultPadding} pt={5} justifyContent={'center'}>

            <Title title={"Using Beholdr.io To Monitor Your Service"} author={"Devin Ogle"}></Title>
            <SubTitle
                text={"Monitoring is essential to ensure your applications' reliable operation and a vital practice of the DevOps movement. Learn more about the role of monitoring in the development workflow and the best practices for monitoring your applications."}>
            </SubTitle>

            <Box
                component="img"
                alt="Monitoring Icon"
                src={MonitoringIcon}
                sx={{
                    width: '100%',
                    height: 'auto',
                    maxHeight: '800px',
                    mb: 5,
                    objectFit: 'contain',

                }}
            ></Box>

            <Grid container justifyContent={'center'}>
                {content.map((item, index) => (
                    <Grid item key={index} sx={{ mb: 5 }}>
                        <Section title={item.header} content={item.content} />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}

export default UsingBeholdrIoToMonitorYourService;

