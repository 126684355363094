import api from "../../services/ApiService.tsx";

export const url = 'users/';

export interface UserPatchData {
    username?: string;
    email?: string;
    account_type?: number;
    is_deleted?: boolean;
    user_organizations?: number[];
    email_opt_out?: boolean;
}

export function formatUserPatchRequestData(
    changedFields: {}
): UserPatchData {

    const userPatchData = {}
    const { user_organizations, username, email, account_type, email_opt_out} = changedFields;

    if (user_organizations !== undefined) {
        const temp_user_organizations = user_organizations.split(",").map(Number);
        userPatchData.user_organizations = temp_user_organizations.filter((id) => !Number.isNaN(id));
    }
    if (username !== undefined) {
        userPatchData.username = username;
    }

    if (email !== undefined) {
        userPatchData.email = email;
    }

    if (email_opt_out !== undefined) {
        userPatchData.email_opt_out = email_opt_out;
    }


    if (account_type !== undefined) {
        const temp_account_type = Number(account_type);
        if (!isNaN(temp_account_type)) {
            userPatchData.account_type = temp_account_type;
        }
    }

    return userPatchData;
}

export interface GetUsersData {
    id: number;
    username: string;
    email: string;
    account_type: string;
    created_by: string;
    is_deleted: string;
    user_organizations: [];
    updated: string;
}

export function formatGetUsersData(
    id: number,
    username: string,
    email: string,
    account_type: string,
    created_by: string,
    is_deleted: string,
    user_organizations: [],
    updated: string,
    email_opt_out: boolean
): GetUsersData {
    return { id, username, email, account_type, created_by, is_deleted, user_organizations, updated, email_opt_out};
}

/**
 * Fetches users and sets the state.
 * @param {Function} setUsers The state setter function for users.
 */
export const fetchUsers = async (setUsers) => {
    try {
        const response = await api.get(url);
        const formattedRows: [] = response.data.data
            .filter(user => user.is_deleted != true)
            .map(
                user => formatGetUsersData(
                    user.id,
                    user.username,
                    user.email,
                    user.account_type,
                    user.created_by,
                    user.is_deleted,
                    user.user_organizations,
                    user.updated,
                    user.email_opt_out,
                )
            )
        setUsers(formattedRows);
    } catch (error) {
        console.error('Error fetching users', error);
    }
};
