import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { handleDelete, handleToastNotification } from '../common/Utils.tsx';
import axios from 'axios';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../services/ApiService.tsx';
import { Grid, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import TableLayout from '../../layouts/TableLayout.tsx';
import { fetchUsers, formatUserPatchRequestData } from './common.tsx';
import { url } from './common.tsx';
import { styles } from '../../style.tsx';

export default function UsersTable() {
    const [rows, setRows] = React.useState([]);

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 70,
            editable: false,
        },
        {
            field: 'username',
            headerName: 'Username',
            width: 170,
            editable: true,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 170,
            editable: false,
        },
        {
            field: 'account_type',
            headerName: 'Account Type',
            width: 170,
            editable: true,
            type: 'singleSelect',
            valueOptions: [
                { "value": 1, "label": 'User' },
                { "value": 2, "label": 'Admin' },
                { "value": 3, "label": 'Org Admin' },
            ],
        },
        {
            field: 'email_opt_out',
            headerName: 'Email Opt Out',
            width: 170,
            editable: true,
        },
        {
            field: 'created_by',
            headerName: 'Created By',
            width: 170,
            editable: false,
        },
        {
            field: 'is_deleted',
            headerName: 'Deleted',
            width: 170,
            editable: false,
        },
        {
            field: 'user_organizations',
            headerName: 'User Organizations',
            width: 170,
            editable: true,
        },
        {
            field: 'updated',
            headerName: 'Updated',
            width: 170,
            editable: false,
        },
        {
            field: 'delete',
            headerName: 'Delete',
            sortable: false,
            renderCell: (params) => (
                <IconButton onClick={() => handleDelete(params.id, url, setRows)} aria-label="delete">
                    <DeleteIcon />
                </IconButton>
            ),
        },
    ];

    const handleProcessRowUpdate = async (updatedRow, originalRow) => {

        // Determine the changed values
        const changedValues = {};
        Object.keys(updatedRow).forEach((key) => {
            if (updatedRow[key] !== originalRow[key]) {
                changedValues[key] = updatedRow[key];
            }
        });

        // Return original row and prevent request being made when no changes were made.
        if (Object.keys(changedValues).length === 0) {
            return originalRow;
        }


        const body = formatUserPatchRequestData(changedValues);

        // Update updatedRow with parsed inputs
        Object.keys(body).forEach((key) => {
            if (updatedRow[key] !== body[key]) {
                updatedRow[key] = body[key];
            }
        });

        try {
            const response = await api.patch(`${url}${updatedRow.id}/`, body);
            handleToastNotification(response);
            return updatedRow;
        } catch (error) {
            handleToastNotification(error.response);
            return originalRow;
        }

    };

    function onProcessRowUpdateError(error) {
        console.error('Error occurred while updating row:', error);
    }


    // Function to determine row class based on status code. Used to turn error message to a light red.
    const getRowClassName = (params) => {
        // Disabled for now
        if (params.row.status_code >= 400) {
            // return 'error-metric';
        }
        return '';
    };

    React.useEffect(() => {

        // Fetch Users
        const fetchData = async () => {
            try {
                await fetchUsers(setRows);
            } catch (error) {
                handleToastNotification(error.response);
            }
        };
        fetchData();

    }, []);

    return (
        <Grid container spacing={5} sx={styles.contentContainer}>
            <Grid item xs={12} md={12}>
                <Typography variant="h2" gutterBottom>
                    Users
                </Typography>
                <TableLayout buttonPath='/users/create' buttonText='New User'>
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            pageSizeOptions={[5, 10, 25]}
                            checkboxSelection
                            disableRowSelectionOnClick
                            getRowClassName={getRowClassName}
                            processRowUpdate={handleProcessRowUpdate}
                            onProcessRowUpdateError={onProcessRowUpdateError}
                        />
                        <ToastContainer />
                    </Box>
                </TableLayout>
            </Grid>
        </Grid>
    );
}
