import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const ProtectedRoute = ({ children }) => {
    const token = Cookies.get(process.env.REACT_APP_TOKEN_NAME);
    if (!token) {
        // User not authenticated, redirect to login
        return <Navigate to="/login" replace />;
    }

    return children;
};

export default ProtectedRoute;

