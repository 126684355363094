import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Container, Card, CardHeader, CardContent, List, ListItem, ListItemIcon, ListItemText, ThemeProvider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { fetchOrganizationUsage } from "./common.tsx";
import { styles } from "../../style.tsx";


interface OrganizationUsageProps {
    inputOrganizationUsage?: {
        organization_usage_by_service: any[];
        organization_total_usage: any;
        organization: any;
        usage_plan: any;
    };
    handleFetch?: boolean;
}

function calculate_plan_metric_usage(organizationUsage: any) {
    if (!organizationUsage || Object.keys(organizationUsage).length === 0) {
        return "";
    }
    const organizationMetricUsage = organizationUsage.organization_total_usage.total_metric_usage;
    const planMonthlyMetricLimit = organizationUsage.usage_plan.monthly_metric_limit;
    const totalUsagePercentage = (
        organizationMetricUsage
        / planMonthlyMetricLimit
    ) * 100;

    return `${organizationMetricUsage} / ${planMonthlyMetricLimit} (%${totalUsagePercentage.toFixed(2)})`;
}

function calculate_plan_notification_usage(organizationUsage: any) {
    if (!organizationUsage || Object.keys(organizationUsage).length === 0) {
        return "";
    }
    const organizationNotificationUsage = organizationUsage.organization_total_usage.total_notification_usage;
    const planMonthlyNotificationLimit = organizationUsage.usage_plan.monthly_notification_limit;
    const totalUsagePercentage = (
        organizationNotificationUsage
        / planMonthlyNotificationLimit
    ) * 100;

    return `${organizationNotificationUsage} / ${planMonthlyNotificationLimit} (%${totalUsagePercentage.toFixed(2)})`;
}

export default function OrganizationUsage() {
    const [organizationUsage, setOrganizationUsage] = React.useState([]);

    React.useEffect(() => {
        fetchOrganizationUsage(setOrganizationUsage).catch(console.error);
    }, []);

    return (
        <Grid container spacing={5} justifyContent="center" sx={styles.contentContainer}>
            <Grid container item xs={12} md={6} justifyContent="center" >
                <Card sx={{ width: "400px" }}>
                    <CardHeader
                        title="Total Organization Usage"
                        subheader="Current Month Usage"
                        titleTypographyProps={{ align: 'left', color: 'white' }}
                        subheaderTypographyProps={{ align: 'left', color: 'lightgray' }}
                        sx={{
                            backgroundColor: (theme) => theme.palette.primary.main,
                        }}
                    />
                    <Grid container item spacing={2} p={2}>
                        <Grid item sm={12} >
                            <Typography sx={{ typography: "h3" }}>
                                Metrics: {calculate_plan_metric_usage(organizationUsage)}
                            </Typography>
                        </Grid>
                        <Grid item sm={12} >
                            <Typography sx={{ typography: "h3" }}>
                                Notifications: {calculate_plan_notification_usage(organizationUsage)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <CardContent>
                        {organizationUsage?.organization_usage_by_service?.map((service_usage, index) => (
                            <Accordion key={index}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography sx={{ typography: "h2" }}>
                                        {service_usage?.service?.name}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography sx={{ typography: "h3" }}>
                                        Metrics: {service_usage?.metric_usage}
                                    </Typography>
                                    <Typography sx={{ typography: "h3" }}>
                                        Notifications: {service_usage?.notification_usage}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </CardContent>

                </Card>
            </Grid>
            <Grid container item xs={12} md={6} justifyContent="center" >
                <Card sx={{ width: "400px" }}>
                    <CardHeader
                        title="Current Plan"
                        subheader={organizationUsage?.usage_plan?.name}
                        titleTypographyProps={{ align: 'left', color: 'white' }}
                        subheaderTypographyProps={{ align: 'left', color: 'lightgray' }}
                        sx={{
                            backgroundColor: (theme) => theme.palette.primary.main,
                        }}
                    />
                    <CardContent>
                        <List>
                            <ListItem>
                                <ListItemIcon sx={{ minWidth: 'auto', marginRight: 1 }}>
                                    <CheckOutlinedIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText>
                                    Organization Limit: {organizationUsage?.usage_plan?.organization_limit}
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon sx={{ minWidth: 'auto', marginRight: 1 }}>
                                    <CheckOutlinedIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText>
                                    User Limit: {organizationUsage?.usage_plan?.user_limit}
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon sx={{ minWidth: 'auto', marginRight: 1 }}>
                                    <CheckOutlinedIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText>
                                    Metric Limit: {organizationUsage?.usage_plan?.monthly_metric_limit}
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon sx={{ minWidth: 'auto', marginRight: 1 }}>
                                    <CheckOutlinedIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText>
                                    Notification Limit: {organizationUsage?.usage_plan?.monthly_notification_limit}
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon sx={{ minWidth: 'auto', marginRight: 1 }}>
                                    <CheckOutlinedIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText>
                                    Monitor Limit: {organizationUsage?.usage_plan?.monitor_limit}
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon sx={{ minWidth: 'auto', marginRight: 1 }}>
                                    <CheckOutlinedIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText>
                                    Service Limit: {organizationUsage?.usage_plan?.service_limit}
                                </ListItemText>
                            </ListItem>
                        </List>
                        <Grid item sx={{ ml: 2 }}>
                            <Typography variant="h2" color="primary">
                                ${organizationUsage?.usage_plan?.cost}
                            </Typography>
                            <Typography variant="h6" color="text.secondary">
                                / month
                            </Typography>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}
