import * as React from 'react';

import { useNavigate } from 'react-router-dom';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { fetchServiceApiKeys, url } from './common.tsx';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { handleDelete, handleToastNotification } from '../common/Utils.tsx';
import DeleteIcon from '@mui/icons-material/Delete';
import TableLayout from '../../layouts/TableLayout.tsx';
import { styles } from '../../style.tsx';


export default function ServiceApiKeysTable() {
    const [rows, setRows] = React.useState([]);

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 100,
            editable: false,
        },
        {

            field: 'name',
            headerName: 'Name',
            width: 200,
            editable: false,

        },
        {
            field: 'service',
            headerName: 'Service ID',
            width: 100,
            align: 'right'
        },
        {
            field: 'created',
            headerName: 'Date Created',
            width: 170,
            align: 'right'
        },
        {
            field: 'is_deleted',
            headerName: 'Deleted',
            width: 70,
            align: 'right'
        },
        {
            field: 'delete',
            headerName: 'Delete',
            sortable: false,
            renderCell: (params) => (
                <IconButton onClick={() => handleDelete(params.id, url, setRows)} aria-label="delete">
                    <DeleteIcon />
                </IconButton>
            ),
        },
    ];

    const navigate = useNavigate();

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchServiceApiKeys(setRows);
            } catch (error) {
                handleToastNotification(error.response);
            }
        };
        fetchData();
    }, []);

    return (
        <Grid container spacing={5} sx={styles.contentContainer}>
            <Grid item xs={12} md={12}>
                <Typography variant="h2" gutterBottom>
                    Service Api Keys
                </Typography>
                <TableLayout buttonPath='/service-api-keys/create' buttonText='New Service API Key'>
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                            }}
                            pageSizeOptions={[5, 10, 25]}
                            checkboxSelection
                            disableRowSelectionOnClick
                        />
                        <ToastContainer />
                    </Box>
                </TableLayout>
            </Grid>
        </Grid>
    );
}