import React, { useState } from 'react';
import { Button, FormControlLabel, Checkbox, RadioGroup, Radio, FormControl, InputLabel, Select, MenuItem, Slider } from '@mui/material';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { handleToastNotification } from '../common/Utils.tsx';
import { ToastContainer } from 'react-toastify';
import { formatServiceCreateRequestData, url } from './common.tsx';

import api from '../../services/ApiService.tsx';
import { fetchOrganizations } from '../organizations/common.tsx';
import { useNavigate } from 'react-router-dom';


export default function ServiceForm() {
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        organization: ''
    });
    const [organizations, setOrganizations] = useState([]);

    const navigate = useNavigate();

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const body = formatServiceCreateRequestData(formData);
        let temp = body;
        try {
            const response = await api.post(`${url}`, body);
            handleToastNotification(response);
            navigate("/services")
        } catch (error) {
            handleToastNotification(error.response);
        }
        console.log(formData);
    };

    const validJson = (input) => {
        try {
            JSON.parse(input);
            return true;
        } catch (error) {
            return false;
        }
    };

    React.useEffect(() => {
        fetchOrganizations(setOrganizations);
    }, []);


    return (
        <React.Fragment>
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    <Typography variant="h6" gutterBottom>
                        Create Service
                    </Typography>
                    <ToastContainer />
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} >
                                <TextField
                                    required
                                    id="name"
                                    name="name"
                                    label="Name"
                                    fullWidth
                                    variant="standard"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="description"
                                    name="description"
                                    label="Description"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    variant="standard"
                                    value={formData.description}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="select-field-label">Select Organization</InputLabel>
                                    <Select
                                        labelId="organization"
                                        value={formData.organization}
                                        label="Organizations"
                                        name="organization"
                                        onChange={handleChange}
                                    >
                                        {organizations.map((organization) => {
                                            return (
                                                <MenuItem value={organization.id}>{organization.name}</MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" color="primary">
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
        </React.Fragment >
    );
}
