import React, { useEffect, useRef, useState } from 'react';

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Box, Button, ThemeProvider } from '@mui/material';
import { mainTheme } from '../../theme.tsx';
import { styles } from '../../style.tsx';
import { Bold, Section } from '../common/CommonComponents.tsx';



const content = [
    {
        'header': 'END USER LICENSE AGREEMENT',
        'content': (
            <>
                <Typography paragraph>
                    Last updated  June 12, 2024
                </Typography>

                <Typography paragraph>
                    This End User License Agreement ("<Bold text='Agreement'></Bold>") is entered into by and between Ogle Software LLC ("<Bold text='Licensor'></Bold>"), and you ("End-User" or "You"). This Agreement governs your use of Beholdr.io, whether accessed through our website, mobile applications, or any updates thereto (collectively referred to as the "<Bold text='Services'></Bold>").
                </Typography>

                <Typography paragraph>
                    By downloading, accessing, or using the Services, you agree to be bound by the terms and conditions of this Agreement. If you do not agree with any part of this Agreement, you must not use the Services.
                </Typography>

                <Typography paragraph>
                    The Services are provided by Ogle Software LLC and are not affiliated with or endorsed by any third-party platforms from which you may have downloaded the Services. Ogle Software LLC is solely responsible for the content, functionality, and maintenance of the Services.
                </Typography>

                <Typography paragraph>
                    This Agreement sets forth the usage rules for the Services. These rules are designed to ensure compliance with all applicable regulations and the latest policies of any platforms through which the Services may be accessed. Ogle Software LLC has reviewed these platform policies and affirms that this Agreement does not conflict with them.
                </Typography>

                <Typography paragraph>
                    The license granted to you under this Agreement is limited, non-exclusive, non-transferable, and revocable. You are permitted to use the Services only under the terms of this Agreement and only on devices that comply with the Services' technical requirements. All rights not explicitly granted to you under this Agreement are reserved by Ogle Software LLC.
                </Typography>
            </>
        )
    },
    {
        'header': '1. THE APPLICATION',
        'content': (
            <>
                <Typography paragraph>
                    Beholdr.io ("<Bold text='Licensed Application' />") is a versatile software solution designed to provide fast, efficient, and affordable monitoring services. It is engineered to be lightweight and seamlessly integrates with your systems, ensuring continuous monitoring. Beholdr.io allows you to set preferences for alerts, ensuring you receive notifications only when necessary. The Licensed Application is customized for use on various mobile devices ("<Bold text='Devices' />"), providing end-users with the ability to monitor service metrics and detect errors that exceed predefined thresholds 24/7.
                </Typography>

                <Typography paragraph>
                    The Licensed Application is not intended for use in environments requiring compliance with industry-specific regulations, such as the Health Insurance Portability and Accountability Act (HIPAA) or the Federal Information Security Management Act (FISMA). If your interactions are subject to such regulations, you are not permitted to use this Licensed Application. Additionally, you may not use the Licensed Application in any manner that would violate the Gramm-Leach-Bliley Act (GLBA).
                </Typography>
            </>

        )
    },
    {
        'header': '2. SCOPE OF LICENSE',
        'content': (
            <>
                <Typography paragraph>
                    <strong>2.1 Updates and Supplements</strong><br />
                    This license applies to any updates, patches, or enhancements provided by Ogle Software LLC ("<Bold text='Licensor' />") that replace, repair, or supplement the original Licensed Application. If a separate license is provided with any update, that new license will govern instead of this Agreement.
                </Typography>

                <Typography paragraph>
                    <strong>2.2 Restrictions on Distribution</strong><br />
                    You are not permitted to share, distribute, or make the Licensed Application available to third parties, except as allowed by the Usage Rules and with prior written consent from Beholdr.io. This includes selling, renting, lending, leasing, or any other form of redistribution.
                </Typography>

                <Typography paragraph>
                    <strong>2.3 Prohibition on Reverse Engineering</strong><br />
                    You may not reverse engineer, translate, disassemble, integrate, decompile, modify, create derivative works from, or attempt to derive the source code of the Licensed Application or any part thereof, unless you have prior written consent from Beholdr.io.
                </Typography>

                <Typography paragraph>
                    <strong>2.4 Copying and Backup Restrictions</strong><br />
                    Copying or altering the Licensed Application is prohibited except as explicitly authorized by this license and the Usage Rules. You may create and store copies of the Licensed Application only on devices that you own or control, strictly for backup purposes under the terms of this license and applicable Usage Rules. All intellectual property notices must remain intact, and you must ensure that no unauthorized third parties gain access to these copies. Before selling any device containing the Licensed Application, you must remove the software from the device.
                </Typography>

                <Typography paragraph>
                    <strong>2.5 Legal Consequences of Violations</strong><br />
                    Any violation or attempted violation of these terms may lead to prosecution and damages.
                </Typography>

                <Typography paragraph>
                    <strong>2.6 Right to Modify Terms</strong><br />
                    Beholdr.io reserves the right to modify the terms and conditions of this license at any time. Such modifications will be effective upon notification to you.
                </Typography>

                <Typography paragraph>
                    <strong>2.7 Compliance with Third-Party Terms</strong><br />
                    Nothing in this license should be interpreted to restrict third-party terms. When using the Licensed Application, you must ensure that you comply with applicable third-party terms and conditions.
                </Typography>
            </>
        )
    },
    {
        'header': '3. TECHNICAL REQUIREMENTS',
        'content': (
            <>
                <Typography paragraph>
                    <strong>3.1</strong> Ogle Software LLC ("<Bold text='Licensor' />") reserves the right to modify the technical specifications of the Licensed Application at any time as it sees appropriate. These changes may include updates, enhancements, or changes to system requirements and operational parameters.
                </Typography>
            </>
        )
    },
    {
        'header': '4. MAINTENANCE AND SUPPORT',
        'content': (
            <>
                <Typography paragraph>
                    <strong>4.1</strong> Ogle Software LLC ("<Bold text='Licensor' />") is solely responsible for providing any maintenance and support services related to the Licensed Application. For assistance, you can contact the Licensor at the email address provided in the Overview section of the Licensed Application.
                </Typography>
                <Typography paragraph>
                    <strong>4.2</strong> Ogle Software LLC and the End-User acknowledge that any third-party Services, such as platforms from which the Licensed Application may be downloaded, have no obligation whatsoever to furnish any maintenance and support services for the Licensed Application.
                </Typography>
                <Typography paragraph>
                    <strong>4.3</strong> Ogle Software LLC is not responsible for any damages or losses resulting from interruptions or outages of the Services. End-Users are solely responsible for ensuring that their own systems are operational and adequately covered at all times. Users should have contingency plans to mitigate the impact of any potential service interruptions. This includes maintaining independent monitoring and backup systems to safeguard their operations against any disruptions in the Services provided by Beholdr.io.
                </Typography>
            </>
        )
    },
    {
        'header': '5. User-Generated Contributions',
        'content': (
            <>
                <Typography paragraph>
                    The Licensed Application currently does not allow users to submit or post content directly. However, should we provide the capability to create, submit, post, display, or transmit content and materials to us or within the Licensed Application, these items are referred to as "<Bold text='Contributions' />". Contributions may be viewable by other users of the Licensed Application and through third-party websites or applications. By making any Contributions, you represent and warrant that:
                    <ol>
                        <li>The Contributions do not infringe on the proprietary rights of any third party.</li>
                        <li>You have the necessary rights and permissions to use and authorize us to use your Contributions as described in this Agreement.</li>
                        <li>You have the consent of all individuals identifiable in your Contributions for their inclusion and use.</li>
                        <li>Your Contributions are accurate and not misleading.</li>
                        <li>Your Contributions are not spam or unsolicited advertisements.</li>
                        <li>Your Contributions are not offensive, abusive, or otherwise objectionable.</li>
                        <li>Your Contributions do not harass, threaten, or promote violence against others.</li>
                        <li>Your Contributions comply with all applicable laws and regulations.</li>
                        <li>Your Contributions do not violate the privacy or rights of any third party.</li>
                        <li>Your Contributions do not include unlawful content, especially content that protects minors.</li>
                        <li>Your Contributions do not contain offensive content related to race, gender, or other protected characteristics.</li>
                        <li>Your Contributions comply with all terms and laws referenced in this Agreement.</li>
                    </ol>
                    Any use of the Licensed Application in violation of these conditions may result in termination or suspension of your rights to use the Licensed Application.
                </Typography>
            </>
        )
    },
    {
        'header': '6. Contribution License',
        'content': (
            <>
                <Typography paragraph>
                    By submitting Contributions, you agree that we may access, store, process, and use any information and personal data you provide according to our Privacy Policy and your settings. You also grant us the right to use and share any feedback you provide without compensation. We do not claim ownership over your Contributions; you retain full ownership and any associated rights. You are responsible for your Contributions and agree to indemnify Ogle Software LLC from any liability related to them.
                </Typography>
            </>
        )
    },
    {
        'header': '7. Liability',
        'content': (
            <>
                <Typography paragraph>
                    <strong>7.1</strong> Licensor's liability is limited to cases of intent and gross negligence. For breaches of essential contractual duties, Licensor is also liable for slight negligence, but only for foreseeable, typical damages. This limitation does not apply to liability for injuries to life, limb, or health.
                </Typography>
                <Typography paragraph>
                    <strong>7.2</strong> Beholdr.io’s total liability under this Agreement, regardless of the form of action, shall not exceed the amount paid by you for the Licensed Application in the six (3) months preceding the claim. Under no circumstances shall Ogle Software LLC be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Licensed Application, including, but not limited to, damages for loss of profits, loss of data, business interruption, or other commercial damages or losses, even if advised of the possibility of such damages.
                </Typography>
                <Typography paragraph>
                    <strong>7.3</strong> Ogle Software LLC shall not be liable for any delay or failure to perform resulting from causes outside its reasonable control, including but not limited to acts of God, war, terrorism, labor disputes, natural disasters, or governmental actions.
                </Typography>
            </>
        )
    },
    {
        'header': '8. Warranty',
        'content': (
            <>
                <Typography paragraph>
                    <strong>8.1</strong> Licensor warrants that the Licensed Application is free from spyware, trojan horses, viruses, or other malware at the time of download and that it functions as described in the user documentation.
                </Typography>
                <Typography paragraph>
                    <strong>8.2</strong> No warranty is provided if the Licensed Application is not executable on your device due to modifications or misuse outside of Licensor's control.
                </Typography>
                <Typography paragraph>
                    <strong>8.3</strong> You must inspect the Licensed Application immediately after installation and notify Ogle Software LLC of any defects within thirty (30) days. Licensor will investigate valid defect reports.
                </Typography>
                <Typography paragraph>
                    <strong>8.4</strong> If a defect is confirmed, Ogle Software LLC may choose to remedy the defect or provide a replacement.
                </Typography>
                <Typography paragraph>
                    <strong>8.5</strong> For defects related to the Licensed Application, you may seek a refund from the Services Store Operator. The Services Store Operator has no other warranty obligations beyond the purchase price refund.
                </Typography>
                <Typography paragraph>
                    <strong>8.6</strong> For entrepreneurs, claims based on faults expire after twelve (6) months from the date of availability. For consumers, statutory periods of limitation apply.
                </Typography>
                <Typography paragraph>
                    <strong>8.7</strong> The Licensed Application is provided "as is" and "as available" without any warranties of any kind, either express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement. Ogle Software LLC does not warrant that the Licensed Application will meet your requirements, operate without interruption, or be free of errors.
                </Typography>
            </>
        )
    },
    {
        'header': '9. Product Claims',
        'content': (
            <>
                <Typography paragraph>
                    Ogle Software LLC is responsible for addressing any claims related to the Licensed Application, including product liability claims, failure to meet legal or regulatory requirements, and claims under consumer protection laws.
                </Typography>
            </>
        )
    },
    {
        'header': '10. Legal Compliance',
        'content': (
            <>
                <Typography paragraph>
                    You represent and warrant that you are not located in a country subject to a US Government embargo or designated as a "terrorist supporting" country, and you are not listed on any US Government list of prohibited or restricted parties.
                </Typography>
            </>
        )
    },
    {
        'header': '11. Contact Information',
        'content': (
            <>
                <Typography paragraph>
                    For any inquiries, complaints, or claims regarding the Licensed Application, please contact:<br />
                    devin@beholdr.io
                </Typography>
            </>
        )
    },
    {
        'header': '12. Termination',
        'content': (
            <>
                <Typography paragraph>
                    This license remains in effect until terminated by either Ogle Software LLC or you. Your rights under this license will terminate automatically without notice if you fail to comply with any terms. Upon termination, you must cease all use of the Licensed Application and destroy all copies.
                </Typography>
            </>
        )
    },
    {
        'header': '13. Third-Party Terms of Agreements and Beneficiary',
        'content': (
            <>
                <Typography paragraph>
                    Ogle Software LLC agrees to comply with applicable third-party terms when using the Licensed Application. Subsidiaries of Ogle Software LLC are considered third-party beneficiaries of this Agreement and may enforce its terms against you.
                </Typography>
            </>
        )
    },
    {
        'header': '14. Intellectual Property Rights',
        'content': (
            <>
                <Typography paragraph>
                    Ogle Software LLC is responsible for addressing any third-party claims that the Licensed Application infringes on intellectual property rights. Ogle Software LLC will handle the investigation, defense, settlement, and discharge of any such claims.
                </Typography>
            </>
        )
    },
    {
        'header': '15. Applicable Law',
        'content': (
            <>
                <Typography paragraph>
                    This License Agreement is governed by the laws of the State of Georgia, excluding its conflict of law rules.
                </Typography>
            </>
        )
    },
    {
        'header': '16. Miscellaneous',
        'content': (
            <>
                <Typography paragraph>
                    <strong>16.1</strong> If any term of this Agreement is found to be invalid or unenforceable, the remaining provisions will remain in effect. Invalid terms will be replaced by valid ones that achieve the original intent.
                </Typography>
                <Typography paragraph>
                    <strong>16.2</strong> Any changes or amendments to this Agreement must be in writing to be valid. This requirement can only be waived in writing.
                </Typography>
            </>
        )
    },
]

export function Eula() {

    return (
        <ThemeProvider theme={mainTheme}>

            <Container disableGutters maxWidth={false} sx={styles.contentContainer}>
                <Grid container direction="column" spacing={2}>
                    {content.map((item, index) => (
                        <Grid item key={index} sx={{ mb: 3 }}>
                            <Section title={item.header} content={item.content} headerStyle={{mb: 2, typography: 'bold'}} />
                        </Grid>
                    ))}

                </Grid>
            </Container>
        </ThemeProvider>
    );
}

interface EulaModalProps {
    onClose: () => void;
    onAccept: () => void;
}

export const EulaModal: React.FC<EulaModalProps> = ({ onClose, onAccept }) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const [canAccept, setCanAccept] = useState(false);

    useEffect(() => {
        const content = contentRef.current;

        const handleScroll = () => {
            if (content) {
                const { scrollTop, scrollHeight, clientHeight } = content;
                if (scrollTop + clientHeight >= scrollHeight) {
                    setCanAccept(true);
                }
            }
        };

        if (content) {
            content.addEventListener('scroll', handleScroll);
            return () => {
                content.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    const handleAccept = () => {
        onAccept();
        onClose();
    };

    const handleDecline = () => {
        onClose();
    };

    return (
        <Grid container md={12}>
            <Grid item>
                <Typography variant="h6" component="h2" gutterBottom>
                </Typography>
                <Box
                    ref={contentRef}
                    sx={{
                        maxHeight: 300,
                        overflowY: 'auto',
                        padding: '0 16px',
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                    }}
                >
                    <Eula></Eula>
                </Box>
            </Grid>
            <Grid container item md={12} textAlign="center">
                <Grid item xs={6}>
                    <Button
                        onClick={handleDecline}
                        color="secondary"
                        sx={{ marginTop: '16px' }}
                    >
                        Decline
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        onClick={handleAccept}
                        color="secondary"
                        disabled={!canAccept}
                        sx={{ marginTop: '16px' }}
                    >
                        Accept
                    </Button>
                </Grid>

            </Grid>
        </Grid>
    );
};
