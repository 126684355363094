import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styles } from '../../style.tsx';
import { Box, Button, Grid, Typography } from '@mui/material';


import BrBlackLogo from '../../static/icons/Br_black_logo.jpg';
import CreateOrganizationMock from '../../static/images/Create-Organization-Mock.webp';
import CreateServiceMock from '../../static/images/Create-Service-Mock.webp';
import CreateServiceApiKeyMock from '../../static/images/Create-Service-Api-Key-Mock.webp';
import CreateMonitorMetricsMock from '../../static/images/Monitor-Metrics-Mock.webp';
import CreateMonitorMock from '../../static/images/Create-Monitor-Mock.webp';
import MetricViewerMock from '../../static/images/Monitor-Metrics-Mock-2.webp';



const sectionMargin = 25;


const onboardingStepsContent = [
    {
        "title": "1. Organization",
        "subTitle": "The central hub that encompasses all of your resources",
        "paragraph": "First things first, you'll need to create your Organization to manage all of your resources. Start by giving it a meaningful name and providing a primary contact email address. This email will be used for communication regarding your organization or billing inquiries.",
        "buttonTitle": "Organizations",
        "buttonPath": "/organizations",
        "icon": <Box
            component="img"
            alt="Create Organization Mock"
            src={CreateOrganizationMock}
            sx={{
                width: "100%",
                maxWidth: "600px",
                // width: "600px",
                height: "auto",
                m: '0px',
                p: '0px'
            }}
        ></Box>
    },
    {
        "title": "2. Services",
        "subTitle": "The resources whose metrics you'd like Beholdr.io to monitor.",
        "paragraph": "This entity represents the service within your organization that you wish to monitor using Beholdr.io. It should utilize Beholdr.io's Service API Keys for authentication and data submission. You can assign specific names to the metrics generated by your services, allowing you to effectively track and monitor the operation of your service workflows.",
        "buttonTitle": "Services",
        "buttonPath": "/services",
        "icon": <Box
            component="img"
            alt="Create Service Mock"
            src={CreateServiceMock}
            sx={{
                width: "100%",
                maxWidth: "600px",
                height: "auto",
                m: '0px',
                p: '0px'
            }}
        ></Box>,
    },
    {
        "title": "3. Service API Keys",
        "subTitle": "The authentication your services need.",
        "paragraph": "These keys enable you to authenticate your services with Beholdr.io and track the origin of your metrics.",
        "buttonTitle": "API Keys",
        "buttonPath": "/service-api-keys",
        "icon": <Box
            component="img"
            alt="Service API Key Mock"
            src={CreateServiceApiKeyMock}
            sx={{
                width: "100%",
                maxWidth: "600px",
                height: "auto",
                m: '0px',
                p: '0px'
            }}
        ></Box>,
    },
    {
        "title": "4. Emit Metrics",
        "subTitle": "Let Beholdr.io monitor your metrics for you.",
        "paragraph": "Start sending metrics from your services with meaningful names and messages. You can set up monitors to alert you based on the metric you provide to Beholdr.io.",
        "buttonTitle": "SDK",
        "buttonPath": "https://github.com/devin-beholdr-io/beholdr_io_sdk",
        "icon": <Box
            component="img"
            alt="Monitor Metrics Mock"
            src="/assets/images/Monitor-Metrics-Mock.webp"
            sx={{
                width: "100%",
                maxWidth: "600px",
                height: "auto",
                m: '0px',
                p: '0px'
            }}
        ></Box>,
    },
    {
        "title": "5. Monitors",
        "subTitle": "Receive notifications when and how you prefer.",
        "paragraph": "Monitors enable you to set thresholds, customize notification content, and choose notification channels. Select the metric you're tracking and get alerted when it surpasses your specified threshold, whether it's service heartbeats, error levels, or event notifications. Monitors work around the clock, keeping an eye on your services for you.",
        "buttonTitle": "Monitors",
        "buttonPath": "/monitors",
        "icon": <Box
            component="img"
            alt="Create Monitor Mock"
            src={CreateMonitorMock}
            sx={{
                width: "100%",
                maxWidth: "600px",
                height: "auto",
                m: '0px',
                p: '0px'
            }}
        ></Box>,
    },
    {
        "title": "6. Users",
        "subTitle": "Add users to your Organization.",
        "paragraph": "Invite users to join your Organization on Beholdr.io and specify their access levels. You can grant them permissions to make changes to your organization, create and manage resources, or restrict them to viewing resources only.",
        "buttonTitle": "Users",
        "buttonPath": "/users",
        "icon": null,
    },
    {
        "title": "7. Usage Plan",
        "subTitle": "Subscribe to our Pro plan.",
        "paragraph": "Beholdr.io offers an affordable Pro plan, enabling you to monitor your resources and scale your subscription based on your organization's needs. With no commitments or contracts, we prioritize ease of use, giving you the flexibility to manage your subscription as you wish.",
        "buttonTitle": "Billing",
        "buttonPath": "/billing",
        "icon": null,
    },
];



const StepComponent = ({ title, subTitle, paragraph, icon, buttonTitle, buttonPath }) => {

    let renderedButton = <></>;
    if (buttonPath && buttonTitle) {
        renderedButton = (
            <Button variant='contained' color="primary" onClick={() => window.open(buttonPath, '_blank')}>
                {buttonTitle}
            </Button>
        );
    }

    return (
        <Grid container md={12} mb={sectionMargin}>
            <Grid item sm={12} md={4} mb={5}>
                <Grid container item sm={12} md={12} >
                    <Grid item sm={12} md={12}>
                        <Typography sx={{ color: "secondary.main", fontSize: 20, fontWeight: "bold" }}>{title}</Typography>
                    </Grid>
                    <Grid item sm={12} md={12}>
                        <Typography variant='h2'>{subTitle}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography paragraph>{paragraph}</Typography>
                    </Grid>
                </Grid>
                <Grid container item md={12}>
                    <Grid item md={6}>
                        {renderedButton}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item sm={12} md={8} justifyContent={'center'}>
                {icon}
            </Grid>
        </Grid>
    );
};

const Onboarding: React.FC = () => {
    let navigate = useNavigate();

    return (
        <React.Fragment>

            {/* Header */}
            <Box sx={{
                minHeight: '50vh',
                background: 'linear-gradient(to bottom, #2a3439 40%, white)',
                color: '#fff',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}>
                <Grid container mb={10} paddingTop={4}>
                    <Grid item md={6} >
                        <Grid container item md={12} paddingLeft={4}>
                            <Grid container item md={1} mr={2} textAlign="left">
                                <Grid item md={2}>
                                    <Box
                                        component="img"
                                        alt="Beholdr Black Logo"
                                        src={BrBlackLogo}
                                        sx={{
                                            width: "50px",
                                            height: "auto"
                                        }}
                                    ></Box>
                                </Grid>
                            </Grid>
                            <Grid container item md={8} textAlign="left" alignItems="center" >
                                <Grid item>
                                    <Typography sx={{ fontSize: 23 }}>Beholdr.io Monitoring</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item md={12} spacing={2} paddingLeft={4}>
                            <Grid item>
                                <Typography variant='h1'>We built a monitoring solution so you don’t have to</Typography>
                            </Grid>
                            <Grid item>
                                <Typography paragraph>Beholdr.io is a user-friendly and cost-effective metric monitoring solution. Easily emit metrics from any process and set monitoring thresholds to receive notifications when you need them. Whether you're tracking service heartbeats, event notifications, or error thresholds, Beholdr.io has you covered.</Typography>
                            </Grid>
                            <Grid container item md={12}>
                                <Grid item md={6}>
                                    <Button variant='contained' onClick={() => navigate('/login')} sx={{ backgroundColor: 'secondary.main', color: "black" }}>
                                        Sign Up
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item mt={2} justifyContent="left" md={6} sx={{ overflowX: 'hidden' }}>
                        <Box
                            component="img"
                            alt="Metric Viewer Mock"
                            src={MetricViewerMock}
                            sx={{
                                width: 'auto',
                                height: '600px',
                                right: '-20%',
                            }}
                        ></Box>
                    </Grid>
                </Grid>
            </Box>

            <Grid container sx={styles.contentContainer}>
                {/* Content */}
                {onboardingStepsContent.map((item) => (
                    <StepComponent
                        icon={item.icon}
                        paragraph={item.paragraph}
                        subTitle={item.subTitle}
                        title={item.title}
                        buttonPath={item.buttonPath}
                        buttonTitle={item.buttonTitle}
                    />
                ))}
            </Grid>

        </React.Fragment>
    );
}

export default Onboarding;

