import React from "react";

import { Box, Button, Grid } from "@mui/material";

import { styles } from "../../style.tsx";
import MetricClassifierInput from "./MetricClassifierInput.tsx";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { fetchMonitors } from "../monitor/common.tsx";
import { toast } from "react-toastify";
import api from "../../services/ApiService.tsx";
import { handleToastNotification } from "../common/Utils.tsx";
import MonitorMetricChart from "./MonitorMetricChart.tsx";
import { formatMetricRequestFilters, MetricSearchProps } from "./common.tsx";
import MonitorSearchInput from "../monitor/MonitorSearchInput.tsx";
import { CustomDatePicker } from "../common/CommonComponents.tsx";

const url = 'metrics-detail/';


export const MetricSearch: React.FC<MetricSearchProps> = ({ filters, setFilters, signalFilterSubmittedEvent }) => {
    // Metric Filter States
    const [monitors, setMonitors] = React.useState([]);
    // Table States
    const [dataSeries, setDataSeries] = React.useState([]);
    const [catagories, setCatagories] = React.useState([]);


    const handleStartDateChange = (date) => {
        setFilters({
            ...filters,
            startDate: date,
            monitorName: '',
        });
    };

    const handleEndDateChange = (date) => {
        setFilters({
            ...filters,
            endDate: date,
            monitorName: '',
        });
    };

    const handleWindowPresetUsed = (startDate, endDate) => {
        setFilters({
            ...filters,
            startDate: startDate,
            endDate: endDate,
            monitorName: '',
        });
    };

    const handleFilterChange = (name: string, value: string) => {
        if (name === 'monitorName') {
            setFilters((prevFilters) => ({
                ...prevFilters,
                [name]: value,
                metricName: '',
                startDate: null,
                endDate: null,
            }));
        } else if (name === 'metricName') {
            setFilters((prevFilters) => ({
                ...prevFilters,
                [name]: value,
                monitorName: '',
            }));
        } else {
            setFilters((prevFilters) => ({
                ...prevFilters,
                [name]: value,
            }));
        }
    };

    const ValidateMetricNameFilter = (filters) => {
        if (filters.metricName === '') {
            toast.error("Metric Name must be when using date range.")
            return false;
        }
        if (filters.startDate && !filters.startDate.isBefore(filters.endDate)) {
            toast.error("Start Date must be sometime before the End Date.")
            return false;
        }
        if (filters.metricName && filters.monitorName) {
            toast.error("You must select either Metric Name or Monitor to filter by.")
            return false;
        }
        return true;
    }

    const ValidateMonitorFilter = (filters) => {
        if (filters.startDate || filters.endDate) {
            toast.error("Date range is not supported for Monitor filters.")
            return false;
        }
        if (filters.monitorName === '') {
            toast.error("Date range is not supported for Monitor filters.")
            return false;
        }
        return true;
    }

    const ValidFormData = (filters) => {
        if (filters.metricName === '' && filters.startDate === null && filters.endDate === null) {
            return ValidateMonitorFilter(filters);
        } else {
            return ValidateMetricNameFilter(filters);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!ValidFormData(filters)) {
            return;
        }

        const body = formatMetricRequestFilters(filters);
        try {
            const response = await api.post(`${url}`, body);
            parseMetricDetailGetData(response.data);
            signalFilterSubmittedEvent();
        } catch (error) {
            handleToastNotification(error.response);
        }

    };

    const parseMetricNameQuery = (data) => {
        const windows = data.data;
        let tempMetricSeries = {
            name: "Metrics",
            type: 'area',
            data: []
        };

        let tempCategories = [];

        for (const window of windows) {
            tempMetricSeries.data.push(window.sum)
            tempCategories.push(window.interval_end)
        }

        setDataSeries([tempMetricSeries]);
        setCatagories(tempCategories);
    };

    const parseMonitorNameQuery = (data) => {
        const windows = data.data;
        let tempMetricSeries = {
            name: "Metrics",
            type: 'area',
            data: []
        };

        let tempThresholdSeries = {
            name: "Threshold",
            type: 'line',
            data: [],
        };

        let tempCategories = [];

        let selectedMonitor = monitors.find(m => m.name === filters.monitorName);

        for (const window of windows) {
            tempMetricSeries.data.push(window.sum)
            tempThresholdSeries.data.push(selectedMonitor.threshold)
            tempCategories.push(window.interval_end)
        }

        setDataSeries([tempMetricSeries, tempThresholdSeries]);
        setCatagories(tempCategories);
    };

    const parseMetricDetailGetData = (data) => {
        const windows = data.data;

        if (windows.length === 0) { return; }

        if (filters.metricName !== '') {
            parseMetricNameQuery(data);
        }
        if (filters.monitorName !== '') {
            parseMonitorNameQuery(data);
        }
    };

    // Run on mount
    React.useEffect(() => {
        fetchMonitors(setMonitors);
    }, []);

    React.useEffect(() => { }, [filters, dataSeries, catagories]);

    return (
        <Grid container spacing={1} sx={styles.contentContainer}>
            <Grid item xs={12} md={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container alignItems={'center'} justifyContent="center" textAlign={'center'} spacing={2} item xs={12} md={12}>
                        <Grid item xs={6} md={3}>
                            <CustomDatePicker
                                label={"Start Date"}
                                value={filters.startDate}
                                onChange={handleStartDateChange}
                                handlePresetSelected={handleWindowPresetUsed}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <CustomDatePicker
                                label={"End Date"}
                                value={filters.endDate}
                                onChange={handleEndDateChange}
                                handlePresetSelected={handleWindowPresetUsed}
                            />
                        </Grid>
                        {/*Metric Table*/}
                        <Grid item xs={12} md={12}>
                            <Box width="100%" minHeight="350px">
                                <MonitorMetricChart series={dataSeries} categories={catagories} height={400} />
                            </Box>
                        </Grid>
                        <Grid container alignItems={'center'} justifyContent="center" spacing={5}>
                            {/* Metric Input */}
                            <Grid item xs={6} md={3}>
                                <Box width="100%" height="350px">
                                    <MetricClassifierInput
                                        handleFilterChange={handleFilterChange}
                                        initialInput={filters.metricName}
                                    />
                                </Box>
                            </Grid>
                            {/* Monitor Input */}
                            <Grid item xs={6} md={3} sx={{ color: 'black' }}>
                                <Box width="100%" height="350px">
                                    <MonitorSearchInput
                                        handleFilterChange={handleFilterChange}
                                        initialInput={filters.monitorName}
                                    />
                                </Box>

                            </Grid>

                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Button type="submit" variant="contained" color="primary" onClick={handleSubmit} sx={{ width: '300px' }}>
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </LocalizationProvider>
            </Grid>
        </Grid>
    );
}