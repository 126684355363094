import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Chip, Grid, Typography } from '@mui/material';
import { AnnouncementCard, defaultPadding } from './template.tsx';


const content = [
    {
        "title": "Fixing Gmail Authentication Issues",
        "subtitle": "Handling 'Message Blocked' by Google Managed Email Domains",
        "date": "September 29, 2024",
        "body": "Resolving 'Message Blocked' Errors for Google-Managed Domains. This guide provides a quick walkthrough on how to fix your email authentication issues, ensuring your emails are no longer blocked.",
        "chips": <>
            <Chip label="RELEASE" variant="outlined" />
            <Chip label="BEHOLDR.IO" variant="outlined" />
        </>,
        "blogUrl": "/blogs/FixingGmailAuthenticationIssues",
    },
    {
        "title": "Using Beholdr.io To Monitor Your Service",
        "subtitle": "Integration & Common Monitoring Use Cases",
        "date": "September 2, 2024",
        "body": "Effortlessly monitor your services with Beholdr.io—integrate quickly and set up customized alerts to ensure proactive system management without the complexity.",
        "chips": <>
            <Chip label="RELEASE" variant="outlined" />
            <Chip label="BEHOLDR.IO" variant="outlined" />
        </>,
        "blogUrl": "/blogs/UsingBeholdrIoToMonitorYourService",
    },
    {
        "title": "Best Practices For Monitoring Your Application",
        "subtitle": "How Beholdr.io Can Help",
        "date": "August 30, 2024",
        "body": "Monitoring is essential to ensure your applications' reliable operation and a vital practice of the DevOps movement. Learn more about the role of monitoring in the development workflow and the best practices for monitoring your applications.",
        "chips": <>
            <Chip label="RELEASE" variant="outlined" />
            <Chip label="BEHOLDR.IO" variant="outlined" />
        </>,
        "blogUrl": "/blogs/BestPracticesForMonitoringYourApplication",
    },
];

const Blogs: React.FC = () => {
    let navigate = useNavigate();

    
    return (
        <React.Fragment>

            {/* Header */}
            <Box sx={{
                minHeight: '40vh',
                background: 'linear-gradient(to bottom, #2a3439 40%, white)',
                color: '#fff',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}>

                <Grid container mb={8} p={1} paddingTop={4}>
                    {/* Header */}
                    <Grid item xs={12} md={12}>
                        <Grid container justifyContent="center" alignItems="center" textAlign={'center'}>
                            <Grid item xs={12} md={12} mb={2}>
                                <Typography variant='title' sx={{ fontWeight: 600 }}>Beholdr.io Blog</Typography>
                            </Grid>
                            <Grid item xs={12} md={12} mb={5}>
                                <Typography variant='paragraph' sx={{ color: "grey", fontSize: 20 }}>Guides, announcements and articles about Beholdr.io, monitoring and alerting.</Typography>
                            </Grid>
                            <Grid item>
                                <Button variant='contained' color="primary" onClick={() => navigate('/login')} sx={{ backgroundColor: 'secondary.main', width: '200px' }}>
                                    Login
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <Grid container justifyContent={'center'} alignItems={'center'} spacing={3} mb={4}>
                {content.map((item, index) => (
                    <Grid item md={4} key={index} >
                         <Grid container justifyContent={'center'} alignItems={'center'}>
                            <AnnouncementCard
                                title={item.title}
                                subtitle={item.subtitle}
                                date={item.date}
                                body={item.body}
                                chips={item.chips}
                                blogUrl={item.blogUrl}
                            />
                        </Grid>
                        
                    </Grid>
                ))}
            </Grid>
        </React.Fragment>
    );
};

export default Blogs;