import { createTheme } from '@mui/material/styles';

import { Theme as MUITheme, TypographyStyleOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
    interface TypographyVariants {
        callout: TypographyStyleOptions;
        title: TypographyStyleOptions;
        title2: TypographyStyleOptions;
        bold: TypographyStyleOptions;
        subtitle: TypographyStyleOptions;
        cardTitle: TypographyStyleOptions;


    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        callout?: TypographyStyleOptions;
        title?: TypographyStyleOptions;
        title2?: TypographyStyleOptions;
        bold?: TypographyStyleOptions;
        subtitle?: TypographyStyleOptions;
        cardTitle?: TypographyStyleOptions;

    }
}

// Update the theme interface
declare module '@mui/system' {
    interface Theme {
        typography: TypographyVariants;
    }
    interface ThemeOptions {
        typography?: TypographyVariantsOptions;
    }
}

// Create a theme instance.
export const mainTheme = createTheme({
    palette: {
        primary: {
            main: '#17171b',
        },
        secondary: {
            main: '#4ADE80',
        },
        error: {
            main: '#ff0000',
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        fontFamily: '"Inter", "Inter Fallback", sans-serif',
        fontSize: 14,
        // In the process of updating typography, these will eventually replace h1, h2, etc.
        title: {
            fontSize: '2.25rem',
            lineHeight: '2.5rem',
        },
        title2 : {
            fontSize: '1.5rem',
            lineHeight: '2rem',
        },
        cardTitle: {
            fontSize: '1.5rem',
            lineHeight: '2rem',
        },

        h1: {
            fontSize: '2.2rem',
        },
        h2: {
            fontSize: '1.8rem',
        },
        h3: {
            fontSize: '1.4rem',
        },
        subtitle: {
            fontSize: '1.125rem',
            lineHeight: '1.75rem',
        },
        callout: {
            fontWeight: 'bold',
            fontStyle: 'italic',
        },
        bold : {
            fontWeight: 'bold',
        }
        // More typography settings can be defined here
    },
    // You can also define custom properties here
});
