import axios from 'axios';
import { toast } from 'react-toastify';
import api from '../../services/ApiService.tsx';

export const handleToastNotification = (response) => {
  // Check if the response status code indicates success (e.g., 200-299 range)
  if(response === undefined){
    toast.error("Unable to fulfill request.")
    return;
  }
  if (response.status >= 200 && response.status < 300) {
    // Customize the message or make it dynamic as needed
    toast.success(response.data.message)
    return;
  } else {
    // Handle non-success cases or throw an error to be caught by a higher-level error handler
    try {
      toast.error(response.data.message)
    } catch {
      toast.error("Unable to fulfill request.")
    }
  }
};


// Used by tables to delete resources and rows
export const handleDelete = async (id, url, setRows) => {
    try {
        const response = await api.delete(`${url}${id}/`);
        setRows(prevRows => prevRows.filter(row => row.id !== id));
        handleToastNotification(response);
    } catch (error) {
        handleToastNotification(error.response);
    }
};
