import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridPaginationModel, GridValueGetterParams } from '@mui/x-data-grid';
import { handleToastNotification } from '../common/Utils.tsx'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Grid, IconButton, TextField, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import { handleDelete } from '../common/Utils.tsx';
import api from '../../services/ApiService.tsx';
import TableLayout from '../../layouts/TableLayout.tsx';
import { fetchMonitors, formatGetMonitorData, formatMonitorPatchRequestData, url, validateEmails } from './common.tsx';
import { styles } from '../../style.tsx';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';






function formatSubscribersField(subscribersColumn) {
    if (typeof subscribersColumn === 'string') {
        return subscribersColumn;
    }

    if (typeof subscribersColumn === 'object') {
        return JSON.stringify(subscribersColumn);
    }
}



export default function MonitorTable() {
    const [rows, setRows] = React.useState([]);
    const [paginationModel, setPaginationModel] = React.useState<GridPaginationModel>({ page: 0, pageSize: 5 });
    const [rowCount, setRowCount] = React.useState(0);

    const navigate = useNavigate();

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 70,
            editable: false,
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 250,
            editable: true,
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 100,
            editable: true,
            type: 'singleSelect',
            valueOptions: [
                // { "value": 1, "label": "Instant" },
                { "value": 2, "label": 'Threshold' },
                { "value": 3, "label": 'Heartbeat' }
            ],
        },
        {
            field: 'metric_name',
            headerName: 'Metric Name',
            width: 250,
            editable: false,
        },
        {
            field: 'service',
            headerName: 'Service',
            width: 70,
            editable: true,
        },
        {
            field: 'window',
            headerName: 'Window',
            width: 70,
            editable: true,
        },
        {
            field: 'threshold',
            headerName: 'Threshold',
            width: 100,
            editable: true,
        },
        {
            field: 'subscribers',
            headerName: 'Subscribers',
            width: 170,
            editable: true,
        },
        {
            field: 'notification_content',
            headerName: 'Notification Content',
            width: 170,
            editable: true,
        },
        {
            field: 'is_deleted',
            headerName: 'Deleted',
            width: 170,
            editable: false,
        },
        {
            field: 'date_created',
            headerName: 'Date Created',
            width: 170,
            editable: false,
        },
        {
            field: 'delete',
            headerName: 'Delete',
            sortable: false,
            renderCell: (params) => (
                <IconButton onClick={() => handleDelete(params.id, url, setRows)} aria-label="delete">
                    <DeleteIcon />
                </IconButton>
            ),
        },
        {
            field: 'edit',
            headerName: 'Edit',
            sortable: false,
            renderCell: (params) => (
                <IconButton onClick={() => navigate(`/monitor/edit/${params.id}`)}>
                    <EditIcon />
                </IconButton>
            ),
        },
    ];

    const determinePageParams = (paginationModel) => {
        return {
            page: paginationModel.page + 1,
            page_size: paginationModel.pageSize,
        };
    }

    const handleProcessRowUpdate = async (updatedRow, originalRow) => {

        // Determine the changed values
        const changedValues = {};
        Object.keys(updatedRow).forEach((key) => {
            if (updatedRow[key] !== originalRow[key]) {
                changedValues[key] = updatedRow[key];
            }
        });

        // Return original row and prevent request being made when no changes were made.
        if (Object.keys(changedValues).length === 0) {
            return originalRow;
        }


        // Format editied fields for patch request. Notify user if json field are not parseable.
        if (!validateEmails(updatedRow.subscribers)) {
            toast.error("Emails are not formatted correctly.")
            return updatedRow.subscribers;
        }

        const body = formatMonitorPatchRequestData(changedValues);

        // Update updatedRow with parsed inputs
        Object.keys(body).forEach((key) => {
            if (updatedRow[key] !== body[key]) {
                updatedRow[key] = body[key];
            }
        });

        try {
            const response = await api.patch(`${url}${updatedRow.id}/`, body);
            handleToastNotification(response);
            return updatedRow;
        } catch (error) {
            handleToastNotification(error.response);
            return originalRow;
        }

    };


    function onProcessRowUpdateError(error) {
        console.error('Error occurred while updating row:', error);
    }


    React.useEffect(() => {

        const fetchData = async () => {
            try {
                let params = determinePageParams(paginationModel);
                const response = await api.get(url, {params});
                setRowCount(response.data.count);
                const formattedRows: [] = response.data.data
                    .filter(row => row.is_deleted != true)
                    .map(
                        row => formatGetMonitorData(
                            row.id,
                            row.name,
                            row.metric_name,
                            row.type,
                            row.service,
                            row.window,
                            row.threshold,
                            row.subscribers,
                            row.notification_content,
                            row.is_deleted,
                            row.date_created,
                        )
                    )
                setRows(formattedRows);
            } catch (error) {
                console.error('Error fetching monitors', error);
            }
        };
        fetchData();
    }, [paginationModel]);

    return (
        <Grid container spacing={5} sx={styles.contentContainer}>
            <Grid item xs={12} md={12}>
                <Typography variant="h2" gutterBottom>
                    Monitors
                </Typography>
                <TableLayout buttonPath='/monitors/create' buttonText='New Monitors'>
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSizeOptions={[5, 10, 25]}
                            rowCount={rowCount}
                            paginationModel={paginationModel}
                            paginationMode="server"
                            disableRowSelectionOnClick
                            onPaginationModelChange={setPaginationModel}
                            processRowUpdate={handleProcessRowUpdate}
                            onProcessRowUpdateError={onProcessRowUpdateError}
                        />
                        <ToastContainer />
                    </Box>
                </TableLayout>
            </Grid>
        </Grid>
    );
}
